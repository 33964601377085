import { SingleNote } from "./SingleNote";
import { Note } from "./models";
import { NotesMessages } from "./messages";

type NotesListProps = {
    notes: Note[];
    referenceId: string;
    userId: string;
    editor: boolean;
    onSave: (note?: { id?: string; referenceId: string; text: string }) => Promise<void>;
    onDelete: (noteId: string) => Promise<void>;
    createMessages: (createdAt: string) => NotesMessages;
};

export const NotesList = ({ notes, referenceId, userId, editor, onSave, onDelete, createMessages }: NotesListProps) => (
    <>
        {notes.map((note) => (
            <SingleNote
                key={note.id}
                referenceId={referenceId}
                onSave={onSave}
                onDelete={onDelete}
                note={note}
                userId={userId}
                editor={editor}
                messages={createMessages(note.createdAt)}
            />
        ))}
    </>
);
