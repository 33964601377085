/**
 * Wrapper function for all case changing functions
 * @param rule Convertion rule (a function that takes in a string and returns a string)
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
const changeCase =
    (rule: (_arg: string) => string) =>
    (data: any, convertValues: boolean): any => {
        if (typeof data !== "object" || data === null || data === undefined) {
            return convertValues ? (typeof data === "string" ? rule(data) : data) : data;
        }

        if (Array.isArray(data)) {
            return data.map((d) => changeCase(rule)(d, convertValues));
        }

        return Object.keys(data)
            .reduce<string[][]>((mappingTuples, rawKey) => [...mappingTuples, [rawKey, rule(rawKey)]], [])
            .reduce<{ [key: string]: any }>(
                (result: { [key: string]: any }, [rawKey, newKey]) => ({
                    ...result,
                    [newKey]:
                        (typeof data[rawKey] === "object" || convertValues) && data[rawKey] !== null
                            ? changeCase(rule)(data[rawKey], convertValues)
                            : data[rawKey],
                }),
                {}
            );
    };

const snakeCaseRule = (str: string) => str.replace(/([A-Z])/g, (_, char) => `_${char.toLowerCase()}`);

/**
 * Only converts object keys unless the second parameter (convertValue) is set to true
 * @param data {any} anything
 * @param convertValues {boolean} whether or not to convert values or just object keys
 */
export const toSnakeCase = changeCase(snakeCaseRule);
