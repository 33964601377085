import { TextField } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import { ComputeInputProps } from "src/components/readOnlyStypeComputer";

interface TextInputRendererProps {
    readOnly: boolean;
    id: string;
    label: string;
    value?: string;
    onChange: (value: string) => void;
}

export const TextInputRenderer: React.FC<TextInputRendererProps> = ({ id, label, value, onChange, readOnly }) => {
    const textFieldRef = React.useRef<HTMLDivElement | null>();
    const [openTooltip, setOpenTooltip] = React.useState(false);

    const handleCloseTooltip = () => {
        setOpenTooltip(false);
    };

    const handleOpenTooltip = () => {
        const isElipsis = textFieldRef.current && textFieldRef.current.scrollWidth > textFieldRef.current.clientWidth;
        setOpenTooltip(isElipsis ?? false);
    };

    return (
        <Tooltip open={openTooltip} onClose={handleCloseTooltip} onOpen={handleOpenTooltip} title={value ?? ""}>
            <TextField
                id={`text_input_renderer_${id}`}
                label={label}
                variant={readOnly ? "standard" : "outlined"}
                fullWidth
                value={value ?? ""}
                onChange={(newValue) => {
                    onChange(newValue.target.value);
                }}
                sx={{
                    "& .MuiInputBase-input": {
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    },
                }}
                InputProps={ComputeInputProps(readOnly)}
                inputRef={textFieldRef}
            />
        </Tooltip>
    );
};
