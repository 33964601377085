import { gql, useLazyQuery } from "@apollo/client";
import { Filter } from "@ignite-analytics/filters";
import { AnalysisQuery, PivotResponse } from "@ignite-analytics/pivot-ts";
import { useCallback } from "react";
import { toSnakeCase } from "@/helpers/toSnakeCase";
import { env } from "@/env";

type GetPivotFunc = (
    query: AnalysisQuery,
    filters: Filter[] | undefined,
    includeHidden?: boolean
) => Promise<PivotResponse>;

export function useGetPivotFromAnalysisService(): GetPivotFunc {
    const GET_PIVOT_QUERY = gql`
        query getPivot($input: GetPivotInput!) {
            getPivot(input: $input) {
                pivotResponse
            }
        }
    `;

    const [getPivot] = useLazyQuery<{ getPivot: { pivotResponse: string } }>(GET_PIVOT_QUERY, {
        fetchPolicy: "no-cache",
        context: {
            uri: env.REACT_APP_GRAPHQL_ROUTER_URL,
        },
    });

    return useCallback(
        async (query: AnalysisQuery, filters: Filter[] | undefined, includeHidden = false): Promise<PivotResponse> => {
            const filterInput = filters ? JSON.stringify(toSnakeCase(filters, false)) : JSON.stringify([]);
            const pivotQuery = {
                valueAggregationItems: query.valueAggregationItems,
                rowSplitItems: query.rowSplitItems,
                columnSplitItems: query.columnSplitItems,
            };

            const inputData = {
                pivotQuery: JSON.stringify(toSnakeCase(pivotQuery, false)),
                elasticIndex: toSnakeCase(query.elasticIndex, true) as string,
                filters: filterInput,
                includeHidden,
            };
            return getPivot({ variables: { input: inputData } }).then(async (response) => {
                if (response.data?.getPivot.pivotResponse) {
                    const pivotResponse: PivotResponse = JSON.parse(response.data.getPivot.pivotResponse);
                    return pivotResponse;
                }

                throw response.error ?? new Error("Error getting data");
            });
        },
        [getPivot]
    );
}
