import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { MenuItem, Select, Stack, Grid, Typography } from "@mui/material";
import { track } from "@ignite-analytics/track";
import { Box, Container } from "@mui/system";
import { ContractCoverage } from "@/components/ContractCoverageWidget";
import { OpportunityWidget } from "@/components/OpportunityWidget";
import { ExploreContracts } from "@/components/ExploreContracts";
import { MissingRenewalDateCard, StatisticsCard } from "@/components/StatisticsCards";
import { contractOverviewRoute } from "@/Router";
import { useModuleAccess } from "@/hooks/useModuleAccess";
import { useBreadcrumbs } from "@/hooks/useBreadcrumbs";

export const OverviewComponent: React.FC = () => {
    const { updateBreadCrumbs } = useBreadcrumbs();

    const moduleAccess = useModuleAccess();
    // Default to true so we don't show ads to users with access before it loads.
    const hasAccess = moduleAccess?.contracts.included ?? true;

    const { group } = contractOverviewRoute.useSearch();
    const { missingRenewalDate, deferredStats } = contractOverviewRoute.useLoaderData();

    // render that sweet skeleton while we're loading data.
    const [realStats, setStats] = React.useState<undefined | Awaited<typeof deferredStats>>(undefined);
    useEffect(() => {
        deferredStats.then((value) => setStats(value));
    }, [deferredStats]);

    const stats = hasAccess
        ? realStats
        : {
              // Some fake data to show if the user doesn't have access.
              active: { count: 1056, totalSpend: 456_200_000 },
              expiring: { count: 347, totalSpend: 35_900_000 },
              renewing: { count: 237, totalSpend: 19_900_000 },
          };

    const navigate = contractOverviewRoute.useNavigate();
    useEffect(() => {
        track("Contract Overview: Page loaded");
    }, []);

    useEffect(() => {
        updateBreadCrumbs({ page: "overview" });
    }, [updateBreadCrumbs]);

    return (
        <Box px={4} py={2}>
            <Container maxWidth="xl">
                {!hasAccess && (
                    <Stack paddingBottom={3}>
                        <ExploreContracts />
                    </Stack>
                )}
                <Stack gap={3} sx={{ filter: hasAccess ? undefined : "blur(2px)" }}>
                    <Typography variant="displayXs" component="h1" fontWeight="bold">
                        <FormattedMessage defaultMessage="Contract overview" />
                    </Typography>
                    <Grid container spacing={2} alignItems="stretch">
                        <Grid item xs={12}>
                            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={3}>
                                <Typography variant="textLg" component="h2" fontWeight={500}>
                                    <FormattedMessage defaultMessage="Key insights" />
                                </Typography>
                                <Select
                                    value={group}
                                    onChange={(e) => {
                                        const group = e.target.value;
                                        if (group !== "all" && group !== "currentUser") {
                                            return;
                                        }
                                        navigate({ search: (prev) => ({ ...prev, group }), replace: true });
                                    }}
                                    size="small"
                                    disabled={!hasAccess}
                                >
                                    <MenuItem
                                        value="all"
                                        onClick={() => track("Contract Overview: Show all contracts")}
                                    >
                                        <FormattedMessage defaultMessage="All contracts" />
                                    </MenuItem>
                                    <MenuItem
                                        value="currentUser"
                                        onClick={() => track("Contract Overview: Show my contracts")}
                                    >
                                        <FormattedMessage defaultMessage="My contracts" />
                                    </MenuItem>
                                </Select>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <StatisticsCard
                                variant="active"
                                statistics={stats?.active}
                                contractSelection={group}
                                disabled={!hasAccess}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <StatisticsCard
                                variant="expiring"
                                statistics={stats?.expiring}
                                contractSelection={group}
                                disabled={!hasAccess}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            {missingRenewalDate ? (
                                <MissingRenewalDateCard />
                            ) : (
                                <StatisticsCard
                                    variant="renewing"
                                    statistics={stats?.renewing}
                                    contractSelection={group}
                                    disabled={!hasAccess}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12} lg={7}>
                            <OpportunityWidget />
                        </Grid>
                        <Grid item xs={12} lg={5}>
                            <ContractCoverage />
                        </Grid>
                    </Grid>
                </Stack>
            </Container>
        </Box>
    );
};
