import { Tooltip } from "@mui/material";
import { AutocompleteRenderGetTagProps } from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { useCallback, useEffect, useState } from "react";
import DropDown from "src/components/ui/DropDown";
import { DropDownOptionItem } from "src/components/ui/DropDown/models/DropDownOptionItem";

export interface ContactOptionItem extends DropDownOptionItem {
    email: string;
}

const ContactOption: React.FC<ContactOptionItem> = ({ label, email }) => (
    <Stack>
        <Typography variant="body1">{label}</Typography>
        <Stack>
            <Typography>{email}</Typography>
        </Stack>
    </Stack>
);
interface DropdownProps {
    fieldLabel: string;
    debounceMs?: number;
    readonly?: boolean;
    multi?: boolean;
    value?: ContactOptionItem[];
    contactOptions: ContactOptionItem[];
    loading: boolean;
    onSelect: (selectedValue: string[]) => void;
    onSearch: (phrase: string) => void;
}
export const Dropdown = ({
    fieldLabel,
    debounceMs,
    readonly,
    multi,
    value,
    contactOptions,
    loading,
    onSelect,
    onSearch,
}: DropdownProps) => {
    const [contacts, setContacts] = useState<ContactOptionItem[]>(value ?? []);

    const handleSelect = useCallback(
        (selectedValue: ContactOptionItem[] | null) => {
            if (selectedValue) {
                onSelect(selectedValue.map((item) => item.id));
                setContacts(selectedValue);
            } else {
                onSelect([]);
                setContacts([]);
            }
        },
        [onSelect]
    );

    useEffect(() => {
        setContacts(value ?? []);
    }, [value]);

    return (
        <DropDown<ContactOptionItem>
            multi={multi}
            label={fieldLabel}
            value={contacts}
            options={contactOptions}
            loading={loading}
            optionTemplateComponent={ContactOption as React.FC<DropDownOptionItem>}
            onSearch={onSearch}
            onSelect={handleSelect}
            debounceMs={debounceMs ?? 800}
            readOnly={readonly}
            renderTags={
                readonly
                    ? (values: ContactOptionItem[], getTagProps: AutocompleteRenderGetTagProps) =>
                          values.map((option, index) => (
                              <Tooltip title={option.email} key={option.id}>
                                  <Chip {...getTagProps({ index })} label={option.label} data-testid="user-chip" />
                              </Tooltip>
                          ))
                    : undefined
            }
        />
    );
};
