import { LayoutsObject } from "src/models/layout";
import { Layout } from "src/types/Layout";
import { useContractsApiFetch } from "./useContractsApiFetch";
import { captureMessage } from "@/errorHandling/errors";

const CONTRACTS_BASE_URL = import.meta.env.REACT_APP_CONTRACTS_BASE_URL;

export function useContractLayout(layoutId: string | null) {
    const { data, isLoading, error, refetch } = useContractsApiFetch<Layout>(`contracts/layouts/${layoutId}/content`);
    return {
        layout: data,
        loading: isLoading,
        refetch,
        error,
    };
}

export async function getLayouts() {
    return fetch(`${CONTRACTS_BASE_URL}/contracts/layouts`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    })
        .then((res) => {
            if (!res.ok) {
                throw new Error("Error fetching layouts");
            }
            return res.json() as Promise<LayoutsObject>;
        })
        .catch((error) => {
            captureMessage("Error fetching layouts", { extra: { error } });
            // we'll just fall back to an empty object here
            return { defaultLayoutId: "", layouts: [] } as LayoutsObject;
        });
}
export async function createLayout(name: string) {
    return fetch(`${CONTRACTS_BASE_URL}/contracts/layouts`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ name }),
    })
        .then((res) => {
            if (!res.ok) {
                throw new Error("Error creating layout");
            }
            return res.json() as Promise<Layout>;
        })
        .catch((error) => {
            captureMessage("Error creating layout", { extra: { error } });
            return {} as Layout;
        });
}
export async function removeLayout(id: string) {
    return fetch(`${CONTRACTS_BASE_URL}/contracts/layouts/${id}`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    })
        .then((res) => {
            if (!res.ok) {
                throw new Error("Error deleting layout");
            }
            return res.json() as Promise<void>;
        })
        .catch((error) => {
            captureMessage("Error deleting layout", { extra: { error } });
        });
}
export async function setAsDefaultLayout(id: string) {
    return fetch(`${CONTRACTS_BASE_URL}/contracts/layouts/${id}/default`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    })
        .then((res) => {
            if (!res.ok) {
                throw new Error("Error setting default layout");
            }
            return res.json() as Promise<void>;
        })
        .catch((error) => {
            captureMessage("Error setting default layout", { extra: { error } });
        });
}
export async function renameLayout(id: string, name: string) {
    return fetch(`${CONTRACTS_BASE_URL}/contracts/layouts/${id}/name`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ name }),
    })
        .then((res) => {
            if (!res.ok) {
                throw new Error("Error renaming layout");
            }
            return res.json() as Promise<void>;
        })
        .catch((error) => {
            captureMessage("Error renaming layout", { extra: { error } });
        });
}
