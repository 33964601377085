import messages from "./messages";
import { currentIntl } from "@/contexts/IntlContext";

const getSiUnits = () => {
    const intl = currentIntl();
    return [
        {
            value: 1e-2,
            short: intl.formatMessage(messages.measureUnitPercentShort),
            long: intl.formatMessage(messages.measureUnitPercentLong),
        },
        { value: 1, short: "", long: "" },
        {
            value: 1e3,
            short: intl.formatMessage(messages.measureUnitThousandShort),
            long: intl.formatMessage(messages.measureUnitThousandLong),
        },
        {
            value: 1e6,
            short: intl.formatMessage(messages.measureUnitMillionShort),
            long: intl.formatMessage(messages.measureUnitMillionLong),
        },
        {
            value: 1e9,
            short: intl.formatMessage(messages.measureUnitBillionShort),
            long: intl.formatMessage(messages.measureUnitBillionLong),
        },
    ];
};

export const formatBigNumber = (
    num: number,
    digits: number | "auto" | null = "auto",
    padString = true,
    unitDescription: "short" | "long" = "short"
): string => {
    if (num < 0) {
        return `-${formatBigNumber(-num, digits, padString, unitDescription)}`;
    }
    const unitsWihtoutPercent = getSiUnits().slice(1);
    let i = 0;
    for (i = unitsWihtoutPercent.length - 1; i > 0; i -= 1) {
        if (num > 0 ? unitsWihtoutPercent[i].value <= num : -unitsWihtoutPercent[i].value >= num) break;
    }
    const valueWithoutSuffix = num / unitsWihtoutPercent[i].value;
    const decimals =
        typeof digits === "number" ? digits : valueWithoutSuffix < 10 ? 2 : valueWithoutSuffix < 100 ? 1 : 0;

    const toBeReturned =
        valueWithoutSuffix.toFixed(decimals).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") +
        unitsWihtoutPercent[i][unitDescription];
    return padString
        ? toBeReturned.padStart(4 + decimals - toBeReturned.length + (i === 0 ? 6 : 0), "\u00A0")
        : toBeReturned;
};
