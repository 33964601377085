import { gql, useLazyQuery } from "@apollo/client";
import { useCallback, useState } from "react";

const searchContactsQuery = gql`
    query searchContacts($input: SearchContactsInput!) {
        searchContacts(input: $input) {
            result {
                id
                firstName
                lastName
                email
            }
            total
        }
    }
`;

export function useSearchContacts(): [(phrase: string) => Promise<any>, boolean, string[] | undefined] {
    const [searchContactsLazyQuery] = useLazyQuery<{ searchContacts: { result: any } }>(searchContactsQuery);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<string[] | undefined>(undefined);

    const searchContacts = useCallback(
        (phrase: string) => {
            setLoading(true);
            return searchContactsLazyQuery({
                variables: {
                    input: {
                        terms: {
                            name: phrase,
                        },
                        limit: 20,
                        offset: 0,
                        sort: {
                            by: "lastName",
                            dir: "asc",
                        },
                    },
                },
            })
                .then((result) => {
                    if (result.error) {
                        const queryErrors = result.error?.graphQLErrors
                            .map((e) => e.message)
                            .concat(result.error?.clientErrors.map((e) => e.message));
                        setErrors(queryErrors);
                    }
                    return result.data?.searchContacts.result;
                })
                .finally(() => setLoading(false));
        },
        [searchContactsLazyQuery]
    );

    return [searchContacts, loading, errors];
}
