import Skeleton from "@mui/material/Skeleton";
import React from "react";
import { TableRelationDropdown } from "./dumb";
import { useTableRelation } from "../../hooks/useTableRelation";

interface TableRelationProps {
    id?: string;
    tableId: string;
    fieldName: string;
    readOnly?: boolean;
    onSelect: (selectedId?: string) => void;
}

export const TableRelation: React.FC<TableRelationProps> = ({ fieldName, tableId, id, readOnly, onSelect }) => {
    const { dataTable, tableRelationValue } = useTableRelation(tableId, id);

    if (dataTable) {
        return (
            <TableRelationDropdown
                value={tableRelationValue}
                dataTable={dataTable}
                fieldName={fieldName}
                onSelect={onSelect}
                readOnly={readOnly}
            />
        );
    }
    return <Skeleton />;
};
