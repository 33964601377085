import { defineMessages } from "react-intl";

const messages = defineMessages({
    noSpend: {
        id: "contractCoverage.noSpend",
        description: "Message for no spend in ignite",
        defaultMessage:
            "Looks like you don't have any spend data in Ignite. Please contact your Customer Success Manager (CSM) to add spend data.",
    },
    notConnectedToContracts: {
        id: "contractCoverage.notConnectedToContracts",
        description: "Message for no contracts connected to spend in ignite",
        defaultMessage:
            "Looks like your contracts are not currently linked to your spend data in Ignite. Please reach out to your Customer Success Manager (CSM) to establish this connection.",
    },
});

export default messages;
