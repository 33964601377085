export const REGION_ISO_639 = "region";
export const LANGUAGE_ISO_639 = "locale";
export const TENANT = "tenant";
export const COLUMN_MODEL_VISIBILITY = "COLUMN_MODEL_VISIBILITY";
export const CONTRACTS_FILTERS = "CONTRACTS_FILTERS";
export const CONTRACTS_PINNED_FILTERS_FIELDS = "CONTRACTS_PINNED_FILTERS_FIELDS";
export const CONTRACTS_COLUMNS_ORDER = "CONTRACTS_COLUMNS_ORDER";
export const CONTRACTS_COLUMN_WIDTHS = "CONTRACTS_COLUMN_WIDTHS";
export const USER = "user";
export const CONTRACTS_TABLE_DENSITY = "CONTRACTS_TABLE_DENSITY";
