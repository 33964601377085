import React, { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { Truck } from "@ignite-analytics/icons";
import { Stack } from "@mui/system";
import { useIntl } from "react-intl";

type SupplierRow = {
    id: string;
    name: string;
};

type Props = {
    value: SupplierRow | null;
    onSearch: (value: string) => void;
    onChange: (value: SupplierRow | null) => void;
    options: SupplierRow[];
    loading: boolean;
    fullWidth?: boolean;
    label?: string;
    required?: boolean;
    maxWidth?: number;
};

export const SupplierDropDownV2 = ({
    onSearch,
    value,
    onChange,
    fullWidth,
    label,
    required,
    loading,
    options,
}: Props) => {
    const [search, setSearch] = React.useState<string>("");
    const { formatMessage } = useIntl();

    const [debouncedSearch] = useDebounce(search, 500);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        // do not update search if we've already selected a value
        if (open) {
            onSearch(debouncedSearch);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearch, open]);

    return (
        <Autocomplete
            onOpen={() => {
                setOpen(true);
                onSearch("");
            }}
            onClose={() => {
                setOpen(false);
            }}
            value={value}
            loading={loading}
            size="small"
            fullWidth={fullWidth}
            onInputChange={(_, value) => {
                setSearch(value);
            }}
            onChange={(_, value) => {
                onChange(value);
            }}
            blurOnSelect
            options={options}
            getOptionLabel={(option) => option.name}
            filterOptions={(options) => options.slice(0, 10)}
            renderOption={(props, option) => (
                <Stack component="li" gap={2} direction="row" alignItems="center" {...props}>
                    <Truck fontSize="inherit" />
                    {option.name}
                </Stack>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    required={required}
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        placeholder: formatMessage({ defaultMessage: "Search for supplier" }),
                        startAdornment: <Truck sx={{ pl: 1 }} fontSize="small" />,
                        size: "small",
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
};
