import { defineMessages } from "react-intl";

const messages = defineMessages({
    title: {
        id: "deletePrompt.title",
        description: "Title for confirmation prompt when you try to remove contract",
        defaultMessage: "Are you sure?",
    },
    description: {
        id: "deletePrompt.description",
        description: "Description for confirmation prompt when you try to remove contract",
        defaultMessage: "Are you sure you want to proceed with deleting this item? The action is irreversible.",
    },
    cancelButton: {
        id: "deletePrompt.cancelButton",
        description: "Button message for canceling the action",
        defaultMessage: "Cancel",
    },
    deleteButton: {
        id: "deletePrompt.deleteButton",
        description: "Button message for delete",
        defaultMessage: "Delete",
    },
});

export default messages;
