import { defineMessages } from "react-intl";
import { fm } from "src/contexts/IntlContext";

const messages = defineMessages({
    title: {
        id: "treePopup.title",
        description: "Tree popup header text",
        defaultMessage: "",
    },
    submit: {
        id: "treePopup.submit",
        description: "Tree popup submit button text",
        defaultMessage: "Save",
    },
    cancel: {
        id: "treePopup.cancel",
        description: "Tree popup cancel button text",
        defaultMessage: "Cancel",
    },
    header: {
        id: "treePopup.header",
        description: "Tree popup submit header text",
        defaultMessage: "",
    },
    hint: {
        id: "treePopup.hint",
        description: "Hint for multiple selection",
        defaultMessage: "Select multiple items",
    },
});

export const labels: { title: string; submit: string; cancel: string } = {
    title: fm(messages.title).toString(),
    submit: fm(messages.submit).toString(),
    cancel: fm(messages.cancel).toString(),
};
