import React, { useState } from "react";
import { Stack } from "@mui/system";
import { Button, TextField, Typography, Dialog, DialogContent, Input, Switch } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FormattedMessage, useIntl } from "react-intl";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { SupplierDropDownV2 } from "../SupplierDropdownV2";
import { useSuppliersSearch } from "../SupplierDropdownV2/hooks";
import { normalizeDateString } from "@/helpers/dates";

export type NewContract = {
    title: string;
    description: string | undefined;
    startDate: string | undefined;
    endDate: string | undefined;
    supplierId: string | undefined;
    isPrivate: boolean;
};

type Props = {
    open: boolean;
    onClose: () => void;
    onSave: (c: NewContract) => Promise<void>;
    prefilledSupplier?: { label: string; id: string };
};

export const NewContractModal = ({ open, onClose, onSave, prefilledSupplier }: Props) => {
    const [loading, setLoading] = useState(false);
    const [selectedSupplier, setSelectedSupplier] = useState<{ id: string; name: string } | null>(
        prefilledSupplier ? { id: prefilledSupplier.id, name: prefilledSupplier.label } : null
    );
    const search = useSuppliersSearch();
    const { formatMessage } = useIntl();

    const [contract, setContract] = useState<NewContract>({
        title: "",
        description: undefined,
        startDate: undefined,
        endDate: undefined,
        supplierId: prefilledSupplier?.id || "",
        isPrivate: false,
    });
    function reset() {
        setContract({
            title: "",
            description: undefined,
            startDate: undefined,
            endDate: undefined,
            supplierId: prefilledSupplier?.id || "",
            isPrivate: false,
        });
    }
    function handleClose() {
        reset();
        onClose();
    }

    return (
        <Dialog open={open} onClose={() => handleClose()}>
            <DialogContent>
                <Stack gap={3} width="485px">
                    <Typography variant="textXl" fontWeight={500}>
                        <FormattedMessage defaultMessage="Add new contract" />
                    </Typography>
                    <TextField
                        label={formatMessage({ defaultMessage: "Contract title" })}
                        placeholder={formatMessage({ defaultMessage: "Contract title" })}
                        required
                        variant="outlined"
                        value={contract.title}
                        onChange={(e) => {
                            setContract({ ...contract, title: e.target.value });
                        }}
                    />

                    <Stack direction="row" gap={3} justifyContent="space-around">
                        <DesktopDatePicker
                            timezone="UTC"
                            maxDate={normalizeDateString(contract.endDate)}
                            label={formatMessage({ defaultMessage: "Start date" })}
                            onChange={(value) => {
                                setContract({ ...contract, startDate: value?.format() });
                            }}
                        />

                        <DesktopDatePicker
                            timezone="UTC"
                            minDate={normalizeDateString(contract.startDate)}
                            label={formatMessage({ defaultMessage: "End date" })}
                            onChange={(value) => {
                                setContract({ ...contract, endDate: value?.format() });
                            }}
                        />
                    </Stack>

                    <SupplierDropDownV2
                        label={formatMessage({ defaultMessage: "Supplier" })}
                        value={selectedSupplier}
                        onChange={(supplier) => {
                            setSelectedSupplier(supplier);
                            if (supplier) {
                                setContract({ ...contract, supplierId: supplier.id });
                            } else {
                                setContract({ ...contract, supplierId: undefined });
                            }
                        }}
                        loading={search.loading}
                        onSearch={(text) => search.update(text)}
                        options={search.matches}
                    />

                    <Stack gap={0.5}>
                        <Typography variant="textSm" fontWeight={500}>
                            <FormattedMessage defaultMessage="Description" />
                        </Typography>
                        <Input
                            disableUnderline
                            multiline
                            minRows={3}
                            placeholder={formatMessage({ defaultMessage: "Description of contract" })}
                            value={contract.description}
                            onChange={(e) => {
                                setContract({ ...contract, description: e.target.value });
                            }}
                        />
                    </Stack>

                    <Stack direction="row" gap={1.25}>
                        <Switch
                            checked={!contract.isPrivate}
                            onChange={() => {
                                setContract({ ...contract, isPrivate: !contract.isPrivate });
                            }}
                            color="primary"
                        />
                        <Stack>
                            <Typography fontWeight={500} variant="textSm">
                                <FormattedMessage defaultMessage="Public contract" />
                            </Typography>
                            <Typography variant="textSm" color="GrayText">
                                <FormattedMessage defaultMessage="Public contracts are visible to all users. If toggled off, they will be restricted to only administrators and contract responsibles of the contract." />
                            </Typography>
                        </Stack>
                    </Stack>

                    <Stack gap={1.5} direction="row" justifyContent="flex-end">
                        <Button onClick={() => handleClose()} color="secondary" size="small">
                            <FormattedMessage defaultMessage="Cancel" />
                        </Button>
                        <LoadingButton
                            loading={loading}
                            disabled={contract.title.length === 0}
                            size="small"
                            onClick={() => {
                                setLoading(true);
                                onSave(contract).finally(() => setLoading(false));
                            }}
                        >
                            <FormattedMessage defaultMessage="Save" />
                        </LoadingButton>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};
