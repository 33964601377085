import React from "react";
import { Tooltip  } from "@mui/material";
import { TooltipProps } from "@mui/material/Tooltip";

type Props = TooltipProps & {
    children: React.ReactNode;
    condition?: boolean;
};

// A tooltip that only renders when the condition is true
export const ConditionalTooltip = ({ children, condition, ...props }: Props) => {
    const title = condition ? props.title : "";
    return (
        <Tooltip placement="top" {...props} title={title}>
            <span>{children}</span>
        </Tooltip>
    );
}
