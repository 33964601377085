import { useMemo } from "react";
import { useErrorHandler } from "src/errorHandling/ErrorHandlerContext";
import { makeContractsApiHttp } from "src/http";

export const useContractsApiDelete = () => {
    const http = useMemo(() => makeContractsApiHttp(), []);
    const { handleError } = useErrorHandler();
    return <T>(url: string) =>
        http
            .delete<T>(url)
            .then((res) => res.data)
            .catch((err) => {
                handleError(err);
                throw err;
            });
};
