import {
    Filter,
    DotsVertical,
    EyeSolid,
    Menu as MenuIcon,
    ChevronUp,
    ChevronDown,
    Grid,
} from "@ignite-analytics/icons";
import { Button, Collapse, IconButton, ListItemIcon, Menu, MenuItem, Stack, Typography, alpha } from "@mui/material";
import { GridApiPro, GridDensity, GridPreferencePanelsValue } from "@mui/x-data-grid-pro";
import { MutableRefObject, useState } from "react";

import { fm } from "src/contexts/IntlContext";
import { isBetaUser } from "@/helpers/isBetaUser";
import messages from "./messages";

interface TableActionBarProps {
    apiRef: MutableRefObject<GridApiPro> | undefined;
    density: GridDensity;
    onDensityChange: (density: GridDensity) => void;
    setOpenLayoutPopup: (open: boolean) => void;
    canEditLayouts: boolean;
}

export const TableActionBar: React.FC<TableActionBarProps> = ({
    apiRef,
    density,
    onDensityChange,
    setOpenLayoutPopup,
    canEditLayouts,
}) => {
    const q2BetaTesting = isBetaUser();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [openTableDensity, setOpenTableDensity] = useState(false);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    if (q2BetaTesting) {
        return (
            <Stack direction="row" justifyContent="center" alignItems="center">
                <IconButton size="small" onClick={handleClick} color="ghostGray">
                    <DotsVertical />
                </IconButton>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        "aria-labelledby": "basic-button",
                    }}
                    slotProps={{
                        paper: {
                            sx: {
                                width: 240,
                            },
                        },
                    }}
                >
                    <MenuItem
                        onClick={() => {
                            apiRef?.current?.showPreferences(GridPreferencePanelsValue.columns);
                            handleClose();
                        }}
                    >
                        <Typography>{fm(messages.hideShowColumnsButton)}</Typography>
                    </MenuItem>
                    <MenuItem
                        disabled={!canEditLayouts}
                        onClick={() => {
                            setOpenLayoutPopup(true);
                            handleClose();
                        }}
                    >
                        <Typography>{fm(messages.layoutsButton)}</Typography>
                    </MenuItem>
                    <MenuItem onClick={() => setOpenTableDensity(!openTableDensity)}>
                        <Stack direction="row" justifyContent="space-between" width="100%">
                            <Typography>{fm(messages.setDensity)}</Typography>
                            {openTableDensity ? <ChevronUp /> : <ChevronDown />}
                        </Stack>
                    </MenuItem>
                    <Collapse in={openTableDensity}>
                        <MenuItem selected={density === "compact"} onClick={() => onDensityChange("compact")}>
                            <Typography variant="body2">{fm(messages.compactDensity)}</Typography>
                        </MenuItem>
                        <MenuItem selected={density === "standard"} onClick={() => onDensityChange("standard")}>
                            <Typography variant="body2">{fm(messages.standardDensity)}</Typography>
                        </MenuItem>
                        <MenuItem selected={density === "comfortable"} onClick={() => onDensityChange("comfortable")}>
                            <Typography variant="body2">{fm(messages.comfortableDensity)}</Typography>
                        </MenuItem>
                    </Collapse>
                </Menu>
            </Stack>
        );
    }

    return (
        <Stack direction="row" justifyContent="center" alignItems="center">
            <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                variant="text"
                color="inherit"
                startIcon={<Filter />}
            >
                <Typography>{fm(messages.preferencesButton)}</Typography>
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                <MenuItem
                    onClick={() => {
                        apiRef?.current?.showPreferences(GridPreferencePanelsValue.columns);
                        handleClose();
                    }}
                >
                    <ListItemIcon>
                        <EyeSolid fontSize="small" />
                    </ListItemIcon>
                    <Typography>{fm(messages.hideShowColumnsButton)}</Typography>
                </MenuItem>
                {canEditLayouts && (
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            setOpenLayoutPopup(true);
                        }}
                    >
                        <ListItemIcon>
                            <Grid fontSize="small" />
                        </ListItemIcon>
                        <Typography>{fm(messages.layoutsButton)}</Typography>
                    </MenuItem>
                )}
                <MenuItem onClick={() => setOpenTableDensity(!openTableDensity)}>
                    <ListItemIcon>
                        <MenuIcon fontSize="small" height="0.5" style={{ color: alpha("#000", 0.7) }} />
                    </ListItemIcon>
                    <Stack direction="row" justifyContent="space-between" width="100%">
                        <Typography>{fm(messages.setDensity)}</Typography>
                        {openTableDensity ? <ChevronUp /> : <ChevronDown />}
                    </Stack>
                </MenuItem>
                <Collapse in={openTableDensity}>
                    <MenuItem onClick={() => onDensityChange("compact")}>
                        <Typography variant="body2" color={density === "compact" ? "primary" : "inherit"}>
                            {fm(messages.compactDensity)}
                        </Typography>
                    </MenuItem>
                    <MenuItem onClick={() => onDensityChange("standard")}>
                        <Typography variant="body2" color={density === "standard" ? "primary" : "inherit"}>
                            {fm(messages.standardDensity)}
                        </Typography>
                    </MenuItem>
                    <MenuItem onClick={() => onDensityChange("comfortable")}>
                        <Typography variant="body2" color={density === "comfortable" ? "primary" : "inherit"}>
                            {fm(messages.comfortableDensity)}
                        </Typography>
                    </MenuItem>
                </Collapse>
            </Menu>
        </Stack>
    );
};
