import { defineMessages } from "react-intl";

const messages = defineMessages({
    dropFiles: {
        id: "components.fileUpload.dropZone.dropFiles",
        defaultMessage: "Drop files",
    },
    or: {
        id: "components.fileUpload.dropZone.or",
        defaultMessage: "or",
    },
    browse: {
        id: "components.fileUpload.dropZone.browse",
        defaultMessage: "browse",
    },
    choseFile: {
        id: "components.fileUpload.dropZone.choseFile",
        defaultMessage: "to choose a file",
    },
});
export default messages;
