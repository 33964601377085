import { useEffect, useState } from "react";
import { Skeleton } from "@mui/material";
import { makeGraphqlRouter } from "src/http";
import { SupplierContacts, Props } from "./supplier_contacts";

const getSupplierQuery = `
    query getSupplier($input: GetSupplierInput!) {
        getSupplier(input: $input) {
            supplier {
                id
                name
            }
        }
    }
`;

const fetchSupplierById = (id: string): Promise<string> =>
    makeGraphqlRouter()
        .post("", {
            query: getSupplierQuery,
            variables: {
                input: {
                    id,
                },
            },
        })
        .then((response) => response.data.data.getSupplier.supplier.id);

export const SupplierContactsNormalizationWrapper = ({ contractId, supplierContact, maxHeight, canEdit }: Props) => {
    const [winnerId, setWinnerId] = useState<string>();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        fetchSupplierById(supplierContact.supplierId)
            .then(setWinnerId)
            .finally(() => setIsLoading(false));
    }, [supplierContact.supplierId]);

    return (
        <>
            {winnerId && (
                <SupplierContacts
                    contractId={contractId}
                    supplierContact={{ ...supplierContact, supplierId: winnerId }}
                    maxHeight={maxHeight}
                    canEdit={canEdit}
                />
            )}
            {isLoading && (
                <>
                    <Skeleton height="80px" animation="wave" />
                    <Skeleton height="80px" animation="wave" />
                </>
            )}
        </>
    );
};
