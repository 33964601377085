import { Layout, Group, Item } from "src/types/Layout";

interface DataColumn {
    id: string;
    name: string;
}

const createItemBaseOnColumn = (column: DataColumn, order: number): Item =>
    ({
        refId: column.id,
        name: column.name,
        visible: true,
        order,
    } as Item);

const createDefaultGroup = (dataColumns: DataColumn[], order: number, remainingFieldsGroupName: string): Group => ({
    visible: true,
    name: remainingFieldsGroupName,
    order,
    items: dataColumns.map(createItemBaseOnColumn),
});

const filterOutFieldsNotExistingAnymore = (contractsLayout: Layout, allFields: string[]): Group[] =>
    contractsLayout.groups.map((group) => ({
        ...group,
        items: group.items.filter((x) => allFields.includes(x.refId)),
    }));

const updateNamesBasedOnDataColumns = (groups: Group[], allFields: DataColumn[]): Group[] => {
    const updateItemName = (field: Item, dataColumns: DataColumn[]) => {
        const newNames = dataColumns.find((column) => column.id === field.refId);
        const newName = newNames ? newNames.name : field.name;
        return {
            ...field,
            name: newName,
        };
    };

    return groups.map((group) => ({
        ...group,
        items: group.items.map((item) => updateItemName(item, allFields)),
    }));
};

const findRemainingItemsGroupIndex = (groups: Group[], remainingFieldsGroupName: string): number =>
    groups.findIndex((group) => group.name === remainingFieldsGroupName);

const appendNotMappedFields = (
    allFields: DataColumn[],
    existingGroups: Group[],
    otherFields: DataColumn[],
    remainingFieldsGroupName: string
) => {
    if (otherFields.length === 0) {
        return existingGroups;
    }
    const remainingItemsGroupIndex = findRemainingItemsGroupIndex(existingGroups, remainingFieldsGroupName);
    if (remainingItemsGroupIndex !== -1) {
        const otherGroup = existingGroups[remainingItemsGroupIndex];
        const theRestGroups = existingGroups.filter((_, index) => index !== remainingItemsGroupIndex);
        const otherGroupItemsCount = otherGroup.items.length;
        const newOtherGroup: Group = {
            ...otherGroup,
            items: otherGroup.items.concat(
                otherFields.map((field, index) => ({
                    refId: field.id,
                    name: field.name,
                    visible: true,
                    order: otherGroupItemsCount + index,
                }))
            ),
        };
        return [...theRestGroups, newOtherGroup];
    }
    const othersFieldsIds = otherFields.map((x) => x.id);
    const othersGroup = createDefaultGroup(
        allFields.filter((column) => othersFieldsIds.includes(column.id)),
        existingGroups.length,
        remainingFieldsGroupName
    );
    return [...existingGroups, othersGroup];
};

export const merge = (contractsLayout: Layout, dataColumns: DataColumn[], remainingFieldsGroupName: string): Layout => {
    const allFieldsIds = dataColumns.map((column) => column.id);
    const allMappedFieldsIds = contractsLayout.groups.flatMap((group) => group.items.map((item) => item.refId));
    const othersFieldsIds = allFieldsIds.filter((field) => !allMappedFieldsIds.includes(field));
    const othersFields = dataColumns.filter((field) => othersFieldsIds.includes(field.id));
    const filteredOutGroups = filterOutFieldsNotExistingAnymore(contractsLayout, allFieldsIds);
    const renamedGroupItems = updateNamesBasedOnDataColumns(filteredOutGroups, dataColumns);
    const enrichedByOtherFieldsGroups = appendNotMappedFields(
        dataColumns,
        renamedGroupItems,
        othersFields,
        remainingFieldsGroupName
    );
    return {
        id: contractsLayout.id,
        groups: enrichedByOtherFieldsGroups,
    };
};

export const createEmptyLayoutWithDefaultGlobalColumns = (defaultGroupName: string): Layout => ({
    id: "-1",
    groups: [
        {
            name: defaultGroupName,
            visible: true,
            order: 0,
            items: [],
        },
    ],
});
