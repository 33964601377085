import { CheckCircleSolid, ExclamationCircleSolid } from "@ignite-analytics/icons";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import { ShapeIcon } from "@ignite-analytics/components";
import { fileHasError } from "../../FileListItem/helpers";
import { UploadFileInfo } from "../../FileListItem/UploadFileInfo.model";
import { UploadFileStatus } from "../../FileListItem/UploadFileStatus.enum";

type Props = {
    file: UploadFileInfo;
};

const StatusIndicator: React.FC<Props> = ({ file }) => {
    switch (file.status) {
        case UploadFileStatus.Uploading:
            return <CircularProgress size={20} data-testid="file-progress" />;
        case UploadFileStatus.Uploaded:

            return (
                <ShapeIcon color="success">
                    <CheckCircleSolid data-testid="file-upload-success-icon" fontSize="inherit" />
                </ShapeIcon>
            );
        case UploadFileStatus.Selected:
            return <></>;
        default:
            return (
                <Tooltip title={fileHasError(file) ? file.message : ""}>
                    <ShapeIcon color="error">
                        <ExclamationCircleSolid data-testid="error-icon" fontSize="inherit" />
                    </ShapeIcon>
                </Tooltip>
            );
    }
};

export default StatusIndicator;
