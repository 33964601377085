import { Alert, Snackbar } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { AxiosError } from "axios";
import * as Sentry from "@sentry/react";
import { Explanation, httpErrorToExplanation } from "./errors";

export type ErrorHandlerContextType = {
    handleError: (error: AxiosError | SimpleGraphQLError | string | undefined) => void;
    captureSentryException: (error: unknown) => void;
};

export const ErrorHandlerContext = React.createContext<ErrorHandlerContextType | null>(null);

const isSimpleGraphQlError = (error: unknown): error is SimpleGraphQLError => {
    return typeof error === "object" && error !== null && "message" in error && error.message !== undefined;
};

type SimpleGraphQLError = {
    message: string;
};

const ErrorHandlerProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [explanation, setExplanation] = useState<Explanation>();
    const [open, setOpen] = useState(false);

    const captureSentryMessage = (error: string) => {
        Sentry.captureMessage(error, {
            tags: { app: "contracts-app" },
        });
    };

    const captureSentryException = useCallback((error: unknown) => {
        Sentry.captureException(error, {
            tags: { app: "contracts-app" },
        });
    }, []);

    const findExplanation = useCallback(
        (error: AxiosError | SimpleGraphQLError | string | undefined): Explanation => {
            if (error) {
                if (typeof error === "string") {
                    captureSentryMessage(error);
                    return error;
                }
                captureSentryException(error);
                if (isSimpleGraphQlError(error)) {
                    console.log("isSimpleGraphQlError", error.message);
                    console.dir(error, { depth: null });
                    return error.message;
                }
                return httpErrorToExplanation(error);
            }
            return "Unknown error";
        },
        [captureSentryException]
    );

    const handleError = useCallback(
        (error: AxiosError | SimpleGraphQLError | string | undefined) => {
            setExplanation(findExplanation(error));
            setOpen(true);
        },
        [findExplanation, setExplanation, setOpen]
    );

    const notificationContextTypeValue = useMemo(
        () => ({ handleError, captureSentryException }),
        [handleError, captureSentryException]
    );

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
        setExplanation(undefined);
    };

    return (
        <ErrorHandlerContext.Provider value={notificationContextTypeValue}>
            {children}
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
                    {explanation}
                </Alert>
            </Snackbar>
        </ErrorHandlerContext.Provider>
    );
};

function useErrorHandler(): ErrorHandlerContextType {
    const ctx = React.useContext(ErrorHandlerContext) as ErrorHandlerContextType;
    if (!ctx) {
        throw new Error("useErrorHandler hook must be used within a ErrorHandlerProvider");
    }
    return ctx;
}

export { ErrorHandlerProvider, useErrorHandler };
