import { gql, useLazyQuery } from "@apollo/client";
import { toSnakeCase } from "src/helpers/toSnakeCase";
import { useElasticFields } from "@ignite-analytics/elastic-fields";
import { generators } from "@ignite-analytics/filters";
import { useCallback, useMemo } from "react";
import { isObject } from "lodash";
import { DataTable } from "@/generated/client";

const getDataTableRowsSearchGQL = gql`
    query getDataTableRowsSearch($input: GetDataTableRowsSearchInput!) {
        getDataTableRowsSearch(input: $input) {
            total
            dataTableRows {
                id
                departmentId
                dataJson
            }
        }
    }
`;

export function useTableRelationSearch(dataTable: DataTable) {
    const elasticFields = useElasticFields(dataTable.elasticIndex, false);

    const nameColumnElasticField = useMemo(() => {
        if (elasticFields) {
            return elasticFields.find((f) => f.fieldId === `data_column_${dataTable.nameColumnId}`);
        }
        return undefined;
    }, [elasticFields, dataTable]);

    const prepareFilter = useCallback(
        (searchTerm: string) => {
            if (nameColumnElasticField) {
                if (nameColumnElasticField.type === "text" || nameColumnElasticField.type === "keyword") {
                    return [
                        generators.generateSearchTermFilter(
                            nameColumnElasticField.field, nameColumnElasticField.type, searchTerm
                        )
                    ];
                }

                if (nameColumnElasticField.type === "date") {
                    return [
                        generators.generateStaticDateFilter(
                            nameColumnElasticField.field,
                            "date",
                            searchTerm,
                            searchTerm,
                        ),
                    ];
                }
            }
            return [];
        },
        [nameColumnElasticField]
    );

    const [getDataTableRowsSearchLazyQuery] = useLazyQuery<{
        getDataTableRowsSearch: {
            total: number;
            dataTableRows: {
                id: string;
                departmentId: number;
                dataJson: string;
            }[];
        };
    }>(getDataTableRowsSearchGQL);

    const getLabelValueFromJson = useCallback((jsonData: string, columnId: string) => {
        const data = JSON.parse(jsonData);
        if (isObject(data[columnId])) {
            return (data[columnId] as any)?.id || "";
        }
        return data[columnId];
    }, []);

    const search = useCallback(
        async (phrase: string) => {
            const filter = prepareFilter(phrase);
            try {
                const response = await getDataTableRowsSearchLazyQuery({
                    variables: {
                        input: {
                            index: 0,
                            range: filter.length ? 10 : 100,
                            request: {
                                dataTableId: dataTable.id,
                                filterJSON: JSON.stringify(toSnakeCase(filter, false)),
                                sortingArray: [],
                            },
                        },
                    },
                });
                if (response.data) {
                    return response.data.getDataTableRowsSearch.dataTableRows.map((row) => ({
                        label: getLabelValueFromJson(row.dataJson, dataTable.nameColumnId || ""),
                        id: row.id,
                    }));
                }
                return [];
            } catch (error: unknown) {
                return [];
            }
        },
        [dataTable, getDataTableRowsSearchLazyQuery, prepareFilter, getLabelValueFromJson]
    );

    return {
        search,
    };
}
