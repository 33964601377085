import { defineMessages } from "react-intl";

const messages = defineMessages({
    somethingWentWrong: {
        id: "global.somethingWentWrong",
        description: "Something went wrong",
        defaultMessage: "Something went wrong",
    },
});

export default messages;
