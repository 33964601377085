import { Skeleton } from "@mui/material";
import { useContacts } from "src/entities/users";
import { Contacts } from "../ui/ContactDropdown/contacts";
import { makeMessages } from "./messages";

type Props = {
    userIds: string[];
    maxHeight?: string;
    canEdit: boolean;
    loading: boolean;
    onChange: (userIds: string[]) => void;
};

export const ContractResponsibles = ({ onChange, loading, userIds, maxHeight, canEdit }: Props) => {
    const messages = makeMessages();

    const { loading: contactsLoading, contacts } = useContacts();

    if (contactsLoading || loading)
        return (
            <>
                <Skeleton height="80px" animation="wave" />
                <Skeleton height="80px" animation="wave" />
            </>
        );
    return (
        <Contacts
            canEdit={canEdit}
            messages={messages}
            maxHeight={maxHeight}
            isOptionEqualToValue={(opt, val) => opt.email === val.email}
            defaultValue={contacts.filter((user) => userIds.includes(user.id))}
            onChange={(contactsList) => onChange(contactsList.map((contactListItem) => contactListItem.id))}
            options={contacts}
        />
    );
};
