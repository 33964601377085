import { defineMessages } from "react-intl";

const messages = defineMessages({
    invalidDataTableConfig: {
        id: "errors.invalidDataTableConfig",
        description: "Invalid contracts data table Configuration",
        defaultMessage: "Invalid contracts data table Configuration",
    },
});

export default messages;
