import { fm } from "src/contexts/IntlContext";
import { EyeSolid, EyeSlashSolid, Drag } from "@ignite-analytics/icons";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import { partition, random } from "lodash";
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { Group, Item } from "src/types/Layout";
import FieldsGroupHeader from "./FieldsGroupHeader";
import { moveItemToAnotherList, reorderItem } from "./helpers";

import messages from "./messages";

const HOVER_LIST_COLOR = "#eee";
const DRAG_ITEM_COLOR = "#ddd";

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    // change background colour if dragging
    background: isDragging ? DRAG_ITEM_COLOR : "inherit",

    // styles we need to apply on draggables
    ...draggableStyle,
});
const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? HOVER_LIST_COLOR : "#fff",
    marginBottom: isDraggingOver ? "3em" : 0,
});

const normalizeGroups = (groups: Group[]): Group[] =>
    groups.map((group, index) => {
        if (!group.id) {
            return {
                ...group,
                id: `${-1 * index}`,
            };
        }
        return group;
    });

interface Props {
    groups: Group[];
    disableHover?: boolean;
    onUpdate: (groups: Group[]) => void;
}

const DragAndDropBoard: React.FC<Props> = ({ groups, onUpdate, disableHover = false }) => {
    const [hoveredGroupIndex, setHoveredGroupIndex] = useState<number | undefined>();
    const normalizedGroups = normalizeGroups(groups);

    const onDragEnd = (result: DropResult) => {
        const { source, destination } = result;
        // dropped outside the list
        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            const updatedGroups = normalizedGroups.map((group: Group) => {
                if (group.id === source.droppableId) {
                    return reorderItem(group, source.index, destination.index);
                }
                return group;
            });

            onUpdate(updatedGroups);
        } else {
            const itemToMove = normalizedGroups.find((g) => g.id === source.droppableId)?.items[source.index];

            if (itemToMove) {
                const updatedGroups = moveItemToAnotherList(source, destination, itemToMove, normalizedGroups);

                onUpdate(updatedGroups);
            }
        }
    };

    const moveGroup = (groupId: string | undefined, currentGroupIndex: number, moveIndex: number) => {
        if (groupId) {
            const destinationIndex = currentGroupIndex + moveIndex;

            const [[groupToMove], remainingGroups] = partition(normalizedGroups, (group) => group.id === groupId);

            remainingGroups.splice(destinationIndex, 0, groupToMove);

            onUpdate(remainingGroups);
        }
    };

    const toggleVisibility = (groupToChange: Group, itemToChange?: Item) => {
        const updatedGroups = normalizedGroups.map((group) => {
            if (itemToChange) {
                return {
                    ...group,
                    items: group.items.map((item) =>
                        item.refId === itemToChange.refId ? { ...item, visible: !item.visible } : item
                    ),
                };
            }
            return {
                ...group,
                visible: groupToChange.id === group.id ? !group.visible : group.visible,
            };
        });

        onUpdate(updatedGroups);
    };

    const addItemsToOthersGroup = (itemsToAdd: Item[]): Group[] => {
        const otherGroupExists =
            normalizedGroups.filter((group) => group.name === fm(messages.defaultOtherGroupName).toString()).length > 0;

        if (otherGroupExists) {
            return normalizedGroups.map((group) => {
                if (group.name === fm(messages.defaultOtherGroupName).toString()) {
                    return {
                        ...group,
                        items: [...group.items, ...itemsToAdd],
                    };
                }

                return group;
            });
        }

        return [
            ...normalizedGroups,
            {
                id: `${-random(1, 10000)}`,
                name: fm(messages.defaultOtherGroupName).toString(),
                visible: true,
                items: itemsToAdd,
                order: normalizedGroups.length,
            },
        ];
    };

    const deleteGroup = (groupId: string | undefined, groupIndex: number) => {
        const groupToRemove = normalizedGroups[groupIndex];
        const groupsAfterItemsManipulation = addItemsToOthersGroup(groupToRemove.items);

        onUpdate(groupsAfterItemsManipulation.filter((g) => g.id !== groupId));
    };

    const updateGroup = (indexToUpdate: number, updatedGroup: Group) => {
        const updatedGroups = normalizedGroups.map((group, index) => {
            if (index === indexToUpdate) {
                return updatedGroup;
            }
            return group;
        });
        onUpdate(updatedGroups);
    };

    const addNewGroup = (existingGroups: Group[]) => {
        const newGroup: Group = {
            id: `${-random(1, 10000)}`,
            items: [],
            name: fm(messages.defaultNewGroupName).toString(),
            order: existingGroups.length,
            visible: true,
        };

        onUpdate([...existingGroups, newGroup]);
    };

    return (
        <div data-testid="drag-and-drop-board">
            <div style={{ display: "flex", flexDirection: "column" }}>
                <DragDropContext onDragEnd={onDragEnd} key="drag-and-drop-board">
                    {normalizedGroups.map((group, groupIndex) => (
                        <Droppable key={`${group.name.replace(" ", "")}`} droppableId={`${group.id}`}>
                            {(provided, snapshot) => (
                                <Grid
                                    onMouseOver={() => setHoveredGroupIndex(groupIndex)}
                                    onMouseLeave={() => setHoveredGroupIndex(undefined)}
                                    sx={{
                                        padding: "1em",
                                        backgroundColor:
                                            !disableHover && groupIndex === hoveredGroupIndex
                                                ? HOVER_LIST_COLOR
                                                : "inherit",
                                    }}
                                >
                                    <FieldsGroupHeader
                                        group={group}
                                        onMoveGroupDown={() => moveGroup(group.id, groupIndex, 1)}
                                        onMoveGroupUp={() => moveGroup(group.id, groupIndex, -1)}
                                        onToggleVisibility={() => toggleVisibility(group)}
                                        showGroupOptions={hoveredGroupIndex === groupIndex}
                                        hideDeleteGroup={
                                            normalizedGroups.length === 1 ||
                                            group.name === fm(messages.defaultOtherGroupName).toString()
                                        }
                                        onDeleteGroup={() => deleteGroup(group.id, groupIndex)}
                                        disableMoveGroupDown={groupIndex === normalizedGroups.length - 1}
                                        disableMoveGroupUp={groupIndex === 0}
                                        onNameEdit={(newName: string) =>
                                            updateGroup(groupIndex, { ...group, name: newName })
                                        }
                                    />

                                    <List
                                        ref={provided.innerRef}
                                        style={getListStyle(snapshot.isDraggingOver)}
                                        {...provided.droppableProps}
                                    >
                                        {group.items.map((item, index) => (
                                            <Draggable key={item.refId} draggableId={item.refId} index={index}>
                                                {(prov, snap) => (
                                                    <ListItem
                                                        ref={prov.innerRef}
                                                        {...prov.draggableProps}
                                                        {...prov.dragHandleProps}
                                                        style={getItemStyle(snap.isDragging, prov.draggableProps.style)}
                                                        divider={index < group.items.length - 1}
                                                    >
                                                        <Grid
                                                            alignItems="center"
                                                            display="flex"
                                                            justifyContent="space-between"
                                                            width="100%"
                                                        >
                                                            <Grid item display="flex">
                                                                <Drag sx={{ marginRight: "0.5em" }} />
                                                                <ListItemText primary={item.name} />
                                                            </Grid>

                                                            <Grid display="flex" alignContent="center">
                                                                <IconButton
                                                                    onClick={() => toggleVisibility(group, item)}
                                                                    role="switch"
                                                                >
                                                                    {item.visible ? <EyeSolid /> : <EyeSlashSolid />}
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    </ListItem>
                                                )}
                                            </Draggable>
                                        ))}
                                        {!group.items.length && (
                                            <Stack
                                                sx={{
                                                    height: "10em",
                                                    textAlign: "center",
                                                    lineHeight: "10em",
                                                    background: HOVER_LIST_COLOR,
                                                }}
                                            >
                                                {fm(messages.emptyGroupMessage)}
                                            </Stack>
                                        )}
                                    </List>
                                </Grid>
                            )}
                        </Droppable>
                    ))}
                </DragDropContext>
                <Button variant="text" onClick={() => addNewGroup(normalizedGroups)}>
                    {fm(messages.addGroupButton)}
                </Button>
            </div>
        </div>
    );
};

export default DragAndDropBoard;
