import { Email, Phone } from "@ignite-analytics/icons";
import { List, ListItem, ListItemIcon, ListItemText, Typography, Link } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import type { Contact } from "src/entities/users";

type Props = {
    contacts: Contact[];
    horizontal?: boolean;
    emptyInfoText?: string;
    showPhone?: true;
    secondaryAction?: (contact: Contact) => JSX.Element;
};

type ListItemProps = {
    contact: Contact;
    horizontal?: boolean;
    showPhone?: true;
    secondaryAction?: JSX.Element;
};

const horizontalListCss = { display: "flex", flexWrap: "wrap", flexDirection: "row", padding: 0 };
const horizontalItemCss = { marginRight: "10px", width: "auto" };

const ContactListItem = ({ contact, horizontal, showPhone, secondaryAction }: ListItemProps) => (
    <ListItem
        style={horizontal ? horizontalItemCss : {}}
        key={contact.id}
        aria-label={`${contact.firstName} ${contact.lastName}`}
        sx={{ paddingLeft: 0 }}
        secondaryAction={secondaryAction}
    >
        <ListItemIcon title={`${contact.firstName} ${contact.lastName}`}>
            <Avatar alt={`${contact.firstName} ${contact.lastName}`} src={contact.profilePicture} />
        </ListItemIcon>
        <ListItemText
            primary={`${contact.firstName} ${contact.lastName}`}
            secondaryTypographyProps={{ style: { whiteSpace: "normal" } }}
            secondary={
                <span>
                    <Link href={`mailto:${contact.email}`}>
                        <Email sx={{ fontSize: "1rem", marginRight: ".25rem", marginBottom: "-.2rem" }} />
                    </Link>
                    {contact.email}
                    <br />
                    {showPhone && (
                        <>
                            <Link href={contact.phone ? `callto:${contact.phone}` : undefined}>
                                <Phone sx={{ fontSize: "1rem", marginRight: ".25rem", marginBottom: "-.2rem" }} />
                            </Link>
                            {contact.phone ? contact.phone : "N/A"}
                        </>
                    )}
                </span>
            }
        />
    </ListItem>
);
export const ContactsList = ({ contacts, horizontal, emptyInfoText, showPhone, secondaryAction }: Props) =>
    contacts.length ? (
        <List dense={horizontal} sx={horizontal ? horizontalListCss : {}}>
            {contacts.map((contact) => (
                <ContactListItem
                    contact={contact}
                    horizontal={horizontal}
                    showPhone={showPhone}
                    secondaryAction={secondaryAction?.(contact)}
                    key={`contact-list-item-${contact.id}`}
                />
            ))}
        </List>
    ) : (
        <Typography variant="body1">{emptyInfoText}</Typography>
    );

export { Contact };
