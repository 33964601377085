import React, { useEffect, useState } from "react";
import { useUserSearch } from "src/entities/users";
import UserDropDown, { UserOptionItem } from "../../UserDropdown";

interface UserRendererWrapperProps {
    selectedUsersIds: string[];
    fieldLabel: string;
    multi: boolean;
    readOnly?: boolean;
    onSelect: (ids: string[]) => void;
}

const UserRendererWrapper: React.FC<UserRendererWrapperProps> = ({
    selectedUsersIds,
    fieldLabel,
    multi,
    readOnly,
    onSelect,
}) => {
    const { users } = useUserSearch();
    const [userValues, setUserValues] = useState<UserOptionItem[]>([]);

    useEffect(() => {
        if (selectedUsersIds.length) {
            const usersValuesToSet = users
                .filter((user) => selectedUsersIds.includes(user.id))
                .map((user) => ({
                    id: user.id,
                    label: `${user.firstName} ${user.lastName}`,
                    email: user.email,
                }));
            setUserValues(usersValuesToSet);
        } else {
            setUserValues([]);
        }
    }, [selectedUsersIds, users]);

    const handleOnSelect = (selectedUsers: UserOptionItem[]) => {
        setUserValues(selectedUsers);
        onSelect(selectedUsers.map((u) => u.id));
    };

    return (
        <UserDropDown
            multi={multi}
            fieldLabel={fieldLabel}
            onSelect={handleOnSelect}
            value={userValues}
            readonly={readOnly}
        />
    );
};

export default UserRendererWrapper;
