import { defineMessages } from "react-intl";

const messages = defineMessages({
    newSupplierLabel: {
        id: "supplierDropdown.newSupplierLabel",
        description: "Label info for creating new supplier",
        defaultMessage: "Create new supplier",
    },
});

export default messages;
