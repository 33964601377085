import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { useFetchGroupChildrenBy, fetchCategories, Leaf, fetchCategoriesByIds } from "../../hooks/useCategories";
import { CategoriesChips } from "./dumb/categoriesChips";
import { Popup } from "../ui/Popup/popup";
import { CategoryTree as Tree } from "./dumb/categoryTree";
import { labels } from "./messages";

type Props = {
    categoryId: string;
    selectedIds: string[];
    readOnly: boolean;
    label: string;
    multiselect?: boolean;
    onSubmit: (nodes: Leaf[]) => void;
};

const addChildren = (tree: Leaf[], nodeId: string, children: Leaf[]) => {
    for (let index = 0; index < tree.length; index += 1) {
        const node = tree[index];
        if (node.id === nodeId && !node.children) {
            node.children = children;
        } else if (node.children) {
            addChildren(node.children, nodeId, children);
        }
    }
};

export const CategoryTree = ({
    categoryId,
    selectedIds: selectedIdsProp,
    label,
    readOnly,
    multiselect,
    onSubmit,
}: Props) => {
    const { data: categoryNodes, mutate, isLoading: isLoadingTreeNodes } = useFetchGroupChildrenBy(categoryId);
    const [loadedIds, setLoadedIds] = useState<string[]>([]);
    const [isLoadingSelectedNodes, setIsLoadingSelectedNodes] = useState(false);
    const [nodeIdBeingFetched, setNodeIdBeingFetched] = useState<string>();
    const [selectedNodes, setSelectedNodes] = useState<Leaf[]>([]);
    const [initialNodes, setInitialNodes] = useState<Leaf[]>([]);
    const [showPopup, setShowPopup] = useState(false);
    const [selectedIds, setSelectedIds] = useState(selectedIdsProp);

    useEffect(() => {
        if (JSON.stringify(selectedIds) !== JSON.stringify(selectedIdsProp)) {
            setSelectedIds(selectedIdsProp);
        }
    }, [selectedIdsProp, selectedIds]);

    const onExpanded = (expandedId: string) => {
        if (loadedIds.includes(expandedId)) return;
        setNodeIdBeingFetched(expandedId);
        fetchCategories(categoryId, expandedId).then((childrenNodes) =>
            mutate(async (data) => {
                if (data) {
                    addChildren(data, expandedId, childrenNodes);
                }
                setLoadedIds(loadedIds.concat(expandedId));
                return data;
            }).finally(() => setNodeIdBeingFetched(undefined))
        );
    };

    useEffect(() => {
        setIsLoadingSelectedNodes(true);
        fetchCategoriesByIds(selectedIds)
            .then((nodes) => {
                setSelectedNodes(nodes);
                setInitialNodes(nodes);
            })
            .finally(() => setIsLoadingSelectedNodes(false));
    }, [selectedIds]);

    return !isLoadingTreeNodes && !isLoadingSelectedNodes ? (
        <>
            <CategoriesChips
                label={label}
                readOnly={readOnly}
                onClick={() => setShowPopup(true)}
                names={initialNodes.map((n) => n.name)}
            />
            <Popup
                cancelLabel={labels.cancel}
                titleLabel={labels.title}
                submitLabel={labels.submit}
                onCancel={() => {
                    setShowPopup(false);
                    setSelectedNodes(initialNodes);
                }}
                onSubmit={async () => {
                    setShowPopup(false);
                    onSubmit(selectedNodes);
                }}
                isOpen={showPopup}
            >
                <Tree
                    nodes={categoryNodes}
                    multiselect={multiselect}
                    nodeIdBeingFetched={nodeIdBeingFetched}
                    onNodeExpanded={onExpanded}
                    onSelected={(node) => setSelectedNodes(multiselect ? selectedNodes.concat(node) : [node])}
                    onDelete={(id) => setSelectedNodes(selectedNodes.filter((n) => n.id !== id))}
                    selectedNodes={selectedNodes}
                />
            </Popup>
        </>
    ) : (
        <>
            <CircularProgress size={15} /> loading
            {label}
            ...
        </>
    );
};
