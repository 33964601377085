import { CustomField } from "./contractListItem";

export const format = (field: CustomField): string | Date | number | undefined | null => {
    switch (field.dataType) {
        case "TITLE":
        case "SUPPLIER":
            return field.data?.label;
        case "END_DATE":
        case "DATE_AGGREGATION":
        case "DATE":
            return field.data;
        case "TEXT":
        case "NUMBER":
        case "NUMBER_AGGREGATION":
        case "TAG":
            return field.data;
        case "TEXT_LIST":
            return field.data?.join(", ");
        case "MONETARY_AMOUNT":
            return field.data?.amount;
        case "BOOLEAN":
            return field.data?.toString();
        case "CONTACT":
        case "COMPANY":
        case "USER":
            return field.data?.label ?? "";
        case "GROUP_STRUCTURE":
            return field.data?.id?.label;
        case "TAG_LIST":
            return field.data?.join(", ");
        case "USER_LIST":
        case "CONTACT_LIST":
            return field.data?.map((data) => data.label).join(", ");
        case "GROUP_STRUCTURE_LIST":
            return field.data?.ids.map((item) => item.label).join(", ");
        default:
            return field.id;
    }
};
