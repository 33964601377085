import { Grid, Stack, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { FieldRendererComponent } from "src/components/FieldRendererComponent";
import { Layout, Item } from "src/types/Layout";
import { merge, createEmptyLayoutWithDefaultGlobalColumns } from "src/helpers/layoutMergeResolver";
import { CustomField } from "src/models/customFields";
import { useIntl } from "react-intl";

interface CustomFieldsSectionProps {
    layout: Layout | undefined;
    editMode: boolean;
    customFields: CustomField[];
    onChange: (customField: CustomField) => void;
}

interface EditLayout {
    id?: string;
    groups: EditGroup[];
}

interface EditGroup {
    id?: string;
    name: string;
    items: EditItem[];
    visible: boolean;
    order: number;
}

interface EditItem {
    visible: boolean;
    order: number;
    field: CustomField;
}

interface FieldRendereProps {
    editMode: boolean;
    item: EditItem;
    onChange: (customField: CustomField) => void;
}

const FieldRenderer: React.FC<FieldRendereProps> = ({ item, onChange, editMode }) => {
    const [customField, setCustomField] = useState<CustomField>(item.field);
    const handleOnSet = (value: CustomField) => {
        setCustomField(value);
        onChange(value);
    };
    useEffect(() => {
        if (editMode === false) {
            setCustomField(item.field);
        }
    }, [editMode, item.field]);
    return (
        <Grid item key={`${item.field.id}`} md={4}>
            <FieldRendererComponent customField={customField} onSetFields={handleOnSet} readOnly={!editMode} />
        </Grid>
    );
};

export const CustomFieldsSection: React.FC<CustomFieldsSectionProps> = ({
    layout: defaultLayout,
    editMode,
    customFields,
    onChange,
}) => {
    const [layout, setLayout] = useState<EditLayout | undefined>();
    const mapLayoutItemToLayoutEditItem = useCallback(
        (item: Item, customFieldsMap: { [key: string]: CustomField }): EditItem => ({
            ...item,
            field: customFieldsMap[item.refId],
        }),
        []
    );
    const { formatMessage } = useIntl();

    const appendDataColumnInfoToLayout = useCallback(
        (rawLayout: Layout, dataColumns: CustomField[]): EditLayout => {
            const columnsDict: { [key: string]: CustomField } = dataColumns.reduce(
                (acc, curr) => ({
                    ...acc,
                    [curr.id]: curr,
                }),
                {}
            );
            const enrichedGroups: EditGroup[] = rawLayout.groups
                ? rawLayout.groups.map((group) => ({
                      ...group,
                      items: group.items.map<EditItem>((item) => mapLayoutItemToLayoutEditItem(item, columnsDict)),
                  }))
                : [];
            return {
                ...rawLayout,
                groups: enrichedGroups,
            };
        },
        [mapLayoutItemToLayoutEditItem]
    );

    useEffect(() => {
        const customFieldsLayout =
            defaultLayout ?? createEmptyLayoutWithDefaultGlobalColumns(formatMessage({ defaultMessage: "Others" }));
        const mergedLayout = merge(customFieldsLayout, customFields, formatMessage({ defaultMessage: "Others" }));
        const layoutWithDataColumnInfo = appendDataColumnInfoToLayout(mergedLayout, customFields);
        setLayout(layoutWithDataColumnInfo);
    }, [defaultLayout, setLayout, formatMessage, appendDataColumnInfoToLayout, customFields]);

    return (
        <Grid
            container
            key="custom_fields_grid_container"
            direction="row"
            alignItems="center"
            pt={2}
            columnSpacing={2}
            rowSpacing={3}
        >
            {layout?.groups
                ?.filter((group) => group.visible)
                .map((layoutGroup, groupIndex) => (
                    <Grid item key={`custom_fields_grid_${layoutGroup.id || groupIndex}`} md={12} sx={{ mb: 2 }}>
                        <Stack key={`custom_fields_stack_${layoutGroup.id || groupIndex}`}>
                            <Typography key={`custom_fields_group_label_${layoutGroup.id || groupIndex}`}>
                                {layoutGroup.name}
                            </Typography>
                            <Grid
                                container
                                key={`custom_fields_group_fields_${layoutGroup.id || groupIndex}`}
                                direction="row"
                                columnSpacing={2}
                                rowSpacing={3}
                            >
                                {layoutGroup?.items
                                    ?.filter((item) => item.visible && item.field)
                                    .map((item) => (
                                        <FieldRenderer
                                            key={item.field.id}
                                            item={item}
                                            onChange={onChange}
                                            editMode={editMode}
                                        />
                                    ))}
                            </Grid>
                        </Stack>
                    </Grid>
                ))}
        </Grid>
    );
};
