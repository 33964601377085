import { fm } from "src/contexts/IntlContext";
import { Button, Dialog, Stack, Typography, DialogContent } from "@mui/material";
import React from "react";
import { LoadingButton } from "@mui/lab";
import messages from "./messages";

interface Props {
    open: boolean;
    onCancel: () => void;
    onDelete: () => Promise<void>;
}

const DeletePrompt = ({ open, onCancel, onDelete }: Props) => {
    const [loading, setLoading] = React.useState(false);
    function handleDelete() {
        setLoading(true);
        onDelete().finally(() => {
            setLoading(false);
        });
    }
    return (
        <Dialog open={open} onClose={() => onCancel()}>
            <DialogContent>
                <Stack gap={2}>
                    <Typography variant="textLg">{fm(messages.title)}</Typography>
                    <Typography variant="textMd">{fm(messages.description)}</Typography>
                </Stack>
                <Stack direction="row" justifyContent="flex-end" pt={3}>
                    <Button sx={{ mr: 1 }} onClick={() => onCancel()} variant="text" color="secondary">
                        {fm(messages.cancelButton)}
                    </Button>
                    <LoadingButton loading={loading} onClick={() => handleDelete()} variant="contained" color="error">
                        {fm(messages.deleteButton)}
                    </LoadingButton>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default DeletePrompt;
