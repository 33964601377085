import { DialogContent, Stack } from "@mui/material";
import { MediaPlayer, MediaProvider, Track } from "@vidstack/react";
import { defaultLayoutIcons, DefaultVideoLayout } from "@vidstack/react/player/layouts/default";

import demoSubs from "@/assets/videos/contractsdemo-en-US.vtt";
import demoVideo from "@/assets/videos/contractsdemo.mp4";

const WatchDemo = () => {
    return (
        <DialogContent sx={{ overflow: "visible" }}>
            <Stack justifyContent="center" alignItems="center" gap={2} p={2}>
                <MediaPlayer title="Contract overview demo" src={demoVideo} autoPlay>
                    <MediaProvider />
                    <DefaultVideoLayout icons={defaultLayoutIcons} />
                    <Track src={demoSubs} kind="subtitles" label="English" lang="en-US" default />
                </MediaPlayer>
            </Stack>
        </DialogContent>
    );
};

export default WatchDemo;
