import React, { useCallback, useContext, useEffect, useState } from "react";
import axios from "axios";
import { track } from "@ignite-analytics/track";
import { ExclamationCircle } from "@ignite-analytics/icons";
import { ShapeIcon } from "@ignite-analytics/components";
import { Skeleton, Stack, Typography } from "@mui/material";
import { ErrorHandlerContext, ErrorHandlerContextType } from "src/errorHandling/ErrorHandlerContext";
import { makeFileServiceHttp, makeContractsApiHttp } from "src/http";
import { useContractsApiPost } from "src/hooks/useContractsApiPost";
import FileUpload from "./dumb";
import { UploadFileInfo } from "./FileListItem/UploadFileInfo.model";
import { UploadFileStatus } from "./FileListItem/UploadFileStatus.enum";

interface FileUploadProps {
    referenceId: string;
    canEdit: boolean;
}

type Attachment = {
    id: string;
    name: string;
    url?: string;
    createdAt: number;
    isPublic: boolean;
    metadata?: { [key: string]: string };
};

const mapAttachmentToUploadFileInfo = (attachment: Attachment): UploadFileInfo => ({
    id: attachment.id,
    name: attachment.name,
    status: UploadFileStatus.Selected,
    file: undefined,
    message: undefined,
    progress: undefined,
    url: attachment.url,
    isPublic: attachment.isPublic,
});

const FileUploadSmartComponent: React.FC<FileUploadProps> = ({ referenceId, canEdit }: FileUploadProps) => {
    const { handleError } = useContext(ErrorHandlerContext) as ErrorHandlerContextType;
    const post = useContractsApiPost();
    const useFetchAttachments = (): {
        data: UploadFileInfo[];
        error: Error | undefined;
        loading: boolean;
        refetch: () => void;
        mutate: (calculateData: (info: UploadFileInfo[]) => UploadFileInfo[]) => void;
    } => {
        const [loading, setLoading] = useState(false);
        const [data, setData] = useState<UploadFileInfo[]>([]);
        const [error, setError] = useState<Error>();

        const fetchAttachments = useCallback(async (): Promise<UploadFileInfo[]> => {
            setLoading(true);
            const uploadFileInfo = makeContractsApiHttp()
                .get<Attachment[]>(`contracts/${referenceId}/files`)
                .then((res) => {
                    const uploadedFiles = res.data ?? [];
                    // eslint-disable-next-line fp/no-mutating-methods
                    return uploadedFiles.map(mapAttachmentToUploadFileInfo);
                })
                .catch((err) => {
                    handleError(err);
                    setError(err);
                    throw err;
                })
                .finally(() => setLoading(false));
            return uploadFileInfo;
        }, []);

        useEffect(() => {
            setLoading(true);
            fetchAttachments()
                .then((response) => setData(response))
                .finally(() => setLoading(false));
        }, [setLoading, fetchAttachments]);

        const refetch = useCallback(() => {
            fetchAttachments().then(setData);
        }, [fetchAttachments]);
        const mutate = (calculateData: (infos: UploadFileInfo[]) => UploadFileInfo[]) => {
            setData(calculateData(data ?? []));
        };

        return {
            data: data ?? [],
            error,
            loading,
            refetch,
            mutate,
        };
    };
    const { data: attachments, error, loading, refetch, mutate } = useFetchAttachments();

    const upload = (file: File) =>
        post<{ url: string }>(`contracts/${referenceId}/files/${file.name}/signedUrl`, {
            contentType: file.type,
        }).then((response) => ({ signedUrl: response, file }));

    const onFileSwitch = (fileId: string, checked: boolean) =>
        makeContractsApiHttp()
            .patch(`/contracts/${referenceId}/files/${fileId}`, {
                value: checked,
                operation: "replace",
                path: "/isPublic",
            })
            .catch(handleError)
            .finally(() => {
                track("File visibility change", { contractId: referenceId, isPublic: checked });
                refetch();
            });

    const onFileAdded = async (files: UploadFileInfo[]) => {
        track("File upload started", { contractId: referenceId });
        mutate((data) => [...data, ...files]);
        const notEmptyFiles = files.map((file) => file.file).filter((file) => file !== undefined);
        const [head, ...tail] = notEmptyFiles;
        const signedUrlHead = await upload(head);
        const signedUrlsTailRequests = tail.map(upload);
        const signedUrls = [signedUrlHead, ...(await Promise.all(signedUrlsTailRequests))];

        const uploads = signedUrls.map(({ file, signedUrl }) =>
            axios
                .put(signedUrl.url, file, {
                    headers: {
                        "Content-Type": file.type,
                    },
                })
                .then(() => refetch())
                .catch((err) => {
                    const errorMessage = err.response?.data ?? err.message;
                    const uploadedFile = attachments.find((x) => x.name === file.name);
                    if (uploadedFile !== undefined) {
                        uploadedFile.status = UploadFileStatus.UploadFailed;
                        uploadedFile.message = errorMessage;
                        mutate((data) => [...data.filter((a) => a.name !== uploadedFile?.name), uploadedFile]);
                    }
                    handleError(err);
                })
        );

        Promise.all(uploads);
    };

    const onFileRemoved = (file: UploadFileInfo) => {
        makeFileServiceHttp()
            .delete(`/api/files/${file.id}`)
            .then(() => mutate((data) => [...data.filter((uploadedFile) => uploadedFile.id !== file.id)]))
            .catch(handleError);
    };
    return (
        <>
            {loading && attachments.length === 0 && (
                <>
                    <Skeleton height="40px" animation="wave" />
                    <Skeleton height="40px" animation="wave" />
                    <Skeleton height="40px" animation="wave" />
                    <Skeleton height="40px" animation="wave" />
                </>
            )}
            {error && (
                <Stack alignItems="center">
                    <ShapeIcon color="error" size="large">
                        <ExclamationCircle fontSize="inherit" />
                    </ShapeIcon>
                    <Typography>{error.message ?? "Something went wrong, please try again later."}</Typography>
                </Stack>
            )}
            {attachments && (
                <FileUpload
                    onFilesSelect={onFileAdded}
                    files={attachments}
                    onFileRemove={onFileRemoved}
                    onSwitch={onFileSwitch}
                    freeze={loading && attachments.length > 0}
                    canEdit={canEdit}
                />
            )}
        </>
    );
};

export default FileUploadSmartComponent;
