import { IconButton, Stack, Typography } from "@mui/material";
import { Plus } from "@ignite-analytics/icons";
import React, { useState } from "react";
import { AddAlertPopup } from "./addPopup";
import { NewAlert } from "./models";

export const AddFooter = ({ onAdd }: { onAdd: (alert: NewAlert) => void }) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    return (
        <>
            <AddAlertPopup
                isOpen={isPopupOpen}
                onCancel={() => setIsPopupOpen(false)}
                onSubmit={(newAlert) => {
                    onAdd(newAlert);
                    setIsPopupOpen(false);
                }}
            />
            <Stack sx={{ marginTop: 2 }} direction="row" justifyContent="end">
                <IconButton size="small" onClick={() => setIsPopupOpen(true)}>
                    <Plus fontSize="inherit" sx={{ mr: 0.5 }} />
                    <Typography>Add</Typography>
                </IconButton>
            </Stack>
        </>
    );
};
