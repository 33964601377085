import { useCallback } from "react";
import { useIntl } from "react-intl";

interface Breadcrumb {
    text: string;
    href?: string;
}

interface BasicPageInfo {
    page: "overview" | "list";
}

interface DetailPageInfo {
    page: "detail";
    title: string;
}

type PageInfo = BasicPageInfo | DetailPageInfo;

export function useBreadcrumbs() {
    const { formatMessage } = useIntl();

    const updateBreadCrumbs = useCallback(
        (page: PageInfo) => {
            const suppliersCrumb: Breadcrumb = {
                text: formatMessage({ defaultMessage: "Suppliers", description: "Suppliers breadcrumb" }),
                href: "/suppliers/overview",
            };
            const contractsCrumb: Breadcrumb = {
                text: formatMessage({ defaultMessage: "Contracts", description: "Contracts breadcrumb" }),
                href: "/contracts/list",
            };
            const contractsListCrumb: Breadcrumb = {
                text: formatMessage({ defaultMessage: "Contract list", description: "Contract list breadcrumb" }),
                href: "/contracts/list",
            };

            const breadcrumbs: Breadcrumb[] = [suppliersCrumb, contractsCrumb];
            switch (page.page) {
                case "overview":
                    breadcrumbs.push({
                        text: formatMessage({ defaultMessage: "Overview", description: "Overview breadcrumb" }),
                    });
                    break;
                case "list":
                    breadcrumbs.push(contractsListCrumb);
                    break;
                case "detail":
                    breadcrumbs.push(contractsListCrumb);
                    breadcrumbs.push({ text: page.title });
                    break;
                default:
                    page satisfies never;
            }

            const data = { source: "ignite-breadcrumbs", payload: breadcrumbs }
            window.postMessage(data, window.location.origin);
        },
        [formatMessage]
    );

    return {
        updateBreadCrumbs,
    };
}
