import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { apolloClient } from "@/contexts";
import { graphql } from "@/generated";
import { captureMessage } from "@/errorHandling/errors";

const getSupplierQuery = graphql(`
    query getSupplier($input: GetSupplierInput!) {
        getSupplier(input: $input) {
            supplier {
                id
                name
            }
        }
    }
`);

export async function getSupplierNameById(id: string) {
    const result = await apolloClient.query({
        query: getSupplierQuery,
        variables: {
            input: {
                id,
            },
        },
    });
    if (result.errors) {
        captureMessage("Error fetching supplier name", {
            extra: {
                error: result.errors[0].message,
            },
        });
        return "";
    }
    const { name } = result.data.getSupplier.supplier;
    return name;
}

export function useFetchSupplier(supplierId?: string) {
    const { data, error, loading } = useQuery<{
        getSupplier: { supplier: { id: string; name: string; country: string } };
    }>(getSupplierQuery, {
        skip: !supplierId,
        variables: {
            input: {
                id: supplierId,
            },
        },
    });

    const supplier = useMemo(() => {
        if (data?.getSupplier?.supplier) {
            return {
                id: data.getSupplier.supplier.id,
                label: data.getSupplier.supplier.name,
                country: data.getSupplier.supplier.country,
                vatId: "",
            };
        }
        return undefined;
    }, [data]);

    return {
        supplier,
        error,
        loading,
    };
}
