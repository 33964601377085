import { Skeleton } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useSearchCompanies } from "src/hooks/useSearchCompanies";
import { Dropdown, CompanyOptionItem } from "./dumb";
import { useGetCompany } from "../../hooks/useGetCompany";
import { useMatchCompanyMaster } from "../../hooks/useMatchCompanyMaster";

interface CompanyDropDownProps {
    companyId?: string;
    fieldLabel: string;
    readOnly?: boolean;
    onSelect: (selectedValue?: CompanyOptionItem) => void;
    debounceMs?: number;
}

export const CompanyDropdown = ({
    companyId,
    fieldLabel,
    readOnly: readonly,
    onSelect,
    debounceMs,
}: CompanyDropDownProps) => {
    const { company, loading } = useGetCompany(companyId ?? "");
    const [searchLoading, setLoading] = useState<boolean>(false);
    const [options, setOptions] = useState<CompanyOptionItem[]>();
    const { getCompanyMasterId } = useMatchCompanyMaster();
    const [search] = useSearchCompanies();

    const handleOnSelect = async (selectedValue: CompanyOptionItem[]) => {
        if (selectedValue) {
            const selectedCompany = selectedValue[0];
            if (selectedCompany) {
                const companyMasterId = await getCompanyMasterId({
                    country: selectedCompany.country,
                    orgNumber: selectedCompany.orgNumber,
                    id: selectedCompany.id,
                    name: selectedCompany.label,
                });

                onSelect({
                    ...selectedValue[0],
                    id: companyMasterId,
                });
            } else {
                onSelect(undefined);
            }
        }
    };
    const handleOnSearch = useCallback(
        async (phrase: string) => {
            setLoading(true);
            try {
                const companies = await search(phrase);
                const mappedCompanies: CompanyOptionItem[] = (companies || []).map((c: any) => ({
                    id: c.companyMasterId,
                    label: c.name,
                    orgNumber: c.orgNumber,
                    country: c.country,
                }));
                setOptions(mappedCompanies);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        },
        [search]
    );
    const value = useMemo(() => {
        if (company) {
            return [
                {
                    id: company.id,
                    country: company.country,
                    label: company.name,
                    orgNumber: company.orgNumber,
                },
            ];
        }
        return [];
    }, [company]);

    if (loading) {
        return <Skeleton data-testid="company-field-loader" animation="wave" variant="rounded" height="3em" />;
    }
    return (
        <Dropdown
            fieldLabel={fieldLabel}
            onSelect={(selected) => {
                handleOnSelect(selected);
            }}
            companyOptions={options ?? []}
            value={value}
            readonly={readonly}
            debounceMs={debounceMs}
            onSearch={handleOnSearch}
            loading={searchLoading}
        />
    );
};
