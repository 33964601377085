import { IconButton, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Trash, BellSolid } from "@ignite-analytics/icons";
import { formatDateAsUTC as formatDate } from "@ignite-analytics/locale";
import { Alert } from "./models";

type Props = {
    alerts: Alert[];
    onDelete: (id: string) => void;
    canEdit: boolean;
};

export const AlertsList = ({ alerts, onDelete, canEdit }: Props) => (
    <List>
        {alerts.map((alert) => (
            <ListItem
                key={alert.id}
                sx={{ paddingLeft: 0 }}
                secondaryAction={
                    canEdit && (
                        <IconButton aria-label="Delete" onClick={() => onDelete(alert.id)}>
                            <Trash />
                        </IconButton>
                    )
                }
            >
                {alert.triggeredAt && (
                    <ListItemIcon title={alert.triggeredAt ? "Alert already triggered" : ""}>
                        <BellSolid color="primary" />
                    </ListItemIcon>
                )}
                <ListItemText primary={alert.message} secondary={formatDate(alert.scheduledOn)} />
            </ListItem>
        ))}
    </List>
);
