import {
    DataGridPro,
    GridColumnVisibilityModel,
    GridRenderCellParams,
    GridDensity,
    GridLocaleText,
    GridColumnHeaderParams,
    GridActionsCellItem,
    GridApiPro,
    GRID_CHECKBOX_SELECTION_COL_DEF,
    GridColDef,
    GridSortDirection,
    GridSortItem,
    gridClasses,
    DataGridProProps,
} from "@mui/x-data-grid-pro";
import { Chip, Typography } from "@mui/material";
import { Plus } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import { hasValue } from "src/helpers/hasValue";
import { MutableRefObject, useMemo } from "react";
import { RenderCustomField } from "./renderCustomField";
import { format } from "./valueFormatter";
import { ColumnHeader } from "./column";
import { Row } from "./contractListItem";

interface ContractsTableProps {
    headers: ColumnHeader[];
    rows: Row[];
    sortBy: string | undefined;
    sortDirection: GridSortDirection;
    orderedColumnsIds: string[];
    pageSize: number;
    loading: boolean;
    density: GridDensity;
    currentPage: number;
    total: number;
    checkboxSelection: boolean;
    columnVisibilityModel: GridColumnVisibilityModel;
    messages: ContractTableMessages;
    pinnedColumns: string[];
    onColumnsOrderChanged: (headersIds: string[]) => void;
    onColumnWidthChanged: (coldId: string, width: number) => void;
    onColumnVisibilityModelChange: (model: GridColumnVisibilityModel) => void;
    onSortChange: (sortBy: string | undefined, sortDirection: GridSortDirection) => void;
    onPaginationChange: (p: { page: number; pageSize: number }) => void;
    apiRef: MutableRefObject<GridApiPro> | undefined;
}

interface ColumnMessages {
    privateLabel: string;
    publicLabel: string;
}

interface ColumnMenuMessages {
    removeColumn: string;
    editColumn: string;
}

export interface ContractTableMessages {
    muiMessages: Partial<GridLocaleText>;
    columnMessages: ColumnMessages;
    columnMenuMessages: ColumnMenuMessages;
}

const NOT_SORTABLE_COLUMNS = ["newcolumn", "isprivate"];
const COLUMNS_WIHT_DISABLED_MENU = ["newcolumn"];
const NOT_RESIZABLE_COLUMNS = ["newcolumn"];
const RIGHT_PINNED_COLUMNS = ["newcolumn"];

const isColumnSortable = (headerId: string) => !NOT_SORTABLE_COLUMNS.includes(headerId);
const isColumnMenuDisabled = (headerId: string) => COLUMNS_WIHT_DISABLED_MENU.includes(headerId);
const isColumnResizable = (headerId: string) => !NOT_RESIZABLE_COLUMNS.includes(headerId);

const renderHeader = (headerId: string, label: string) => {
    if (headerId === "newcolumn") {
        return <GridActionsCellItem label="Add" icon={<Plus fontSize="small" />} disabled={false} />;
    }
    return label;
};

const headerToMuiColumn = (
    header: ColumnHeader,
    msgs: ColumnMessages
): GridColDef<Row> & { fieldType: string } => ({
    field: header.id,
    fieldType: header.fieldType,
    headerName: header.label,
    align: header.align,
    sortable: isColumnSortable(header.id),
    width: header.width,
    disableColumnMenu: isColumnMenuDisabled(header.id),
    resizable: isColumnResizable(header.id),
    renderHeader: (_params: GridColumnHeaderParams<Row>) => renderHeader(header.id, header.label),
    valueGetter: (params: GridRenderCellParams<Row>) => {
        const cell = params.row.fields.find((row) => row.id === header.id);
        return cell ? format(cell) : "";
    },
    renderCell: (params: GridRenderCellParams<Row>) => {
        const cell = params.row.fields.find((row) => row.id === header.id);
        if (header.id === "isprivate") {
            return (
                <Typography sx={{ textAlign: "center" }}>
                    <Chip
                        sx={{ m: 0.5 }}
                        label={params.row.isPrivate ? msgs.privateLabel : msgs.publicLabel}
                        color={params.row.isPrivate ? "warning" : "success"}
                        size="small"
                    />
                </Typography>
            );
        }
        return cell ? <RenderCustomField field={cell} /> : <></>;
    },
});

// TODO: Style table in theme, and remove these **********
const defaultSlotProps: DataGridProProps["slotProps"] = {
    baseButton: {
        color: "secondary",
        size: "small",
    },
};

const defaultSx: DataGridProProps["sx"] = {
    [`& .${gridClasses.toolbarContainer}`]: {
        padding: (t) => t.spacing(2.5),
        display: "flex",
        flexDirection: "row",
        spacing: (t) => t.spacing(1.5),
        justifyContent: "flex-end",
        alignItems: "center",
        borderBottom: "1px solid var(--DataGrid-rowBorderColor)",
    },
    /**
     * @todo This should be fixed in the theme package. Sets the background color of the pinned columns to the same as the container background.
     * Intentionally adding here to test get feedback from the design team on the interactions before
     * addressing it in the theme package.
     */
    [`& .${gridClasses.columnHeader}`]: {
        "--DataGrid-pinnedBackground": "var(--DataGrid-containerBackground)",
    },
    [`& .${gridClasses.editInputCell}`]: {
        borderRadius: "4px",
    },
    [`& .${gridClasses.cell}:focus-within`]: {
        outlineColor: (t) => t.palette.secondary.main,
        borderRadius: "4px",
    },
    [`& .${gridClasses.cell}.${gridClasses["cell--editing"]}:focus-within`]: {
        outlineColor: (t) => t.palette.secondary.main,
    },
    [`& .${gridClasses.cell}.${gridClasses["cell--editable"]}:hover`]: {
        outline: (t) => `1px solid ${t.palette.secondary.main}`,
        outlineOffset: "-1px",
        borderRadius: "4px",
    },
};
//
// stolen from suppliers app
const customGridClasses = {
    dragIndicator: "CustomMuiDataGrid-dragIndicator",
} as const;
/**
 * These are purposely defined outside the component to avoid them being recreated on every render.
 */
const customDataGridStyling: DataGridProProps["sx"] = {
    ...defaultSx,
    "--unstable_DataGrid-radius": "0px",
    /**
     * For column headers that have `draggable="true"` attribute we display the drag indicator icon
     */
    [`& .${gridClasses.columnHeaderDraggableContainer}[draggable="true"] .${customGridClasses.dragIndicator}`]: {
        display: "inline-block",
    },
    "& .MuiDataGrid-pinnedColumnHeaders": { background: (theme) => theme.palette.gray.main },
    "& .MuiDataGrid-columnHeaders": { background: (theme) => theme.palette.gray.main },
    "& .MuiDataGrid-cell[data-colindex='0']": { background: (theme) => theme.palette.gray.main },
    // Add a border radius of 1
    borderRadius: 1,
};
// TODO: Style table in theme, and remove these **********

export const TablePro = ({
    rows,
    headers,
    columnVisibilityModel,
    onColumnVisibilityModelChange,
    onSortChange,
    pageSize,
    density,
    loading,
    currentPage,
    orderedColumnsIds,
    checkboxSelection,
    messages,
    onColumnWidthChanged,
    onPaginationChange,
    onColumnsOrderChanged,
    total,
    apiRef,
    sortBy,
    sortDirection,
    pinnedColumns,
}: ContractsTableProps) => {
    const newHeaders = headers.filter((header) => !orderedColumnsIds.includes(header.id));

    const orderedHeaders = orderedColumnsIds
        .map((id) => headers.find((h) => h.id === id))
        .filter(hasValue)
        .concat(newHeaders);

    const columns = orderedHeaders.map((h) => headerToMuiColumn(h, messages.columnMessages));

    const sortModel = useMemo(() => {
        if (!sortBy) return [];
        return [{ field: sortBy, sort: sortDirection }];
    }, [sortBy, sortDirection]);

    const mergedSlotProps = useMemo(() => {
        const slotProps: DataGridProProps["slotProps"] = {
            ...defaultSlotProps,
            pagination: {
                page: currentPage,
                count: total,
                rowsPerPage: pageSize,
                rowsPerPageOptions: [25, 50, 100],
                onPageChange: (_, page) => {
                    onPaginationChange({ page, pageSize });
                },
                onRowsPerPageChange: (e) => {
                    const pageSize = parseInt(e.target.value, 10);
                    onPaginationChange({ page: 0, pageSize });
                },
            },
        }
        return slotProps
    }, [currentPage, pageSize, total, onPaginationChange]);

    return (
        <DataGridPro
            apiRef={apiRef}
            slotProps={mergedSlotProps}
            checkboxSelection={checkboxSelection}
            showColumnVerticalBorder
            showCellVerticalBorder
            pagination
            sx={customDataGridStyling}
            loading={loading}
            density={density}
            localeText={messages.muiMessages}
            rowCount={total}
            disableColumnFilter
            onSortModelChange={(sortModel) => {
                const sort: GridSortItem | undefined = sortModel[0];
                onSortChange(sort?.field, sort?.sort);
            }}
            initialState={{
                pagination: { paginationModel: { page: currentPage, pageSize } },
                columns: { columnVisibilityModel },
                pinnedColumns: {
                    right: RIGHT_PINNED_COLUMNS,
                    // NOTE: Always include checkbox column as pinned - it doesn't matter if it's actually there or not
                    left: [GRID_CHECKBOX_SELECTION_COL_DEF.field].concat(pinnedColumns),
                },
            }}
            sortModel={sortModel}
            paginationMode="server"
            sortingMode="server"
            onColumnWidthChange={(e) => onColumnWidthChanged(e.colDef.field, e.width)}
            onColumnVisibilityModelChange={onColumnVisibilityModelChange}
            onColumnOrderChange={({ targetIndex }) => {
                if (!apiRef) return;
                const orderedColumns = apiRef.current.getAllColumns();
                const newOrderedIds = orderedColumns.map((col) => col.field);
                onColumnsOrderChanged(newOrderedIds);

                // Track reordering columns
                const movedColDef = orderedColumns[targetIndex];
                if (!movedColDef) return;
                const movedColumn = columns.find((col) => col.field === movedColDef.field);
                if (!movedColumn) return;
                track("Contracts List: Reorder column", {
                    newIndex: targetIndex,
                    columnId: movedColumn.field,
                    columnType: movedColumn.fieldType,
                    columnLabel: movedColumn.headerName,
                });
            }}
            columns={columns}
            rows={rows}
            disableRowSelectionOnClick
        />
    );
};
