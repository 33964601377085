import { useCallback, useState } from "react";
import { gql, useApolloClient } from "@apollo/client";

const searchCompanyQuery = gql`
    query searchCompany($input: SearchCompanyInput!) {
        searchCompany(input: $input) {
            companies {
                companyMasterId
                name
                country
                orgNumber
            }
            total
        }
    }
`;

export function useSearchCompanies(): [(phrase: string) => Promise<any>, string[] | undefined] {
    const [errors, setErrors] = useState<string[] | undefined>(undefined);
    const client = useApolloClient();

    const searchCompany = useCallback(
        async (phrase: string) =>
            client
                .query({
                    query: searchCompanyQuery,
                    variables: {
                        input: {
                            name: phrase,
                            pageSize: 10,
                            pageIndex: 0,
                        },
                    },
                })
                .then((result) => {
                    if (result.error) {
                        const queryErrors = result.error?.graphQLErrors
                            .map((e) => e.message)
                            .concat(result.error?.clientErrors.map((e) => e.message));
                        setErrors(queryErrors);
                    }
                    return result.data.searchCompany.companies;
                }),
        [client]
    );
    return [searchCompany, errors];
}
