import { Stack, DialogContent, TextField, DialogContentText } from "@mui/material";
import React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { dayJsLocale } from "src/formatDate";
import { NewAlert } from "./models";
import { Popup } from "../../ui/Popup/popup";
import { parseDateTimeAnyToDateString } from "@/components/muiDatePickerDateOnlyFix";

dayjs.extend(utc);

interface Props {
    isOpen: boolean;
    onCancel: () => void;
    onSubmit: (newAlert: NewAlert) => void;
}

export const messages = {
    title: "Create new alert",
    hint: "Alerts will be sent to the contract responsible person when triggered by e-mail and in-app notifications.",
    requiredError: "Field is required",
    dateError: {
        invalid: "Date is invalid",
        mustBeFuture: "Date must be future date",
    },
    name: "Alert name",
    cancel: "Cancel",
    submit: "Add",
    scheduledOnDate: "Alert date",
};

type FormState = {
    name: string;
    scheduledOn: Date | null;
};

export const AddAlertPopup = ({ isOpen, onCancel, onSubmit }: Props) => {
    const minDate = dayjs().add(1, "day").startOf("day");
    const { lang, dateFormat } = dayJsLocale();
    const schema = yup.object({
        name: yup.string().required(messages.requiredError),
        scheduledOn: yup
            .date()
            .min(minDate, messages.dateError.mustBeFuture)
            .typeError(messages.dateError.invalid)
            .required(messages.requiredError)
            .nullable(),
    });
    const {
        register,
        formState: { errors },
        reset,
        handleSubmit,
        control,
    } = useForm<FormState>({
        resolver: yupResolver(schema),
    });
    const onSubmitHandler: SubmitHandler<FormState> = (state) => {
        onSubmit({
            message: state.name,
            scheduledOn: parseDateTimeAnyToDateString(state.scheduledOn ?? new Date()) ?? "",
        });
        reset();
    };
    const onClose = () => {
        onCancel();
        reset();
    };

    const computeValueDate = (value: Date | null) => (value ? dayjs(value).utc() : null);

    return (
        <Popup
            isOpen={isOpen}
            onCancel={onClose}
            onSubmit={handleSubmit(onSubmitHandler)}
            titleLabel={messages.title}
            submitLabel={messages.submit}
            cancelLabel={messages.cancel}
        >
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <DialogContent>
                    <DialogContentText>{messages.hint}</DialogContentText>
                    <Stack sx={{ mt: 2 }}>
                        <Controller
                            name="scheduledOn"
                            control={control}
                            defaultValue={null}
                            render={({ field: { onChange, value } }) => (
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={lang}>
                                    <DesktopDatePicker
                                        label={`${messages.scheduledOnDate} *`}
                                        value={computeValueDate(value)}
                                        minDate={minDate}
                                        onChange={(date) => {
                                            onChange(date?.format());
                                        }}
                                        format={dateFormat}
                                        slotProps={{
                                            textField: {
                                                label: `${messages.scheduledOnDate} *`,
                                                "aria-label": `${messages.scheduledOnDate} *`,
                                                error: !!errors.scheduledOn,
                                                helperText: errors.scheduledOn?.message,
                                                margin: "dense",
                                                inputProps: {
                                                    "aria-label": `${messages.scheduledOnDate} *`,
                                                    label: `${messages.scheduledOnDate} *`,
                                                },
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                            )}
                        />
                        <TextField
                            label={`${messages.name} *`}
                            helperText={errors.name?.message}
                            error={!!errors.name}
                            {...register("name")}
                        />
                    </Stack>
                </DialogContent>
            </form>
        </Popup>
    );
};
