import { useLazyQuery } from "@apollo/client";
import { captureMessage } from "@/errorHandling/errors";
import { graphql } from "@/generated";

const getTagValuesQuery = graphql(`
    query getTagValuesByColumnId($input: GetTagValuesByColumnIdInput!) {
        getTagValuesByColumnId(input: $input) {
            tagValues {
                value
            }
        }
    }
`);

export function useSearchTagsV2(dataColumnId: string) {
    const [load, { data, loading, error }] = useLazyQuery(getTagValuesQuery, {
        variables: {
            input: {
                dataColumnId,
            },
        },
        onError: (error) => {
            captureMessage("Failed to search tags", { extra: { error } });
        },
    });

    return {
        loading,
        options: data?.getTagValuesByColumnId.tagValues.map((tag) => tag.value) ?? [],
        error,
        load: () => load().then(() => { }),
    }

}
