import { Grid, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import { TextInputRenderer } from "src/components/FieldRendererComponent/TextInputRenderer";
import { TextAreaRenderer } from "src/components/FieldRendererComponent/TextAreaRenderer";
import SupplierDropdown from "src/components/SupplierDropdownLegacy";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { useIntl } from "react-intl";
import { useCompanyCurrency } from "@/contexts/CompanyCurrencyContext";
import { normalizeDateString } from "@/helpers/dates";

const useDebouncedEffect = (effect: () => void, deps: any[], delay: number) => {
    useEffect(() => {
        const handler = setTimeout(() => effect(), delay);
        return () => clearTimeout(handler);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...(deps || []), delay]);
};

interface GeneralInformationSectionProps {
    editMode: boolean;
    onChange: (contractGeneralInformation: ContractGeneralInformation) => void;
    generalInformation: ContractGeneralInformation;
    hasRenewalDate: boolean;
}

export interface ContractGeneralInformation {
    id: string;
    title: string | undefined;
    description: string | undefined;
    startDate: string | undefined;
    endDate: string | undefined;
    renewalDate: string | undefined;
    supplierId: string | undefined;
    totalSpend: number | undefined;
}

export const GeneralInformationSection: React.FC<GeneralInformationSectionProps> = ({
    editMode,
    onChange,
    generalInformation,
    hasRenewalDate,
}) => {
    const currency = useCompanyCurrency();

    const [title, setTitle] = useState(generalInformation.title);
    const [description, setDescription] = useState(generalInformation.description);
    const [startDate, setStartDate] = useState(generalInformation.startDate);
    const [endDate, setEndDate] = useState(generalInformation.endDate);
    const [renewalDate, setRenewalDate] = useState(generalInformation.renewalDate);
    const [supplierId, setSupplierId] = useState(generalInformation.supplierId);
    const { formatMessage } = useIntl();

    useDebouncedEffect(
        () => {
            onChange({
                id: generalInformation.id,
                title,
                description,
                endDate,
                startDate,
                renewalDate,
                supplierId,
                totalSpend: generalInformation.totalSpend,
            });
        },
        [
            title,
            description,
            startDate,
            endDate,
            renewalDate,
            supplierId,
            generalInformation.totalSpend,
            generalInformation.id,
            onChange,
        ],
        600
    );

    useEffect(() => {
        setTitle(generalInformation.title);
        setDescription(generalInformation.description);
        setStartDate(generalInformation.startDate);
        setEndDate(generalInformation.endDate);
        setRenewalDate(generalInformation.renewalDate);
        setSupplierId(generalInformation.supplierId);
    }, [generalInformation]);

    const hasTotalSpend = generalInformation.totalSpend !== undefined;

    return (
        <Grid
            container
            key="general_information_grid_container"
            direction="row"
            alignItems="center"
            pt={2}
            columnSpacing={2}
            rowSpacing={3}
        >
            <Grid item md={12}>
                <TextInputRenderer
                    readOnly={!editMode}
                    id="contract_title"
                    label={formatMessage({ defaultMessage: "Title" })}
                    value={title}
                    onChange={(newValue) => setTitle(newValue)}
                />
            </Grid>
            <Grid item md={12}>
                <TextAreaRenderer
                    readOnly={!editMode}
                    id="contract_description"
                    label={formatMessage({ defaultMessage: "Description" })}
                    value={description}
                    onChange={(newValue) => setDescription(newValue)}
                />
            </Grid>
            <Grid item md={hasTotalSpend ? 6 : 12}>
                <SupplierDropdown
                    id={supplierId}
                    fieldLabel={formatMessage({ defaultMessage: "Supplier" }).toString()}
                    onSetSupplier={(selectedId) => setSupplierId(selectedId)}
                    readonly={!editMode}
                />
            </Grid>
            {hasTotalSpend && (
                <Grid item md={6}>
                    <TextField
                        label={formatMessage({ defaultMessage: "Total spend" })}
                        variant="standard"
                        fullWidth
                        value={currency.formatInteger(generalInformation.totalSpend)}
                        InputProps={{
                            readOnly: true,
                            disableUnderline: true,
                            sx: { paddingLeft: "14px" },
                        }}
                    />
                </Grid>
            )}
            <Grid item md={4}>
                <DesktopDatePicker
                    readOnly={!editMode}
                    label={formatMessage({ defaultMessage: "Start Date" })}
                    value={normalizeDateString(startDate)}
                    timezone="UTC"
                    onChange={(newValue) => setStartDate(newValue?.format())}
                />
            </Grid>
            <Grid item md={4}>
                <DesktopDatePicker
                    readOnly={!editMode}
                    label={formatMessage({ defaultMessage: "End Date" })}
                    value={normalizeDateString(endDate)}
                    timezone="UTC"
                    onChange={(newValue) => setEndDate(newValue?.format())}
                />
            </Grid>
            {hasRenewalDate && (
                <Grid item md={4}>
                    <DesktopDatePicker
                        readOnly={!editMode}
                        label={formatMessage({ defaultMessage: "Renewal Date" })}
                        value={normalizeDateString(renewalDate)}
                        timezone="UTC"
                        onChange={(newValue) => setRenewalDate(newValue?.format())}
                    />
                </Grid>
            )}
        </Grid>
    );
};
