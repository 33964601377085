import Skeleton from "@mui/material/Skeleton";
import { useMemo } from "react";
import SupplierDropDown, { SupplierOptionItem } from "./dumb";
import { useFetchSupplier } from "../../hooks/useFetchSupplier";

interface SupplierDropdownProps {
    id?: string;
    fieldLabel: string;
    readonly: boolean;
    onSetSupplier: (value: string | undefined) => void;
}

const SupplierDropdown = ({ id, fieldLabel, readonly, onSetSupplier }: SupplierDropdownProps) => {
    const { supplier, loading } = useFetchSupplier(id);

    const supplierValue = useMemo(() => (supplier ? [supplier] : []), [supplier]);

    const handleOnSelect = (options: SupplierOptionItem[]) => {
        if (options.length === 1) {
            onSetSupplier(options[0].id);
        }

        if (options.length === 0) {
            onSetSupplier(undefined);
        }
    };

    if (loading) {
        return <Skeleton data-testid="supplier-field-loader" animation="wave" variant="rounded" height="3em" />;
    }
    return (
        <SupplierDropDown fieldLabel={fieldLabel} onSelect={handleOnSelect} value={supplierValue} readonly={readonly} />
    );
};
export default SupplierDropdown;
