import { gql, useQuery } from "@apollo/client";
import { useMemo } from "react";

const getContactsQuery = gql`
    query getContacts($input: GetContactsInput!) {
        getContacts(input: $input) {
            result {
                id
                firstName
                lastName
                email
            }
            total
        }
    }
`;

type Contact = {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
};

export function useGetContacts(ids: string[]) {
    const { data, loading, error } = useQuery<{ getContacts: { result: Contact[] } }>(getContactsQuery, {
        skip: !ids.length,
        variables: {
            input: {
                contacts: ids.map((id) => ({ id, phone: {} })),
                limit: 20,
                offset: 0,
                sort: {
                    by: "firstName",
                    dir: "asc",
                },
            },
        },
    });

    const contacts = useMemo(() => {
        if (data?.getContacts?.result) {
            return data.getContacts.result;
        }
        return [];
    }, [data]);

    return { contacts, loading, error };
}
