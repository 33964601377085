import { defineMessages } from "react-intl";

const messages = defineMessages({
    titleHeaderLabel: {
        id: "tableRelationDropdown.titleHeaderLabel",
        description: "Label for title header",
        defaultMessage: "Name",
    },
    valueHeaderLabel: {
        id: "tableRelationDropdown.valueHeaderLabel",
        description: "Label for value header",
        defaultMessage: "Value",
    },
});

export default messages;
