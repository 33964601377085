import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from "react";
import { getLocale } from "@ignite-analytics/locale";
import { useSessionContext } from "./SessionContext";
import { env } from "@/env";

const cacheKey = (tenant: string) => `DISPLAY_CURRENCY_${tenant}`;

const validateCurrency = (currency: string | null): string | undefined => {
    if (!currency) return undefined;
    if (currency.length !== 3) return undefined;
    return currency;
};

type CurrencyContext = {
    code: string | undefined;
    format: (value?: number | null, blank?: string) => string;
    formatCompact: (value?: number | null, blank?: string) => string;
    formatInteger: (value?: number | null, blank?: string) => string;
};

const Context = createContext<CurrencyContext | null>(null);

export const CompanyCurrencyProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { tenant } = useSessionContext();

    const [fetchedCurrency, setFetchedCurrency] = useState<string | undefined>(undefined);
    const cachedCurrency = validateCurrency(localStorage.getItem(cacheKey(tenant)));

    useEffect(() => {
        if (!tenant) return;
        let tenantHasChanged = false;
        fetch(`${env.REACT_APP_API_URL}/r/${tenant}/departments/department-settings/1/`, {
            credentials: "include",
        }).then(async (response) => {
            const data = await response.json();
            const currency = data.display_currency;

            if (!currency) return;
            if (tenantHasChanged) return;

            localStorage.setItem(cacheKey(tenant), currency);
            setFetchedCurrency(currency);
        });
        return () => {
            tenantHasChanged = true;
        };
    }, [tenant]);

    const code = fetchedCurrency || cachedCurrency;
    const locale = getLocale();

    const defaultOpts: Intl.NumberFormatOptions = useMemo(
        () => ({
            currencyDisplay: "code",
            style: code ? "currency" : undefined,
            currency: code,
        }),
        [code]
    );

    // Keep the formatters in memory to avoid expensive database lookups. See 2nd paragraph for details:
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString
    const formatFormatter = useMemo(() => new Intl.NumberFormat(locale, defaultOpts), [locale, defaultOpts]);
    const compactFormatter = useMemo(
        () => new Intl.NumberFormat(locale, { ...defaultOpts, notation: "compact" }),
        [locale, defaultOpts]
    );
    const integerFormatter = useMemo(
        () => new Intl.NumberFormat(locale, { ...defaultOpts, maximumFractionDigits: 0 }),
        [locale, defaultOpts]
    );

    const value = useMemo(
        () => ({
            code,
            format: (value?: number | null, blank = "-") => {
                if (value == null) return blank;
                return formatFormatter.format(value);
            },
            formatCompact: (value?: number | null, blank = "-") => {
                if (value == null) return blank;
                return compactFormatter.format(value);
            },
            formatInteger: (value?: number | null, blank = "-") => {
                if (value == null) return blank;
                return integerFormatter.format(value);
            },
        }),
        [code, formatFormatter, compactFormatter, integerFormatter]
    );

    return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useCompanyCurrency = (): CurrencyContext => {
    const context = useContext(Context);
    if (!context) {
        throw new Error("useCompanyCurrency must be used within a CompanyCurrencyProvider");
    }
    return context;
};
