import { env } from "./env";
import { Session } from "./types";

const BASE_URL = env.REACT_APP_PUBLIC_URL;

interface WhoAmIResponse {
    error?: {
        id: string;
    };
    identity?: {
        id: string;
        traits: {
            email: string;
        };
        // eslint-disable-next-line camelcase
        metadata_public?: {
            tenant: string;
        };
    };
}

async function totp() {
    const setupURL = `${BASE_URL}/ory/self-service/login/browser?aal=aal2&refresh=true`;
    const setupResponse = await fetch(setupURL, { headers: { accept: "application/json" }, method: "GET" });
    const setupData = await setupResponse.json();
    if (setupData.error?.id === "session_already_available") {
        const res = await fetch(`${env.REACT_APP_ORY_API_URL}/sessions/whoami`, { credentials: "include" });
        const j = await res.json();
        localStorage.setItem("tenant", j.identity.metadata_public.tenant);
        return;
    }

    const csrfToken = setupData.ui.nodes.find((node: any) => node?.attributes?.name === "csrf_token").attributes.value;
    const code = prompt("Please enter your 2FA code");

    const { action } = setupData.ui;
    const flowId = action.split("login?flow=").pop();

    const totpURL = `${BASE_URL}/ory/self-service/login?flow=${flowId}`;

    fetch(totpURL, {
        method: "POST",
        credentials: "include",
        headers: { accept: "application/json", "Content-Type": "application/json" },
        body: JSON.stringify({
            csrf_token: csrfToken,
            method: "totp",
            totp_code: code,
        }),
    });
}

async function getSession(): Promise<Session> {
    const sessionURL = `${BASE_URL}/ory/sessions/whoami`;
    const sessionResponse = await fetch(sessionURL, { headers: { accept: "application/json" }, method: "GET" });
    const sessionData: WhoAmIResponse = await sessionResponse.json();

    let tenant: string | undefined;
    let email: string | undefined;
    let id: string | undefined;
    if (sessionData.error?.id === "session_aal2_required") {
        await totp();
        const sessionResponseAfterTotp = await fetch(sessionURL, {
            headers: { accept: "application/json" },
            method: "GET",
        });
        const sessionDataAfterTotp = await sessionResponseAfterTotp.json();
        id = sessionDataAfterTotp.identity.id;
        tenant = sessionDataAfterTotp.identity?.metadata_public?.tenant;
        email = sessionDataAfterTotp.identity.traits.email;
    } else {
        id = sessionData.identity?.id;
        tenant = sessionData.identity?.metadata_public?.tenant;
        email = sessionData.identity?.traits.email;
    }

    if (!id) {
        throw new Error("Could not get id");
    }
    if (!tenant) {
        throw new Error("Could not get tenant");
    }
    if (!email) {
        throw new Error("Could not get email");
    }

    return {
        id,
        tenant,
        email,
    };
}

export async function setupProxyAuth() {
    const setupURL = "/ory/self-service/login/browser";
    const setupResponse = await fetch(setupURL, { headers: { accept: "application/json" }, method: "GET" });
    const setupData = await setupResponse.json();

    if (setupData.error?.id !== "session_already_available") {
        const username = env.REACT_APP_DEFAULT_USER ?? prompt("Please enter your username");
        const password = env.REACT_APP_DEFAULT_PASSWORD ?? prompt("Please enter your password");

        const csrfNode = setupData.ui.nodes.find((node: any) => node.attributes.name === "csrf_token");
        const csrfToken = csrfNode.attributes.value;
        const { action } = setupData.ui;
        const flowId = action.split("login?flow=").pop();

        const loginURL = `${BASE_URL}/ory/self-service/login?flow=${flowId}`;

        await fetch(loginURL, {
            method: "POST",
            credentials: "include",
            headers: { accept: "application/json", "Content-Type": "application/json" },
            body: JSON.stringify({
                csrf_token: csrfToken,
                method: "password",
                identifier: username,
                password,
                password_identifier: username,
            }),
        });
    }
    const session = await getSession();

    localStorage.setItem("tenant", session.tenant);

    return session;
}
