import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";

interface Props {
    id: string;
    label: string;
    value: boolean;
    onChange: (value: boolean) => void;
    readOnly: boolean;
}

export const BooleanToggleRenderer: React.FC<Props> = ({ id, label, value, onChange, readOnly }) => (
    <FormControlLabel
        sx={{ alignItems: "flex-start", marginTop: 3 }}
        control={
            <Checkbox
                id={`boolean_toggle_renderer_${id}`}
                checked={value ?? false}
                onChange={(newValue) => {
                    if (!readOnly) {
                        onChange(newValue.target.checked);
                    }
                }}
                sx={{
                    marginTop: -1,
                }}
                inputProps={{ readOnly }}
                disableRipple={readOnly}
            />
        }
        label={label}
    />
);
