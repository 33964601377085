import { ElasticField, useElasticFields } from "@ignite-analytics/elastic-fields";
import { ExcludeFilter, Filter, StaticDateFilter } from "@ignite-analytics/filters";
import {
    CONTRACT_RELATION_GT,
    TRANSACTIONS_GT,
    TRANSACTION_DATE_GT,
    TRANSACTION_VALUE_GT,
} from "@ignite-analytics/global-types";
import { ArrowLeft } from "@ignite-analytics/icons";
import { AnalysisQuery } from "@ignite-analytics/pivot-ts";
import { track } from "@ignite-analytics/track";
import { Divider, IconButton, Paper, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import { fm } from "@/contexts/IntlContext";
import { useGetPivotFromAnalysisService } from "@/hooks/useAnalysisQuery";
import { useV2DataTableWithType } from "@/hooks/useTableWithType";
import { InnerChart } from "./InnerChart";
import messages from "./messages";
import { useErrorHandler } from "@/errorHandling/ErrorHandlerContext";

type ContractCoverageDataPoint = Record<string, number> & {
    label: string;
};

export type ContractCoverageData = ContractCoverageDataPoint[];

const dateFilterFromPeriod = (period: TimePeriod, year: string, dateField: string): StaticDateFilter => {
    switch (period) {
        case "year":
            return {
                field: dateField,
                fieldId: dateField,
                type: "date",
                filterType: "datefilter",
                start: "now-3y/y",
                end: "now",
            };
        case "quarter":
            return {
                field: dateField,
                fieldId: dateField,
                type: "date",
                filterType: "datefilter",
                start: `${year}-01-01`,
                end: `${year}-12-31`,
            };
    }
};

const getQuarterKey = (date: string): string => {
    const month = Number(date.slice(5, 7));
    if (month <= 3) {
        return "Q1";
    }
    if (month <= 6) {
        return "Q2";
    }
    if (month <= 9) {
        return "Q3";
    }
    return "Q4";
};

const keyAndPeriodToLabel = (key: string, period: TimePeriod): string => {
    switch (period) {
        case "year":
            return key.slice(0, 4);
        case "quarter":
            return getQuarterKey(key);
    }
};

const constructQueryAndFilters = (
    spendElasticFields: ElasticField[],
    elasticIndex: string,
    period: TimePeriod,
    year: string
): { query: AnalysisQuery; filters: Filter[] } | undefined => {
    const spendAmountField = spendElasticFields.find(({ globalTypeKey }) => globalTypeKey === TRANSACTION_VALUE_GT);
    const spendDateField = spendElasticFields.find(({ globalTypeKey }) => globalTypeKey === TRANSACTION_DATE_GT);
    const contractRelationField = spendElasticFields.find(
        ({ globalTypeKey }) => globalTypeKey === CONTRACT_RELATION_GT
    );

    if (!spendAmountField || !spendDateField || !contractRelationField) {
        return;
    }

    // start should be the beginning of the year three years back
    const dateFilter: StaticDateFilter = dateFilterFromPeriod(period, year, spendDateField.field);

    const hasContractFilter: ExcludeFilter = {
        field: contractRelationField.field,
        fieldId: contractRelationField.field,
        type: "keyword",
        exclude: [],
        filterType: "excludefilter",
        excludeBlanks: true,
    };

    const query: AnalysisQuery = {
        valueAggregationItems: [
            {
                field: spendAmountField.field,
                type: "float",
                aggregation: "sum",
                label: "Total spend",
            },
            {
                field: spendAmountField.field,
                type: "float",
                aggregation: "sum",
                label: "Contracted spend",
                filters: [hasContractFilter],
            },
        ],
        rowSplitItems: [
            {
                field: spendDateField.field,
                type: "date",
                interval: period,
                excludeOthers: true,
            },
        ],
        columnSplitItems: [],
        elasticIndex,
    };

    return { query, filters: [dateFilter] };
};

export type TimePeriod = "year" | "quarter";

export const ContractCoverage: React.FC = () => {
    const [period, setPeriod] = useState<TimePeriod>("year");
    const [year, setYear] = useState<string>("");
    const { table: spendTable, loading: tableLoading } = useV2DataTableWithType(TRANSACTIONS_GT);
    const elasticFields = useElasticFields(spendTable?.elasticIndex);
    const getPivotFunc = useGetPivotFromAnalysisService();
    const [data, setData] = useState<ContractCoverageData | undefined>(undefined);
    const [error, setError] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(true);
    const { captureSentryException } = useErrorHandler();

    const spendWoContractsKey = fm(messages.spendWoContracts).toString();
    const spendThroughContractsKey = fm(messages.spendThroughContracts).toString();
    useEffect(() => {
        if (!spendTable && !tableLoading) {
            setLoading(false);
        }
        if (spendTable && elasticFields) {
            const queryAndFilters = constructQueryAndFilters(elasticFields, spendTable.elasticIndex, period, year);
            if (!queryAndFilters) {
                setLoading(false);
                setError("Could not construct query and filters");
                return;
            }
            const { query, filters } = queryAndFilters;
            getPivotFunc(query, filters)
                .then((pivotResponse) => {
                    const pivotData: ContractCoverageData = pivotResponse.rows.map((row) => {
                        const label = keyAndPeriodToLabel(row.key, period);
                        const values =
                            "values" in row && row.values.length === 1 && row.values[0]?.length === 2
                                ? row.values[0]
                                : [0, 0];

                        return {
                            [spendWoContractsKey]: Number(values[0]) - Number(values[1]),
                            [spendThroughContractsKey]: Number(values[1]),
                            label,
                        } as ContractCoverageDataPoint;
                    });
                    setData(pivotData);
                })
                .catch((e) => {
                    setError(e.message);
                    captureSentryException(e);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [
        spendTable,
        elasticFields,
        getPivotFunc,
        captureSentryException,
        spendWoContractsKey,
        spendThroughContractsKey,
        tableLoading,
        period,
        year,
    ]);
    const hasConnectedContracts = !!elasticFields?.find(({ globalTypeKey }) => globalTypeKey === CONTRACT_RELATION_GT);

    return (
        <Stack component={Paper} px={3} gap={2.5} height="100%">
            <Stack py={2.5}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    // set a fixed height to match the Opportunities widget
                    height={(theme) => theme.spacing(5)}
                >
                    <Typography variant="textLg" component="h2" fontWeight={500}>
                        {`${fm(messages.contractCoverageTitle)} ${year}`}
                    </Typography>
                    {year && (
                        <Stack direction="row" justifyContent="end">
                            <IconButton
                                size="small"
                                color="primary"
                                onClick={() => {
                                    setPeriod("year");
                                    setYear("");
                                }}
                            >
                                <ArrowLeft />
                            </IconButton>
                        </Stack>
                    )}
                </Stack>
                <Divider sx={{ pt: 2.5 }} />
            </Stack>
            <InnerChart
                onTimePeriodChange={(period: TimePeriod) => {
                    setPeriod(period);
                    setLoading(true);
                }}
                onYearSelected={(year: string) => {
                    setYear(year);
                    track("Contract Overview: Contract coverage year selected", { year });
                }}
                period={period}
                data={data}
                loading={loading}
                error={error}
                spendThroughContractsKey={spendThroughContractsKey}
                spendWoContractsKey={spendWoContractsKey}
                hasSpendTable={!!spendTable}
                hasConnectedContracts={hasConnectedContracts}
            />
        </Stack>
    );
};
