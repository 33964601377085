import { defineMessages } from "react-intl";

const messages = defineMessages({
    name: {
        id: "companyPopup.companyName",
        description: "Label for company name in company popup",
        defaultMessage: "Name",
    },
    orgNumber: {
        id: "companyPopup.orgNumber",
        description: "Label for company org number in company popup",
        defaultMessage: "Number",
    },
    countryIso: {
        id: "companyPopup.countryCode",
        description: "Label for company country iso Code in company popup",
        defaultMessage: "Country",
    },
});

export default messages;
