import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";

interface OverflowTooltipProps {
    text: string;
    url?: string;
}

const OverflowTooltip = ({ text, url }: OverflowTooltipProps) => {
    // Create Ref
    const textElementRef = React.useRef<HTMLElement>();
    // Define state and function to update the value
    const [hoverStatus, setHover] = useState(false);

    const compareSize = () => {
        if (textElementRef.current) {
            const compare = textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
            setHover(compare);
        }
    };

    // compare once and add resize listener on "componentDidMount"
    useEffect(() => {
        compareSize();
        window.addEventListener("resize", compareSize);

        return () => {
            window.removeEventListener("resize", compareSize);
        };
    }, []);

    return (
        <Tooltip title={text} disableHoverListener={!hoverStatus}>
            <div>
                <Typography
                    ref={textElementRef as any}
                    variant="body1"
                    style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                >
                    {url ? (
                        <a href={url} target="_blank" rel="noreferrer">
                            {text}
                        </a>
                    ) : (
                        text
                    )}
                </Typography>
            </div>
        </Tooltip>
    );
};

export default OverflowTooltip;
