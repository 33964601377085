import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import { fm } from "src/contexts/IntlContext";
import messages from "./messages";
import { DropZoneArea } from "./style";

interface DropZoneProps {
    children: React.ReactNode;
    disabled: boolean;
}

const DropZone = ({ children, disabled }: DropZoneProps) => {
    const [uploading, setUploading] = React.useState(false);

    const dragEnter = (e: React.MouseEvent) => {
        e.preventDefault();
        !disabled && setUploading(true);
    };

    const dragLeave = (e: React.MouseEvent) => {
        e.preventDefault();
        setUploading(false);
    };

    const drop = () => {
        setUploading(false);
    };

    return (
        <DropZoneArea
            uploading={uploading ? true : undefined}
            onDragEnter={dragEnter}
            onDragLeave={dragLeave}
            onDrop={drop}
            disabled={disabled}
        >
            <Grid sx={{ display: "flex", flexDirection: "column", alignItems: "center", p: 2 }}>
                <Typography>{fm(messages.dropFiles)}</Typography>
                <Grid justifyContent="center">
                    <Typography display="inline" variant="body1" sx={{ pr: 0.5 }}>
                        {fm(messages.or)}
                    </Typography>
                    <Typography display="inline" sx={{ pr: 0.5 }}>
                        {fm(messages.browse)}
                    </Typography>
                    <Typography display="inline" variant="body2">
                        {fm(messages.choseFile)}
                    </Typography>
                </Grid>
            </Grid>
            {children}
        </DropZoneArea>
    );
};

export default DropZone;
