import { X, Check } from "@ignite-analytics/icons";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { TextField } from "@mui/material";
import React, { useState } from "react";

interface Props {
    name: string;
    onSubmit: (newName: string) => void;
    onCancel: VoidFunction;
}

const GroupNameInput: React.FC<Props> = ({ name, onCancel, onSubmit }: Props) => {
    const [value, setValue] = useState<string>(name);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        value && onSubmit(value);
    };

    return (
        <Box
            onSubmit={handleSubmit}
            component="form"
            display="flex"
            alignItems="center"
            role="form"
            sx={{ width: "100%" }}
        >
            <TextField
                sx={{ width: "100%" }}
                value={value}
                onChange={(event) => setValue(event.target.value)}
                data-testid="edit-name-input"
                name="groupName"
                size="small"
            />
            <IconButton type="submit" data-testid="submit-edit-name-btn">
                <Check />
            </IconButton>
            <IconButton data-testid="cancel-edit-name-btn" type="button" onClick={() => onCancel()}>
                <X />
            </IconButton>
        </Box>
    );
};

export default GroupNameInput;
