import { useMemo } from "react";
import { makeContractsApiHttp as http } from "src/http";
import { useFetchOrySessionDirectly } from "@/entities/users";
import { Note } from "../Notes/models";
import { useContractsApiFetch as get } from "../../../hooks/useContractsApiFetch";

export function useNotes(contractId: string) {
    const { data, isLoading: loading, refetch } = get<Note[]>(`contracts/${contractId}/notes`);

    const notes = useMemo(() => {
        return data ?? [];
    }, [data]);

    const orySession = useFetchOrySessionDirectly();

    return {
        notes,
        add: async (text: string) => {
            const res = await http().post(`/contracts/${contractId}/notes`, {
                text,
                creatorLastName: orySession.lastName ?? "",
                creatorFirstName: orySession.firstName ?? "",
            });
            if (res.status === 201) {
                refetch();
            }
        },
        delete: async (id: string) => {
            const res = await http().delete(`/contracts/${contractId}/notes/${id}`);
            if (res.status === 204) {
                refetch();
            }
        },
        update: async (id: string, text: string) => {
            const res = await http().put(`/contracts/${contractId}/notes/${id}`, {
                text,
            });
            if (res.status === 204) {
                refetch();
            }
        },
        loading,
        refetch,
    };
}
