import { useCallback, useEffect, useState } from "react";
import { makeGraphqlRouter } from "../http";

const queryByIds = `
query getGroups($input: GetGroupsInput!) {
    getGroups(input: $input) {
        groups {
            id
            name
            parentId
            groupStructureId
            level
        }
    }
}`;

const queryByParentId = `
query getGroupsByGroupStructure($input: GetGroupsByGroupStructureInput!) {
    getGroupsByGroupStructure(input: $input) {
        groups {
            id
            name
            parentId
            groupStructureId
            level
            hasChildren
        }
    }
}`;

export type Leaf = {
    id: string;
    name: string;
    parentId: string;
    groupStructureId: string;
    level: number;
    hasChildren: boolean;
    children: Leaf[];
};

const orderByName = (leaves: Leaf[]): Leaf[] => leaves.sort((a, b) => a.name.localeCompare(b.name));

export const useFetchGroupChildrenBy = (
    categoryId: string,
): {
    data: Leaf[];
    isLoading: boolean;
    mutate: (
        updateFun: (existingData: Leaf[] | undefined) => Promise<Leaf[] | undefined>
    ) => Promise<Leaf[] | undefined>;
} => {
    const [isLoading, setIsloading] = useState(false);
    const [data, setData] = useState<Leaf[] | undefined>(undefined);

    const getNodes = useCallback(
        async (groupId: string) =>
            makeGraphqlRouter()
                .post("", {
                    query: queryByParentId,
                    variables: {
                        input: { groupStructureId: groupId, parentId: undefined },
                    },
                })
                .then(async (response) => {
                    const categoryNodes = orderByName(response.data.data.getGroupsByGroupStructure.groups);
                    return categoryNodes;
                }),
        []
    );

    useEffect(() => {
        setIsloading(true);
        getNodes(categoryId)
            .then((response) => setData(response))
            .finally(() => setIsloading(false));
    }, [categoryId, setData, getNodes, setIsloading]);

    const mutate = useCallback(
        async (
            leafs: (existingData: Leaf[] | undefined) => Promise<Leaf[] | undefined>
        ): Promise<Leaf[] | undefined> => {
            if (leafs === undefined) {
                setIsloading(true);
                const fetchedLeafs = await getNodes(categoryId);
                setData(fetchedLeafs);
                setIsloading(false);
                return fetchedLeafs;
            }
            const newData = await leafs(data);
            setData(newData);
            return newData;
        },
        [categoryId, data, getNodes, setIsloading]
    );
    return {
        data: data ?? [],
        isLoading,
        mutate,
    };
};

export const fetchCategoriesByIds = (ids: string[]): Promise<Leaf[]> =>
    makeGraphqlRouter()
        .post("", {
            query: queryByIds,
            variables: {
                input: { ids },
            },
        })
        .then((response) => orderByName(response.data.data.getGroups.groups));

export const fetchCategories = (categoryId: string, parentId: string): Promise<Leaf[]> =>
    makeGraphqlRouter()
        .post("", {
            query: queryByParentId,
            variables: {
                input: { groupStructureId: categoryId, parentId },
            },
        })
        .then((response) => orderByName(response.data.data.getGroupsByGroupStructure.groups));
