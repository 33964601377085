import { useEffect, useState } from "react";
import { useErrorHandler } from "src/errorHandling/ErrorHandlerContext";
import { makeSubscriptionsApi as http } from "src/http";

export const useSubscriptionsApiFetch = <T>(url: string) => {
    const { handleError } = useErrorHandler();
    const [data, setData] = useState<T>();
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState<Error>();

    useEffect(() => {
        setLoading(true);
        http()
            .get<T>(url)
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
                handleError(err);
                setError(err);
            })
            .finally(() => setLoading(false));
    }, [url, handleError, setLoading]);

    return {
        data,
        setData,
        error,
        isLoading,
    };
};
