import { useEffect, useState } from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import type { Contact } from "src/entities/users";
import { PenSolid } from "@ignite-analytics/icons";
import { ContactsDropdown } from "./contactsEdit";
import { ContactsList } from "../ContactList/contactList";

type Props = {
    messages?: typeof defaultMessages;
    defaultValue: Contact[];
    options: Contact[];
    onChange: (contacts: Contact[]) => void;
    maxHeight?: string;
    isOptionEqualToValue?: (option: Contact, value: Contact) => boolean;
    onCreate?: (input: string) => void;
    showPhone?: true;
    canEdit: boolean;
};

export const defaultMessages = {
    title: "The people",
    editLabel: "Edit",
};

export const Contacts = ({
    defaultValue,
    messages,
    onChange,
    options,
    maxHeight,
    onCreate,
    showPhone,
    isOptionEqualToValue,
    canEdit,
}: Props) => {
    const text = messages ?? defaultMessages;
    const [readonly, setReadonly] = useState(true);
    const [selectedContacts, setSelectedContacts] = useState(defaultValue);
    const [dropDownSelectedContacts, setDropDownSelectedContacts] = useState(defaultValue);
    const onBlur = () => {
        setSelectedContacts(dropDownSelectedContacts);
        onChange(dropDownSelectedContacts);
        setReadonly(true);
    };

    useEffect(() => {
        setSelectedContacts(defaultValue);
        setDropDownSelectedContacts(defaultValue);
    }, [defaultValue]);
    return (
        <>
            <Stack direction="row" justifyContent="space-between">
                <Typography>{text.title}</Typography>
                {canEdit && (
                    <IconButton size="small" onClick={() => setReadonly(false)}>
                        <PenSolid fontSize="inherit" sx={{ mr: 0.5 }} />
                        <Typography>{text.editLabel}</Typography>
                    </IconButton>
                )}
            </Stack>
            <Stack maxHeight={maxHeight ?? "auto"} overflow="auto">
                <ContactsList contacts={selectedContacts} showPhone={showPhone} />
            </Stack>

            {!readonly && (
                <ContactsDropdown
                    multiple
                    defaultValue={dropDownSelectedContacts}
                    isOptionEqualToValue={isOptionEqualToValue}
                    onChange={setDropDownSelectedContacts}
                    onBlur={onBlur}
                    options={options}
                    readonly={readonly}
                    onCreate={onCreate}
                />
            )}
        </>
    );
};
