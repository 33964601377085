import React from "react";
import { CategoryTree } from "src/components/CategoryTree/categoryTree";
import {
    BooleanCustomField,
    CompanyCustomField,
    ContactCustomField,
    ContactListCustomField,
    CustomField,
    DateAggregationCustomField,
    DateCustomField,
    GroupStructureCustomField,
    GroupStructureListCustomField,
    NumberAggregationCustomField,
    NumberCustomField,
    TableRelationCustomField,
    TagCustomField,
    TagListCustomField,
    TextCustomField,
    TextListCustomField,
    UserCustomField,
    UserListCustomField,
} from "src/models/customFields";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { getLocale } from "@ignite-analytics/locale";
import { CompanyDropdown } from "../CompanyDropdown";
import { TableRelation } from "../TableRelation Legacy";
import TagsDropdown from "../TagsDropdown";
import { BooleanToggleRenderer } from "./BooleanToggleRender";
import { NumberInputRenderer } from "./NumberInputRenderer";
import { TextInputRenderer } from "./TextInputRenderer";
import UserRendererWrapper from "./UserRendererWrapper";
import ContactDropdown from "../ContactDropdown/contactDropdown";
import { normalizeDateString } from "@/helpers/dates";

interface Props {
    readOnly: boolean;
    customField: CustomField;
    onSetFields: (customField: CustomField) => void;
}

const RenderTextControl: React.FC<{
    customField: TextCustomField;
    readonly: boolean;
    onSetFields: (customField: TextCustomField) => void;
}> = ({ customField, readonly, onSetFields }) => (
    <TextInputRenderer
        readOnly={readonly}
        id={customField.id}
        label={customField.name}
        value={customField.data}
        onChange={(newValue) => onSetFields({ ...customField, data: newValue })}
    />
);

const RenderTextListControl: React.FC<{
    customField: TextListCustomField;
    readonly: boolean;
    onSetFields: (customField: TextListCustomField) => void;
}> = ({ customField, readonly, onSetFields }) => {
    const value = customField.data ? customField.data.join(",") : "";
    const handleOnSet = (newValues: string | undefined) => {
        let valueToSave: string[] = [];
        if (newValues) {
            valueToSave = newValues.split(",").map((x) => x.trim());
        }
        onSetFields({ ...customField, data: valueToSave });
    };
    return (
        <TextInputRenderer
            readOnly={readonly}
            id={customField.id}
            label={customField.name}
            value={value}
            onChange={(newValue) => handleOnSet(newValue)}
        />
    );
};

const RenderNumberControl: React.FC<{
    customField: NumberCustomField | NumberAggregationCustomField;
    readonly: boolean;
    onSetFields: (customField: NumberCustomField | NumberAggregationCustomField) => void;
}> = ({ customField, readonly, onSetFields }) => {
    const fixedNumber = customField.data && Number(customField.data?.toFixed(2));
    return (
        <NumberInputRenderer
            readOnly={readonly}
            id={customField.id}
            label={customField.name}
            value={fixedNumber}
            onChange={(newValue) => onSetFields({ ...customField, data: newValue })}
        />
    );
};

const RenderDateControl: React.FC<{
    customField: DateCustomField | DateAggregationCustomField;
    readonly: boolean;
    onSetFields: (customField: DateCustomField | DateAggregationCustomField) => void;
}> = ({ customField, readonly, onSetFields }) => (
    <DesktopDatePicker
        readOnly={readonly}
        slotProps={{ textField: { id: customField.id } }}
        label={customField.name}
        value={normalizeDateString(customField.data)}
        timezone="UTC"
        onChange={(newValue) => onSetFields({ ...customField, data: newValue?.format() })}
    />
);

const RenderBooleanControl: React.FC<{
    customField: BooleanCustomField;
    readonly: boolean;
    onSetFields: (customField: BooleanCustomField) => void;
}> = ({ customField, readonly, onSetFields }) => (
    <BooleanToggleRenderer
        readOnly={readonly}
        id={customField.id}
        label={customField.name}
        value={customField.data ?? false}
        onChange={(newValue) => onSetFields({ ...customField, data: newValue })}
    />
);

const RenderCompanyControl: React.FC<{
    customField: CompanyCustomField;
    readonly: boolean;
    onSetFields: (customField: CompanyCustomField) => void;
}> = ({ customField, readonly, onSetFields }) => (
    <>
        <CompanyDropdown
            fieldLabel={customField.name}
            onSelect={(newValue) => onSetFields({ ...customField, data: newValue?.id })}
            companyId={customField.data}
            readOnly={readonly}
        />
    </>
);

const RenderTagControl: React.FC<{
    customField: TagCustomField;
    readonly: boolean;
    onSetFields: (customField: TagCustomField) => void;
}> = ({ customField, readonly, onSetFields }) => {
    const tags = customField.data ? [customField.data] : [];
    const handleTagsSet = (newValues: string[]) => {
        const singleTagToSave = newValues.length ? newValues[0] : undefined;
        onSetFields({ ...customField, data: singleTagToSave });
    };

    return (
        <TagsDropdown
            fieldLabel={customField.name}
            value={tags}
            dataColumnId={customField.id}
            readonly={readonly}
            multi={false}
            onSelect={handleTagsSet}
        />
    );
};

const RenderTagListControl: React.FC<{
    customField: TagListCustomField;
    readonly: boolean;
    onSetFields: (customField: TagListCustomField) => void;
}> = ({ customField, readonly, onSetFields }) => (
    <TagsDropdown
        fieldLabel={customField.name}
        value={customField.data ? customField.data : []}
        dataColumnId={customField.id}
        readonly={readonly}
        multi
        onSelect={(newValue) => onSetFields({ ...customField, data: newValue })}
    />
);

const RenderGroupStructureControl: React.FC<{
    customField: GroupStructureCustomField;
    readonly: boolean;
    onSetFields: (customField: GroupStructureCustomField) => void;
}> = ({ customField, readonly, onSetFields }) => (
    <CategoryTree
        categoryId={customField.data.tableId}
        readOnly={readonly}
        selectedIds={customField.data.id ? [customField.data.id] : []}
        label={customField.name}
        onSubmit={(selectedNodes) =>
            onSetFields({
                ...customField,
                data: {
                    ...customField.data,
                    id: selectedNodes[0]?.id,
                },
            })
        }
    />
);

const RenderGroupStructureListControl: React.FC<{
    customField: GroupStructureListCustomField;
    readonly: boolean;
    onSetFields: (customField: GroupStructureListCustomField) => void;
}> = ({ customField, readonly, onSetFields }) => (
    <CategoryTree
        categoryId={customField.data.tableId}
        readOnly={readonly}
        selectedIds={customField.data ? customField.data.ids ?? [] : []}
        onSubmit={(selectedNodes) =>
            onSetFields({
                ...customField,
                data: {
                    ...customField.data,
                    ids: selectedNodes.map((x) => x.id),
                },
            })
        }
        multiselect
        label={customField.name}
    />
);

const RenderTableRelationControl: React.FC<{
    customField: TableRelationCustomField;
    readonly: boolean;
    onSetFields: (customField: TableRelationCustomField) => void;
}> = ({ customField, readonly, onSetFields }) => (
    <TableRelation
        fieldName={customField.name}
        readOnly={readonly}
        tableId={customField.data.tableId}
        id={customField.data.id ?? undefined}
        onSelect={(newValue) =>
            onSetFields({
                ...customField,
                data: {
                    ...customField.data,
                    id: newValue,
                },
            })
        }
    />
);

const RenderUserControl: React.FC<{
    customField: UserCustomField;
    readonly: boolean;
    onSetFields: (customField: UserCustomField) => void;
}> = ({ customField, readonly, onSetFields }) => {
    const id = customField.data ? [customField.data] : [];
    const handleOnSet = (newValues: string[]) => {
        const singleValue = newValues.length ? newValues[0] : undefined;
        onSetFields({ ...customField, data: singleValue });
    };
    return (
        <UserRendererWrapper
            readOnly={readonly}
            selectedUsersIds={id}
            fieldLabel={customField.name}
            multi={false}
            onSelect={(newValues) => handleOnSet(newValues)}
        />
    );
};
const RenderUserListControl: React.FC<{
    customField: UserListCustomField;
    readonly: boolean;
    onSetFields: (customField: UserListCustomField) => void;
}> = ({ customField, readonly, onSetFields }) => (
    <UserRendererWrapper
        readOnly={readonly}
        selectedUsersIds={customField.data ? customField.data : []}
        fieldLabel={customField.name}
        multi
        onSelect={(newValues) => onSetFields({ ...customField, data: newValues })}
    />
);

const RenderContactControl: React.FC<{
    customField: ContactCustomField;
    readonly: boolean;
    onSetFields: (customField: ContactCustomField) => void;
}> = ({ customField, readonly, onSetFields }) => {
    const id = customField.data ? [customField.data] : [];
    const handleOnSet = (newValues: string[]) => {
        const singleValue = newValues.length ? newValues[0] : undefined;
        onSetFields({ ...customField, data: singleValue });
    };
    return (
        <ContactDropdown
            fieldLabel={customField.name}
            multi={false}
            onSelect={(selectedContacts) => handleOnSet(selectedContacts)}
            contactsIds={id}
            readonly={readonly}
        />
    );
};
const RenderContactListControl: React.FC<{
    customField: ContactListCustomField;
    readonly: boolean;
    onSetFields: (customField: ContactListCustomField) => void;
}> = ({ customField, readonly, onSetFields }) => (
    <ContactDropdown
        fieldLabel={customField.name}
        multi
        onSelect={(selectedContacts) => onSetFields({ ...customField, data: selectedContacts })}
        contactsIds={customField.data ? customField.data : []}
        readonly={readonly}
    />
);

export const FieldRendererComponent: React.FC<Props> = ({ customField, readOnly, onSetFields }) => {
    switch (customField.dataType) {
        case "TEXT":
            return RenderTextControl({ customField, readonly: readOnly, onSetFields });
        case "TEXT_LIST":
            return RenderTextListControl({ customField, readonly: readOnly, onSetFields });
        case "NUMBER":
            return RenderNumberControl({ customField, readonly: readOnly, onSetFields });
        case "NUMBER_AGGREGATION":
            return RenderNumberControl({ customField, readonly: true, onSetFields });
        case "MONETARY_AMOUNT":
            return (
                <TextField
                    fullWidth
                    disabled
                    value={`${customField.data?.amount.toLocaleString(getLocale(), { maximumFractionDigits: 0 })} ${customField.data?.currency ?? ""}`}
                />
            );
        case "DATE":
            return RenderDateControl({ customField, readonly: readOnly, onSetFields });
        case "DATE_AGGREGATION":
            return RenderDateControl({ customField, readonly: true, onSetFields });
        case "BOOLEAN":
            return RenderBooleanControl({ customField, readonly: readOnly, onSetFields });
        case "TAG":
            return RenderTagControl({ customField, readonly: readOnly, onSetFields });
        case "TAG_LIST":
            return RenderTagListControl({ customField, readonly: readOnly, onSetFields });
        case "GROUP_STRUCTURE":
            return RenderGroupStructureControl({ customField, readonly: readOnly, onSetFields });
        case "GROUP_STRUCTURE_LIST":
            return RenderGroupStructureListControl({ customField, readonly: readOnly, onSetFields });
        case "TABLE_RELATION":
            return RenderTableRelationControl({ customField, readonly: readOnly, onSetFields });
        case "COMPANY":
            return RenderCompanyControl({ customField, readonly: readOnly, onSetFields });
        case "USER":
            return RenderUserControl({ customField, readonly: readOnly, onSetFields });
        case "USER_LIST":
            return RenderUserListControl({ customField, readonly: readOnly, onSetFields });
        case "CONTACT":
            return RenderContactControl({ customField, readonly: readOnly, onSetFields });
        case "CONTACT_LIST":
            return RenderContactListControl({ customField, readonly: readOnly, onSetFields });
    }
};
