export function hasValue<T>(item: T | null | undefined): item is T {
    return item !== undefined && item !== null;
}

// shamelessly stolen from https://github.com/apollographql/apollo-client/issues/2412#issuecomment-755449680
type RecursivelyReplaceNullWithUndefined<T> = T extends null
    ? undefined // Note: Add interfaces here of all GraphQL scalars that will be transformed into an object
    : T extends Date
      ? T
      : {
            [K in keyof T]: T[K] extends (infer U)[]
                ? RecursivelyReplaceNullWithUndefined<U>[]
                : RecursivelyReplaceNullWithUndefined<T[K]>;
        };
export function replaceNullsWithUndefineds<T>(obj: T): RecursivelyReplaceNullWithUndefined<T> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newObj: any = {};
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Object.keys(obj).forEach((k) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const v: any = (obj as any)[k];
        newObj[k as keyof T] =
            v === null
                ? undefined
                : // eslint-disable-next-line no-proto
                  v && typeof v === "object" && v.__proto__.constructor === Object
                  ? replaceNullsWithUndefineds(v)
                  : v;
    });
    return newObj;
}

