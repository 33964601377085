import { DraggableLocation } from "react-beautiful-dnd";
import { Group, Item } from "src/types/Layout";

export const reorderItem = (group: Group, startIndex: number, endIndex: number): Group => {
    const result = Array.from(group.items);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return {
        ...group,
        items: result.map((item, index) => ({ ...item, order: index })),
    };
};

export const moveItemToAnotherList = (
    source: DraggableLocation,
    destination: DraggableLocation,
    itemToMove: Item,
    groups: Group[]
): Group[] =>
    groups.reduce((acc: Group[], curr: Group) => {
        if (curr.id === source.droppableId) {
            return [
                ...acc,
                {
                    ...curr,
                    items: curr.items.filter((item) => item.refId !== itemToMove?.refId),
                },
            ];
        }

        if (curr.id === destination.droppableId) {
            const itemsClone = [...curr.items];
            itemsClone.splice(destination.index, 0, itemToMove);
            return [
                ...acc,
                {
                    ...curr,
                    items: itemsClone,
                },
            ];
        }
        return [...acc, curr];
    }, []);
