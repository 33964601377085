import DialogContent from "@mui/material/DialogContent";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import React from "react";
import { Popup } from "../ui/Popup/popup";

interface TableRelationDataPopupProps {
    title: string;
    fields?: TableRelationDataPopupEntry[];
    titleHeaderLabel: string;
    valueHeaderLabel: string;
    open: boolean;
    onClose: VoidFunction;
}

export interface TableRelationDataPopupEntry {
    name: string;
    value: string;
}

export const TableRelationDataPopup: React.FC<TableRelationDataPopupProps> = ({
    title,
    fields,
    valueHeaderLabel,
    titleHeaderLabel,
    open,
    onClose,
}) => (
    <Popup isOpen={open} onCancel={onClose} titleLabel={title} hasCloseIcon>
        <DialogContent>
            <Table>
                <TableHead sx={{ backgroundColor: "whitesmoke" }}>
                    <TableRow>
                        <TableCell>
                            <Typography fontWeight={700}>{titleHeaderLabel}</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography fontWeight={700}>{valueHeaderLabel}</Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {fields?.map((entry) => (
                        <TableRow key={entry.name}>
                            <TableCell>
                                <Typography>{entry.name}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography>{entry.value}</Typography>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </DialogContent>
    </Popup>
);
