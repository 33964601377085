import { Skeleton } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useSearchContacts } from "src/hooks/useSearchContacts";
import { ContactOptionItem, Dropdown } from "./dumb";
import { useGetContacts } from "../../hooks/useGetContact";

export interface ContactDropDownProps {
    contactsIds?: string[];
    fieldLabel: string;
    readonly?: boolean;
    multi?: boolean;
    onSelect: (selectedValue: string[]) => void;
}

const ContactDropdown = ({ contactsIds, fieldLabel, readonly, multi, onSelect }: ContactDropDownProps) => {
    const { contacts, loading } = useGetContacts(contactsIds ?? []);
    const [searchLoading, setLoading] = useState<boolean>(false);
    const [contactsOptions, setContactsOptions] = useState<ContactOptionItem[]>([]);
    const [searchContacts] = useSearchContacts();
    const contactsValue = useMemo(
        () =>
            (contacts || []).map((c) => ({
                id: c.id,
                label: `${c.firstName} ${c.lastName}`,
                email: c.email,
            })),
        [contacts]
    );
    const handleOnSearch = useCallback(
        async (phrase: string) => {
            setLoading(true);
            try {
                const result = await searchContacts(phrase);
                const mappedContacts = (result || []).map((r: any) => ({
                    id: r.id,
                    label: `${r.firstName} ${r.lastName}`,
                    email: r.email,
                }));
                setContactsOptions(mappedContacts);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        },
        [searchContacts]
    );
    if (loading) {
        return <Skeleton data-testid="contact-field-loader" animation="wave" variant="rounded" height="3em" />;
    }
    return (
        <Dropdown
            fieldLabel={fieldLabel}
            onSelect={onSelect}
            value={contactsValue}
            readonly={readonly}
            multi={multi}
            onSearch={handleOnSearch}
            contactOptions={contactsOptions ?? []}
            loading={searchLoading}
        />
    );
};

export default ContactDropdown;
