import { defineMessages } from "react-intl";
import { fm } from "src/contexts/IntlContext";

const messages = defineMessages({
    email: {
        id: "addContactPopup.email",
        description: "Add contact popup email label",
        defaultMessage: "Email",
    },
    emailInvalid: {
        id: "addContactPopup.emailInvalid",
        description: "Add contact popup email: message when email is invalid",
        defaultMessage: "Invalid Email",
    },
    emailRequired: {
        id: "addContactPopup.emailRequired",
        description: "Add contact popup email: message when email is required",
        defaultMessage: "Email is required",
    },
    firstName: {
        id: "addContactPopup.firstName",
        description: "Add contact popup first name label",
        defaultMessage: "First name",
    },
    lastName: {
        id: "addContactPopup.lastName",
        description: "Add contact popup last name label",
        defaultMessage: "Last Name",
    },
    phone: {
        id: "addContactPopup.phone",
        description: "Add contact popup phone label",
        defaultMessage: "Phone number",
    },
    submit: {
        id: "addContactPopup.submit",
        description: "Add contact popup submit button text",
        defaultMessage: "Add",
    },
    cancel: {
        id: "addContactPopup.cancel",
        description: "Add contact popup cancel button text",
        defaultMessage: "Cancel",
    },
    header: {
        id: "addContactPopup.header",
        description: "Add contact popup submit header text",
        defaultMessage: "Add contact",
    },
});

export type ContactsMessages = {
    email: {
        label: string
        errors: {
            invalid: string
            required: string
        }
    }
    firstName: string
    lastName: string
    phone: string
    submit: string
    cancel: string
    header: string
}

export const makeContactsMessages = (): ContactsMessages => ({
    email: {
        label: fm(messages.email).toString(),
        errors: {
            invalid: fm(messages.emailInvalid).toString(),
            required: fm(messages.emailRequired).toString(),
        }
    },
    firstName: fm(messages.firstName).toString(),
    lastName: fm(messages.lastName).toString(),
    phone: fm(messages.phone).toString(),
    submit: fm(messages.submit).toString(),
    cancel: fm(messages.cancel).toString(),
    header: fm(messages.header).toString(),
})
