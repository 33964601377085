import { Typography, Stack } from "@mui/material";
import { ExclamationCircle } from "@ignite-analytics/icons";
import { Explanation } from "src/errorHandling/errors";
import { ShapeIcon } from "@ignite-analytics/components";

export const ErrorDetails = ({ explanation }: { explanation?: Explanation }) => (
    <Stack alignItems="center">
        <ShapeIcon color="error" size="large">
            <ExclamationCircle fontSize="inherit" />
        </ShapeIcon>
        <Typography>{explanation ?? "Something went wrong, please try again later."}</Typography>
    </Stack>
);
