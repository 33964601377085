import { defineMessages } from "react-intl";

const messages = defineMessages({
    remainingFieldsGroupName: {
        id: "contractViewPage.customFieldsSetting.remainingFieldsGroupName",
        defaultMessage: "Others",
        description: "Remaining fields group name",
    },
    header: {
        id: "contractViewPage.customFieldsSettings.header",
        defaultMessage: "Custom fields order settings",
        description: "Header text: Custom fields order settings",
    },
    save: {
        id: "contractViewPage.customFieldsSettings.save",
        defaultMessage: "Save",
        description: "Button text: Save settings",
    },
    cancel: {
        id: "contractViewPage.customFieldsSettings.cancel",
        defaultMessage: "Cancel",
        description: "Button text: Cancel settings",
    },
    back: {
        id: "contractViewPage.customFieldsSetting.back",
        defaultMessage: "Back",
        description: "Used for back buttons(steps and others)",
    },
    defaultContractDetailsGroupName: {
        id: "contractViewPage.customFieldsSetting.defaultContractDetailsGroupName",
        defaultMessage: "Contract details",
        description: "Used for default contract details group name",
    },
    defaultCustomFieldsGroupName: {
        id: "contractViewPage.customFieldsSetting.defaultCustomFieldsGroupName",
        defaultMessage: "Others",
        description: "Used for default custom fields group name",
    },
    layoutUpdatedMessage: {
        id: "contractViewPage.customFieldsSetting.layoutUpdatedSuccessMessage",
        defaultMessage: "Layout updated",
        description: "Message shown after successful layout update",
    },
});

export default messages;
