import { formatDateAsUTC } from "@ignite-analytics/locale";
import { defineMessages } from "react-intl";
import { fm } from "src/contexts/IntlContext";

const messages = defineMessages({
    notePlaceholder: {
        id: "singleContractPage.note.notePlaceholder",
        defaultMessage: "Write a note",
        description: "Input placeholder: Write a contract note",
    },
    createdAt: {
        id: "singleContractPage.note.createdAt",
        defaultMessage: "Created at: {createdAt}",
        description: "Label text: Info when contract was created",
    },
    saveButton: {
        id: "singleContractPage.note.saveButton",
        defaultMessage: "Save",
        description: "Button label: Save a note",
    },
    noteRequired: {
        id: "singleContractPage.note.noteRequired",
        defaultMessage: "Note is required",
        description: "Error message: Note is required",
    },
});

export type NotesMessages = {
    notePlaceholder: string;
    createdAt: string;
    saveButton: string;
    noteRequired: string;
};

export const createMessages = (date?: string): NotesMessages => {
    const formatedDate = date ? formatDateAsUTC(date.slice(0, 10)) : "";
    return {
        createdAt: fm(messages.createdAt, { createdAt: formatedDate }).toString(),
        notePlaceholder: fm(messages.notePlaceholder).toString(),
        saveButton: fm(messages.saveButton).toString(),
        noteRequired: fm(messages.noteRequired).toString(),
    };
};
