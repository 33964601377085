import { useMutation } from "@apollo/client";
import { ExclamationCircle } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import { Skeleton, Stack, Typography } from "@mui/material";
import { useMemo, useState } from "react";

import { useErrorHandler } from "src/errorHandling/ErrorHandlerContext";
import { makeContractsApiHttp } from "src/http";
import { ShapeIcon } from "@ignite-analytics/components";
import { graphql } from "@/generated";
import { AddContact, NewContact } from "../AddContactPopup/createContactPopup";
import { Contacts } from "../ui/ContactDropdown/contacts";
import { Contact } from "../ui/ContactList/contactList";
import { makeMessages } from "./messages";
import { useContactsBySupplierId } from "@/hooks/supplierContacts";

const mutation = graphql(`
    mutation upsertContacts($input: UpsertContactsInput!) {
        upsertContacts(input: $input) {
            result {
                id
            }
        }
    }
`);

const makeQuery = (supplierId: string) => ({
    input: {
        supplierId,
    },
});

const makeMutation = ({ email, firstName, lastName, phone }: NewContact, companyId: string) => ({
    input: {
        contacts: [
            {
                id: "",
                phone: {
                    number: phone,
                },
                email,
                firstName,
                lastName,
                position: "",
                companyId,
            },
        ],
    },
});

export type Props = {
    contractId: string;
    supplierContact: {
        supplierId: string;
        contactsIds: string[];
    };
    maxHeight?: string;
    canEdit: boolean;
};

export const SupplierContacts = ({ contractId, supplierContact, maxHeight, canEdit }: Props) => {
    const messages = makeMessages();
    const { handleError, captureSentryException } = useErrorHandler();
    const [contactsIds, setContactsIds] = useState(supplierContact.contactsIds ?? []);
    const [createContactTerm, setCreateContactTerm] = useState("");
    const http = useMemo(() => makeContractsApiHttp(), []);

    const { contacts, loading, error, refetch } = useContactsBySupplierId(supplierContact.supplierId);
    const [upsertContacts] = useMutation(mutation, {
        onError: (e) => {
            captureSentryException(e);
        },
    });

    const selectedContacts = useMemo(() => {
        const selected = contacts?.filter((contact) => contactsIds?.includes(contact.id)) ?? [];
        return selected;
    }, [contacts, contactsIds]);

    const onChange = (contactsList: Contact[]) => {
        setContactsIds(contactsList.map((p) => p.id));
        http.patch(`/contracts/${contractId}`, [
            {
                data: {
                    supplierId: supplierContact.supplierId,
                    contactPersonIds: contactsList.map((person) => person.id),
                },
                operation: "replace",
                path: "supplier",
            },
        ]).catch(handleError);
    };
    return (
        <>
            {contacts ? (
                <Contacts
                    messages={messages}
                    maxHeight={maxHeight}
                    defaultValue={selectedContacts}
                    onChange={onChange}
                    options={contacts}
                    showPhone
                    onCreate={setCreateContactTerm}
                    canEdit={canEdit}
                />
            ) : (
                <Typography>{messages.title}</Typography>
            )}
            {error && (
                <Stack alignItems="center">
                    <ShapeIcon color="error" size="large">
                        <ExclamationCircle fontSize="inherit" />
                    </ShapeIcon>
                    <Typography>{error.message ?? "Something went wrong, please try again later."}</Typography>
                </Stack>
            )}
            {loading && (
                <>
                    <Skeleton height="80px" animation="wave" />
                    <Skeleton height="80px" animation="wave" />
                </>
            )}
            <AddContact
                key={createContactTerm}
                isOpen={!!createContactTerm}
                onClose={() => {
                    setCreateContactTerm("");
                }}
                triggeringTerm={createContactTerm}
                onSubmit={async (newContact) => {
                    const response = await upsertContacts({
                        variables: makeMutation(newContact, supplierContact.supplierId),
                    });
                    if (response.errors && response.errors.length > 0) {
                        handleError(response.errors[0]);
                        return { error: true };
                    }
                    if (!response.data?.upsertContacts?.result) {
                        handleError("Failed to create contact");
                        return { error: true };
                    }
                    const createdContactId = response.data.upsertContacts.result[0].id;
                    const createdContact: Contact = {
                        id: createdContactId,
                        firstName: newContact.firstName,
                        lastName: newContact.lastName,
                        email: newContact.email,
                        phone: newContact.phone,
                    };
                    onChange([...selectedContacts, createdContact]);
                    setCreateContactTerm("");
                    track("Contract Details: Contact person added", { contractId, createdContactId });
                    refetch(makeQuery(supplierContact.supplierId));
                    return { error: false };
                }}
            />
        </>
    );
};
