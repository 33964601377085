import { AutocompleteRenderGetTagProps, Chip, Stack, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import DropDown from "src/components/ui/DropDown";
import { DropDownOptionItem } from "src/components/ui/DropDown/models/DropDownOptionItem";
import { fm } from "src/contexts/IntlContext";
import { TableRelationDataPopup, TableRelationDataPopupEntry } from "src/components/TableRelationDataPopup";
import messages from "./messages";

export interface CompanyOptionItem extends DropDownOptionItem {
    orgNumber: string;
    country: string;
}

const CompanyOption: React.FC<CompanyOptionItem> = ({ label, orgNumber, country }) => (
    <Stack>
        <Typography variant="body1">{label}</Typography>
        <Stack>
            <Typography>{` ${orgNumber} ${country}`}</Typography>
        </Stack>
    </Stack>
);

interface DropdownProps {
    fieldLabel: string;
    debounceMs?: number;
    readonly?: boolean;
    value?: CompanyOptionItem[];
    companyOptions: CompanyOptionItem[];
    loading: boolean;
    onSelect: (selectedValue: CompanyOptionItem[]) => void;
    onSearch: (phase: string) => void;
}

export const Dropdown = ({
    fieldLabel,
    debounceMs,
    readonly,
    value,
    companyOptions,
    loading,
    onSelect,
    onSearch,
}: DropdownProps) => {
    const [openPopup, setOpen] = useState<boolean>(false);

    const defaultCompanyFields: TableRelationDataPopupEntry[] = [
        {
            name: fm(messages.name).toString(),
            value: "",
        },
        {
            name: fm(messages.orgNumber).toString(),
            value: "",
        },
        {
            name: fm(messages.countryIso).toString(),
            value: "",
        },
    ];
    const mapCompanyData = (companyItem: CompanyOptionItem[] | undefined) => {
        if (companyItem) {
            return [
                {
                    name: fm(messages.name).toString(),
                    value: companyItem[0].label,
                },
                {
                    name: fm(messages.orgNumber).toString(),
                    value: companyItem[0].orgNumber,
                },
                {
                    name: fm(messages.countryIso).toString(),
                    value: companyItem[0].country,
                },
            ];
        }
        return defaultCompanyFields;
    };
    const [companyData, setCompanyData] = useState<TableRelationDataPopupEntry[]>();

    const handleSetOpen = () => {
        setCompanyData(mapCompanyData(value));
        setOpen(true);
    };

    return (
        <>
            <DropDown<CompanyOptionItem>
                loading={loading}
                label={fieldLabel}
                options={companyOptions}
                onSearch={onSearch}
                onSelect={onSelect}
                value={value}
                optionTemplateComponent={CompanyOption as React.FC<DropDownOptionItem>}
                debounceMs={debounceMs ?? 800}
                readOnly={readonly}
                renderTags={
                    readonly
                        ? (values: CompanyOptionItem[], getTagProps: AutocompleteRenderGetTagProps) =>
                              values.map((option, index) => (
                                  <Tooltip title={option.label} key={option.id}>
                                      <Chip
                                          {...getTagProps({ index })}
                                          label={option.label}
                                          data-testid="company-chip"
                                          onClick={handleSetOpen}
                                      />
                                  </Tooltip>
                              ))
                        : undefined
                }
            />
            <TableRelationDataPopup
                title={fieldLabel}
                titleHeaderLabel=""
                valueHeaderLabel=""
                open={openPopup}
                onClose={() => setOpen(false)}
                fields={companyData}
            />
        </>
    );
};
