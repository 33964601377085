import { PenSolid, TrashSolid, FileBookmarkSolid, DotsHorizontal } from "@ignite-analytics/icons";
import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Skeleton,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "@tanstack/react-router";
import { LayoutListItem } from "src/models/layout";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { FormattedMessage, useIntl } from "react-intl";
import { layoutDetailsRoute } from "@/Router";

type Props = {
    loading?: boolean;
    open: boolean;
    defaultId?: string;
    layouts: LayoutListItem[];
    onClose: () => void;
    onSetDefault: (id: string) => Promise<void>;
    onDelete: (id: string) => Promise<void>;
    onCreate: (name: string) => Promise<void>;
    onRename: (id: string, name: string) => Promise<void>;
};

type LayoutItemProps = {
    layoutListItem: LayoutListItem;
    label: string;
    isDefault: boolean;
    updateLayout: (layout: LayoutListItem) => Promise<void>;
    deleteLayout: (layoutId: string) => Promise<void>;
    setAsDefault: (layoutId: string) => Promise<void>;
};

const LayoutItem = ({
    layoutListItem,
    label,
    isDefault,
    updateLayout,
    deleteLayout,
    setAsDefault,
}: LayoutItemProps) => {
    const [isEditing, setEditing] = useState(false);
    const [name, setName] = useState<string>(layoutListItem.name);
    const navigate = useNavigate();
    const navigateToLayoutDetails = () => {
        navigate({ to: layoutDetailsRoute.fullPath, params: { id: layoutListItem.id } });
    };
    const abort = () => {
        setEditing(false);
    };
    const accept = async (inputValue: string) => {
        const newName: LayoutListItem = {
            id: layoutListItem.id,
            name: inputValue,
        };
        await updateLayout(newName);
        setEditing(false);
    };
    const deleteExistingLayout = async (id: string) => {
        await deleteLayout(id);
    };
    const { formatMessage } = useIntl();

    const setLayoutAsDefault = async (id: string) => {
        await setAsDefault(id);
    };

    if (isEditing) {
        return (
            <ListItem key={layoutListItem.id}>
                <Stack
                    direction="row"
                    gap={3}
                    component="form"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (name) {
                            accept(name);
                        }
                    }}
                >
                    <TextField
                        autoFocus
                        size="small"
                        placeholder={label}
                        defaultValue={layoutListItem.name}
                        onChange={({ target }) => setName(target.value)}
                        fullWidth
                    />
                    <Stack direction="row" gap={2} alignItems="center">
                        <Button onClick={abort} size="small" color="secondary">
                            <FormattedMessage defaultMessage="Cancel" />
                        </Button>
                        <Button type="submit" size="small">
                            <FormattedMessage defaultMessage="Save" />
                        </Button>
                    </Stack>
                </Stack>
            </ListItem>
        );
    }

    return (
        <ListItem key={layoutListItem.id}>
            <ListItemButton>
                <ListItemText onClick={navigateToLayoutDetails}>
                    {layoutListItem.name}
                    {isDefault && (
                        <Chip label={formatMessage({ defaultMessage: "default" })} size="small" sx={{ ml: 1 }} />
                    )}
                </ListItemText>
                <PopupState variant="popover">
                    {(popupState) => (
                        <>
                            <IconButton
                                sx={{ padding: 0, position: "absolute", right: 0 }}
                                size="small"
                                aria-label="menu"
                                aria-haspopup="true"
                                {...bindTrigger(popupState)}
                            >
                                <DotsHorizontal />
                            </IconButton>
                            <Menu {...bindMenu(popupState)}>
                                <MenuItem
                                    onClick={() => {
                                        setLayoutAsDefault(layoutListItem.id);
                                        popupState.close();
                                    }}
                                >
                                    <ListItemIcon>
                                        <FileBookmarkSolid />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <FormattedMessage defaultMessage="Set as default" />
                                    </ListItemText>
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        setEditing(true);
                                        popupState.close();
                                    }}
                                >
                                    <ListItemIcon>
                                        <PenSolid />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <FormattedMessage defaultMessage="Rename" />
                                    </ListItemText>
                                </MenuItem>
                                <MenuItem
                                    disabled={isDefault}
                                    onClick={() => {
                                        deleteExistingLayout(layoutListItem.id);
                                        popupState.close();
                                    }}
                                >
                                    <ListItemIcon>
                                        <TrashSolid />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <FormattedMessage defaultMessage="Delete" />
                                    </ListItemText>
                                </MenuItem>
                            </Menu>
                        </>
                    )}
                </PopupState>
            </ListItemButton>
        </ListItem>
    );
};

export const LayoutsPopup = ({
    layouts,
    defaultId,
    open,
    onCreate,
    onClose,
    onRename,
    onDelete,
    onSetDefault,
    loading,
}: Props) => {
    const [isAdding, setNewLayoutItem] = useState(false);
    const [name, setName] = useState<string>();

    const abort = () => {
        setNewLayoutItem(false);
    };
    const { formatMessage } = useIntl();
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogContent>
                <Stack gap={3} minWidth="485px">
                    <Typography variant="textXl" fontWeight={500}>
                        <FormattedMessage defaultMessage="Layouts" />
                    </Typography>
                    <DialogContentText>
                        <FormattedMessage defaultMessage="You can edit contract layouts. Be aware editing and assigning layouts as default will influence your entire organisation" />
                    </DialogContentText>
                    <List>
                        {loading && (
                            <Stack gap={3}>
                                <Skeleton variant="rounded" height={48} />
                                <Skeleton variant="rounded" height={48} />
                            </Stack>
                        )}

                        {!loading &&
                            layouts.map((layout) => (
                                <LayoutItem
                                    layoutListItem={layout}
                                    key={layout.id}
                                    label={formatMessage({ defaultMessage: "Layout name" })}
                                    updateLayout={(layout) => onRename(layout.id, layout.name)}
                                    deleteLayout={onDelete}
                                    isDefault={defaultId === layout.id}
                                    setAsDefault={onSetDefault}
                                />
                            ))}
                    </List>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack width="100%" justifyItems="end">
                    {isAdding && (
                        <Stack
                            direction="row"
                            gap={3}
                            component="form"
                            onSubmit={(e) => {
                                e.preventDefault();
                                if (name) {
                                    onCreate(name).finally(() => {
                                        setName(undefined);
                                        setNewLayoutItem(false);
                                    });
                                }
                            }}
                        >
                            <TextField
                                size="small"
                                required
                                onChange={({ target }) => setName(target.value)}
                                autoFocus
                                placeholder={formatMessage({ defaultMessage: "Layout name" })}
                                fullWidth
                            />
                            <Stack direction="row" gap={2}>
                                <Button size="small" color="secondary" onClick={abort}>
                                    <FormattedMessage defaultMessage="Cancel" />
                                </Button>
                                <Button size="small" type="submit">
                                    <FormattedMessage defaultMessage="Create" />
                                </Button>
                            </Stack>
                        </Stack>
                    )}
                    {!isAdding && (
                        <Stack direction="row" gap={3}>
                            <Button sx={{ ml: "auto" }} size="small" onClick={() => setNewLayoutItem(true)}>
                                <Typography>
                                    {formatMessage({ defaultMessage: "Create", id: "layouts.create-new" })}
                                </Typography>
                            </Button>
                        </Stack>
                    )}
                </Stack>
            </DialogActions>
        </Dialog>
    );
};
