import React from "react";
import { Button, Stack, Avatar, Typography, AvatarGroup as MuiAvatarGroup, Tooltip } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { User } from "@/entities/users";
import { getDisplayName, getInitials } from "./utils";

type Props = {
    max: number;
    users: User[];
    displayTooltip?: boolean;
};

export const AvatarGroup = ({ users, max, displayTooltip }: Props) => {
    const surplus = Math.max(0, users.length - max);

    return (
        <Stack direction="row" alignItems="center" spacing={1}>
            <MuiAvatarGroup>
                {users.slice(0, max).map((user) => {
                    let cmp = (
                        <Avatar
                            sx={{
                                width: 32,
                                height: 32,
                                fontSize: 14,
                                color: "text.primary",
                            }}
                            key={user.id}
                            src={user.profilePicture}
                        >
                            {getInitials(user)}
                        </Avatar>
                    );
                    if (displayTooltip) {
                        const tooltipContent = (
                            <Stack direction="column">
                                <Typography variant="subtitle2">{getDisplayName(user)}</Typography>
                                <Typography variant="caption">{user.email}</Typography>
                            </Stack>
                        );
                        cmp = (
                            <Tooltip title={tooltipContent} key={user.id}>
                                {cmp}
                            </Tooltip>
                        );
                    }
                    return cmp;
                })}
                {surplus > 0 && <Avatar>+{surplus}</Avatar>}
            </MuiAvatarGroup>
            {surplus > 0 && (
                <Button variant="text" onClick={() => {}}>
                    <Typography variant="caption" sx={{ cursor: "default" }}>
                        <FormattedMessage defaultMessage="+{surplus} more" values={{ surplus }} />
                    </Typography>
                </Button>
            )}
        </Stack>
    );
};
