import { captureException } from "@sentry/react";
import { graphql } from "@/generated";
import { apolloClient } from "@/contexts";

const getContractStatisticsDocument = graphql(`
    query GetContractStatistics($input: GetContractStatisticsInput!) {
        getContractStatistics(input: $input) {
            active {
                totalSpend
                count
            }
            expiring {
                totalSpend
                count
            }
            renewing {
                totalSpend
                count
            }
        }
    }
`);

export async function getContractStatistics(responsibleId: null | string) {
    return apolloClient
        .query({
            query: getContractStatisticsDocument,
            variables: { input: { responsibleId } },
        })
        .then((res) => res.data.getContractStatistics)
        .catch((err) => {
            captureException(err);
            throw err;
        });
}
