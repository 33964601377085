import { useCallback, useEffect, useMemo, useState } from "react";

import { useUsers } from "src/entities/users";
import type { Contact } from "src/entities/users";
import { makeContractsApiHttp } from "src/http";
import { Access } from "src/components/AccessOverview";
import { ContractPermissions } from "src/components/ManageAccessPopup/manageAccessPopup";

export function useContractAccessors(id: string, restricted: boolean, responsible: string[]) {
    const [accessors, setAccessors] = useState<Access[]>([]);
    const http = useMemo(() => makeContractsApiHttp(), []);
    const [loading, setLoading] = useState(true);

    const { users, loading: usersLoading } = useUsers();

    const refetch = useCallback(async () => {
        setLoading(true);
        function accessByRole(role: string) {
            const matches = users.filter((u) => u.roles.includes(role));
            return matches.map((u) => ({
                contact: u,
                justification: { reason: "role", role },
            })) as Access[];
        }
        function appendOnce(arr: Access[], toExclude: Access[]) {
            const ids = toExclude.map((a) => a.contact.id);
            return [...toExclude, ...arr.filter((a) => !ids.includes(a.contact.id))];
        }
        let result: Access[] = [];
        if (restricted) {
            const { data } = await http.get<ContractPermissions[]>(`contracts/object-permissions?contractId=${id}`);
            const individuals = data.flatMap(({ entitleds }) =>
                entitleds.map(({ subjectId }) => ({
                    contact: users.find((u) => u.id === subjectId) as Contact,
                    justification: { reason: "individual-grant" },
                }))
            ) as Access[];
            const contractResponsible = responsible.map((userId) => ({
                contact: users.find((u) => u.id === userId) as Contact,
                justification: { reason: "contract-responsible" },
            })) as Access[];
            result = contractResponsible;
            result = appendOnce(individuals, result);
            result = appendOnce(accessByRole("global.admin"), result);
        } else {
            result = appendOnce(accessByRole("global.admin"), result);
            result = appendOnce(accessByRole("global.editor"), result);
            result = appendOnce(accessByRole("global.viewer"), result);
            result = appendOnce(accessByRole("global.contract-editor"), result);
            result = appendOnce(accessByRole("global.contract-viewer"), result);
        }
        setAccessors(result);
        setLoading(false);
    }, [http, id, restricted, users, responsible]);

    useEffect(() => {
        if (usersLoading) return;
        refetch();
    }, [refetch, usersLoading, restricted]);

    return {
        accessors,
        loading: loading || usersLoading,
        refetch,
    };
}
