import { InputProps, TextFieldProps } from "@mui/material";

export const ComputeInputProps = (readOnly: boolean): Partial<InputProps> =>
    readOnly
        ? {
              readOnly,
              disableUnderline: readOnly,
              endAdornment: null,
          }
        : {};
export const ComputeTextFieldProps = (readOnly: boolean, props: TextFieldProps): TextFieldProps =>
    readOnly
        ? {
              ...props,
              InputProps: {
                  ...props.InputProps,
                  ...ComputeInputProps(readOnly),
              },
          }
        : {
              ...props,
          };
