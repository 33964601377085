import React, { useEffect, useState } from "react";
import { Button, Divider, Paper, Stack, Typography } from "@mui/material";

type SidebarCardProps = {
    label: string;
    children?: React.ReactNode;
    blank?: boolean;
    edit?: boolean;
    onClickEdit?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    editLabel?: React.ReactNode;
    editDisabled?: boolean;
};

export const SidebarCard = ({
    label,
    children,
    blank,
    edit,
    onClickEdit,
    editLabel,
    editDisabled,
}: SidebarCardProps) => {
    const childNodes = React.Children.toArray(children);
    const displayNode = childNodes[0];
    const editNode = childNodes.length >= 2 ? childNodes[1] : displayNode;

    const [editClicked, setEditClicked] = useState(false);
    useEffect(() => {
        if (!edit) {
            setEditClicked(false); // reset
        }
    }, [edit]);

    return (
        <Stack p={2.5} gap={2.5} component={Paper} elevation={1} alignItems="stretch">
            <Stack width="100%" direction="row" alignItems="center">
                <Typography variant="textLg" fontWeight={500}>
                    {label}
                </Typography>
                {edit && (
                    <Button
                        disabled={editDisabled}
                        color="secondary"
                        sx={{ ml: "auto" }}
                        size="small"
                        onClick={(e) => {
                            setEditClicked(true);
                            onClickEdit?.(e);
                        }}
                    >
                        {editLabel ?? "Edit"}
                    </Button>
                )}
            </Stack>
            <Divider />
            {editClicked ? editNode : blank ? <Typography>-</Typography> : displayNode}
        </Stack>
    );
};
