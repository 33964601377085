import { gql, useMutation, useQuery } from "@apollo/client";
import { ResultOf } from "@graphql-typed-document-node/core";
import { ContractExpiringSoonSuggestion, SuggestionList, SuggestionStatus } from "@ignite-analytics/opportunities";
import { track } from "@ignite-analytics/track";
import { Button, Divider, Paper, Stack, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { router } from "@/Router";

import { useCompanyCurrency } from "@/contexts/CompanyCurrencyContext";
import { GetSuggestionsDocument } from "@/generated/graphql";
import { hasValue } from "@/helpers/hasValue";
import { captureMessage } from "@/errorHandling/errors";
import { isBetaUser } from "@/helpers/isBetaUser";

/**
 * Vendored from apps/collaboration/src/pages/Overview/SuggestionListCard
 */

const updateStatusMutation = gql`
    mutation updateStatus($input: UpdateSuggestionStatusInput!) {
        updateSuggestionStatus(input: $input) {
            suggestion {
                id
                status
            }
        }
    }
`;

gql`
    query GetSuggestions {
        suggestions(input: { limit: 25, offset: 0, types: [CONTRACT_EXPIRING_SOON], statuses: [SAVED, ACTIVE] }) {
            total
            suggestions {
                id
                status
                type
                __typename
                ... on ContractExpiringSoonSuggestion {
                    contract {
                        id
                        title
                        totalSpend
                        endDate
                        responsibles {
                            firstName
                            lastName
                            email
                        }
                        supplier {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`;
type ApiSuggestion = ResultOf<typeof GetSuggestionsDocument>["suggestions"]["suggestions"][number];

const mapApiSuggestion = (
    apiSuggestion: ApiSuggestion,
    currency: string | undefined
): ContractExpiringSoonSuggestion | undefined => {
    switch (apiSuggestion?.__typename) {
        case "ContractExpiringSoonSuggestion":
            return {
                id: apiSuggestion.id,
                status: apiSuggestion.status,
                type: "CONTRACT_EXPIRING_SOON",
                contract: {
                    ...apiSuggestion.contract,
                    responsibles: apiSuggestion.contract.responsibles.filter((r) => r !== null),
                },
                currency,
            };
    }
};

const mapApiSuggestionWithCurrency = (currency: string | undefined) => (apiSuggestion: ApiSuggestion) =>
    mapApiSuggestion(apiSuggestion, currency);

export const OpportunityWidget: React.FC = () => {
    const { code } = useCompanyCurrency();

    const { data, error, loading, refetch } = useQuery(GetSuggestionsDocument, {
        onError: (e) => captureMessage("failed to get suggestions", { extra: { error: e } }),
        errorPolicy: "all",
    });

    const [updateSuggestionStatus] = useMutation(updateStatusMutation, {
        onError: (e) => captureMessage("failed to update suggestion status", { extra: { error: e } }),
        onCompleted: () => refetch(),
    });
    const handleStatusUpdate = async (id: string, status: SuggestionStatus) => {
        track("Contract Overview: Opportunity status updated", { status, id });
        await updateSuggestionStatus({
            variables: {
                input: {
                    id,
                    status,
                },
            },
        });
    };

    const suggestions = useMemo(() => {
        if (!data) {
            return [];
        }
        return data.suggestions.suggestions.filter(hasValue).map(mapApiSuggestionWithCurrency(code));
    }, [data, code]);

    return (
        <Stack component={Paper} px={3} gap={2.5} height="100%">
            <Stack py={2.5}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="textLg" component="h2" fontWeight={500}>
                        <FormattedMessage defaultMessage="Opportunities" />
                    </Typography>
                    <Button
                        size="small"
                        color="secondary"
                        onClick={() => {
                            track("Contract Overview: View all opportunities");
                            // Not the best user experience, but need to look at injecting
                            // history from web to do this better?
                            window.location.pathname = "/opportunities";
                        }}
                    >
                        <FormattedMessage defaultMessage="View all" description="view all button" />
                    </Button>
                </Stack>
                <Divider sx={{ pt: 2.5 }} />
            </Stack>

            <SuggestionList
                error={error}
                loading={loading}
                onStatusUpdate={handleStatusUpdate}
                suggestions={suggestions}
                navigate={(to) => {
                    if (to.includes("/detail/")) {
                        const parts = to.split("/");
                        const id = parts[parts.length - 1];
                        router.navigate({ to: "/detail/$id", params: { id } });
                        return;
                    }
                    captureMessage("unhandled navigation", { tags: { to } });
                    // tanstack does not navigate on arbitrary paths, so unfortunately we'll need do "proper" navigation
                    // for each opportunity. We fallback to just going to overview and reporting to sentry if it's not
                    // handled.
                    router.navigate({ to: isBetaUser() ? "/overview" : "/list" });
                }}
                currency={code}
            />
        </Stack>
    );
};
