import { captureMessage } from "@/errorHandling/errors";
import { graphql } from "@/generated";
import { apolloClient } from "@/contexts";

const getGroupQuery = graphql(`
    query getGroupsByGroupStructure($input: GetGroupsByGroupStructureInput!) {
        getGroupsByGroupStructure(input: $input) {
            groups {
                id
                name
                parentId
                groupStructureId
                level
                hasChildren
            }
        }
    }
`);

// recursively load groups rooted at id
export async function loadGroupsRecursively(id: string) {
    async function _loadGroupsRecursively(id: string, parentId: string | null) {
        const { data, error } = await apolloClient.query({
            query: getGroupQuery,
            variables: { input: { groupStructureId: id, parentId: parentId ?? undefined } },
            fetchPolicy: "no-cache",
        });
        if (error) {
            captureMessage("Failed to search tags", { extra: { error } });
        }
        const result: { id: string; parent: string | null; name: string }[] = [];
        if (error) {
            captureMessage("Failed to search tags", { extra: { error } });
        }
        if (data?.getGroupsByGroupStructure.groups) {
            const groups = data.getGroupsByGroupStructure.groups;
            for (let i = 0; i < groups.length; i++) {
                const group = groups[i];
                result.push({ id: group.id, parent: parentId ?? null, name: group.name });
                if (group.hasChildren) {
                    result.push(...(await _loadGroupsRecursively(id, group.id)));
                }
            }
        }
        return result;
    }
    return _loadGroupsRecursively(id, null);
}

// get all ancestor groups of a leaf group, starting from the root
export function groupHierarchy( data: {id: string, parent: string | null, name: string}[], leafId: string) {
    const res = [];
    let current: string | null = leafId;
    while (current) {
        // eslint-disable-next-line no-loop-func
        const group = data.find((g) => g.id === current);
        if (!group) {
            break;
        }
        res.push(group);
        current = group.parent;
    }
    return res.reverse();
}
