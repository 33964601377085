import { User } from "@/entities/users";

export function getDisplayName(user: User): string {
    const email = user.email;
    let name = "";
    if (user.firstName) {
        name = `${name} ${user.firstName}`;
    }
    if (user.lastName) {
        name = `${name} ${user.lastName}`;
    }
    if (name.length === 0) {
        name = email;
    }
    return name;
}
export function getInitials(user: User): string {
    const email = user.email;
    let initials = "";
    if (user.firstName && user.lastName) {
        initials = user.firstName[0] + user.lastName[0];
    } else if (user.firstName) {
        initials = user.firstName[0];
    } else if (user.lastName) {
        initials = user.lastName[0];
    } else {
        initials = email[0];
    }
    return initials;
}
