import { Autocomplete, TextField } from "@mui/material";
import { ComputeTextFieldProps } from "src/components/readOnlyStypeComputer";

type Props = {
    names: string[];
    readOnly: boolean;
    label: string;
    onClick: () => void;
};

export const CategoriesChips = ({ names, readOnly, label, onClick }: Props) => (
    <Autocomplete
        multiple
        value={names}
        readOnly
        renderInput={(params) => (
            <TextField
                {...ComputeTextFieldProps(readOnly ?? false, params)}
                variant={readOnly ? "standard" : "outlined"}
                label={label}
                onClick={() => {
                    !readOnly && onClick();
                }}
            />
        )}
        forcePopupIcon={false}
        options={[]}
        ChipProps={{ size: "small" }}
    />
);
