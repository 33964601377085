import Grid from "@mui/material/Grid";
import React from "react";
import { fm } from "src/contexts/IntlContext";
import { Typography } from "@mui/material";
import DropZone from "./DropZone";
import { FileUploadProps } from "./file-upload-props";
import FilesList from "./files_list";
import { messages } from "./messages";
import { uploadFile } from "./helpers";
import { UploadInput, UploadWrapper } from "./style";

const FileUpload: React.FC<FileUploadProps> = ({
    acceptedTypes,
    disabled = false,
    "data-testid": dataTestId,
    files,
    onFilesSelect,
    onFileRemove,
    onSwitch,
    freeze = false,
    canEdit,
}) => (
        <Grid data-testid={dataTestId} flexDirection="column">
            {files && files.length > 0 && (
                <Grid data-testid={`${dataTestId}-files`}>
                    <FilesList
                        files={files}
                        disabled={disabled}
                        onSwitch={onSwitch}
                        onFileRemove={onFileRemove}
                        hasEditPermission={canEdit}
                        freeze={freeze}
                    />
                </Grid>
            )}
            {canEdit && (
                <UploadWrapper sx={{ display: "flex" }}>
                    <DropZone disabled={disabled}>
                        <UploadInput
                            type="file"
                            multiple
                            accept={acceptedTypes?.join(",")}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                uploadFile(e.target.files, acceptedTypes, onFilesSelect)
                            }
                            disabled={disabled}
                            data-testid={`${dataTestId}-input`}
                        />
                    </DropZone>
                </UploadWrapper>
            )}
            {(!files || files.length === 0) && !canEdit && (
                <Typography>{fm(messages.noFilesAttached)}</Typography>
            )}
        </Grid>
    );

export default FileUpload;
