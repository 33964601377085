import { useLazyQuery } from "@apollo/client";
import { useMemo } from "react";
import { graphql } from "@/generated";

const getSuppliersDocument = graphql(`
    query contracts_searchSuppliers($input: GetSuppliersInput!) {
        getSuppliers(input: $input) {
            suppliers {
                id
                name
            }
        }
    }
`);

export function useSuppliersSearch() {
    const [searchSuppliers, { data, loading, error }] = useLazyQuery(getSuppliersDocument, {
        variables: { input: { nameSearch: "" } },
    });
    const matches = useMemo(() => data?.getSuppliers.suppliers ?? [], [data]);

    return {
        update: async (term: string) => {
            searchSuppliers({ variables: { input: { nameSearch: term, pageRange: 25 } } });
        },
        matches,
        loading,
        error,
    };
}
