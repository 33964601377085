import { EyeSolid, EyeSlashSolid, ChevronDown, ChevronUp, Trash, Pen } from "@ignite-analytics/icons";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import React, { useState } from "react";
import { Group } from "src/types/Layout";
import GroupNameInput from "../GroupNameInput";

interface Props {
    group: Group;
    disableMoveGroupUp?: boolean;
    disableMoveGroupDown?: boolean;
    showGroupOptions: boolean;
    hideDeleteGroup?: boolean;
    onMoveGroupUp: VoidFunction;
    onMoveGroupDown: VoidFunction;
    onToggleVisibility: VoidFunction;
    onDeleteGroup?: VoidFunction;
    onNameEdit: (newName: string) => void;
}

const FieldsGroupHeader: React.FC<Props> = ({
    group,
    disableMoveGroupDown = false,
    disableMoveGroupUp = false,
    showGroupOptions,
    hideDeleteGroup = true,
    onMoveGroupDown,
    onMoveGroupUp,
    onToggleVisibility,
    onDeleteGroup,
    onNameEdit,
}: Props) => {
    const [editMode, setEditMode] = useState<boolean>(false);

    return (
        <Grid display="flex" justifyContent="space-between" container>
            <Grid display="flex" alignItems="center" item sm={9}>
                <IconButton onClick={() => onMoveGroupDown()} disabled={disableMoveGroupDown}>
                    <ChevronDown />
                </IconButton>
                <IconButton onClick={() => onMoveGroupUp()} disabled={disableMoveGroupUp}>
                    <ChevronUp />
                </IconButton>
                {!editMode && <div data-testid="group-name-label">{group.name}</div>}
                {editMode && (
                    <GroupNameInput
                        name={group.name}
                        onSubmit={(newName) => {
                            onNameEdit(newName);
                            setEditMode(false);
                        }}
                        onCancel={() => setEditMode(false)}
                    />
                )}
            </Grid>
            {showGroupOptions && !editMode && (
                <Grid
                    paddingRight="1em"
                    data-testid="view-buttons"
                    item
                    sm={3}
                    justifyContent="flex-end"
                    display="flex"
                >
                    <IconButton role="button" aria-label="edit" onClick={() => setEditMode(true)}>
                        <Pen />
                    </IconButton>
                    {!hideDeleteGroup && (
                        <IconButton role="button" aria-label="delete" onClick={() => onDeleteGroup && onDeleteGroup()}>
                            <Trash />
                        </IconButton>
                    )}
                    <IconButton onClick={() => onToggleVisibility()}>
                        {group.visible ? <EyeSolid /> : <EyeSlashSolid />}
                    </IconButton>
                </Grid>
            )}
        </Grid>
    );
};

export default FieldsGroupHeader;
