import React from "react";
import {
    Accordion as MuiAccordion,
    AccordionSummary as MuiAccordionSummary,
    AccordionDetails as MuiAccordionDetails,
    AccordionProps,
    AccordionSummaryProps,
    Checkbox as MuiCheckbox,
    CheckboxProps,
    Radio as MuiRadio,
    RadioProps,
    styled,
} from "@mui/material";

const Checkbox = (props: CheckboxProps) => (
    <MuiCheckbox onClick={(e) => e.stopPropagation()} sx={{ padding: 0, marginLeft: "auto" }} size="small" {...props} />
);

const Radio = (props: RadioProps) => (
    <MuiRadio onClick={(e) => e.stopPropagation()} sx={{ padding: 0, marginLeft: "auto" }} size="small" {...props} />
);

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
    () => ({
        border: `0`,
        "&:before": {
            display: "none",
        },
    })
);

const AccordionSummary = styled((props: AccordionSummaryProps) => <MuiAccordionSummary {...props} />)(({ theme }) => ({
    flexDirection: "row-reverse",
    borderRadius: "6px",
    minHeight: "0",
    maxHeight: "40px",
    marginTop: "-1px",
    ":hover": {
        backgroundColor: theme.palette.primary.hover,
    },
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(2),
}));

export { Accordion, AccordionSummary, AccordionDetails, Checkbox, Radio };
