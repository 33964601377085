import { TextField } from "@mui/material";
import React from "react";
import { ComputeInputProps } from "src/components/readOnlyStypeComputer";

interface TextAreaRendererProps {
    id: string;
    label: string;
    value?: string;
    readOnly: boolean;
    onChange: (value: string) => void;
}

export const TextAreaRenderer: React.FC<TextAreaRendererProps> = ({ id, label, value, onChange, readOnly }) => (
    <TextField
        id={`text_area_renderer_${id}`}
        label={label}
        variant={readOnly ? "standard" : "outlined"}
        fullWidth
        multiline
        maxRows={6}
        value={value ?? ""}
        onChange={(newValue) => {
            onChange(newValue.target.value);
        }}
        InputProps={ComputeInputProps(readOnly)}
    />
);
