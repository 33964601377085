import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { track } from "@ignite-analytics/track";
import React from "react";
import { Switch, TableCell, Tooltip } from "@mui/material";
import { FileArrowDown, Trash, FileBlock } from "@ignite-analytics/icons";
import OverflowTooltip from "../../OverflowTooltip";
import { fileHasError } from "./helpers";
import StatusIndicator from "../dumb/StatusIndicator";
import { UploadFileInfo } from "./UploadFileInfo.model";
import { UploadFileStatus } from "./UploadFileStatus.enum";
import { makeMessages } from "./messages";

interface FilesListItemProps {
    file: UploadFileInfo;
    onDeleteFile: (file: UploadFileInfo) => void;
    disabled?: boolean;
    onSwitch?: (id: string, checked: boolean) => void;
    editor: boolean;
    freeze?: boolean;
}
type FileInfoProps = {
    file: UploadFileInfo;
    freeze?: boolean;
};
const FileInfo = ({ file, freeze }: FileInfoProps) => (
    <>
        {freeze && <OverflowTooltip text={file.name} />}
        {!freeze && (
            <OverflowTooltip
                text={file.name}
                url={(file.status === UploadFileStatus.Selected && file.url) || undefined}
            />
        )}
        <Box alignItems="center" sx={{ pl: 1 }}>
            <StatusIndicator file={file} />
        </Box>
    </>
);
export const FileListItem = ({
    file,
    onDeleteFile: deleteFile,
    disabled = false,
    onSwitch,
    editor,
    freeze,
}: FilesListItemProps) => {
    const getFileUrl = (fileInfo: UploadFileInfo) =>
        fileInfo.url || (fileInfo.file && window.URL.createObjectURL(new Blob([fileInfo.file])));

    const downloadFile = (selectedFileInfo: UploadFileInfo) => {
        const url = getFileUrl(selectedFileInfo);
        if (url) {
            track("Contract Details: File downloaded", { hasEditPermissions: editor });
            if (!editor) {
                track("File download initiated by a contract viewer");
            }
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${selectedFileInfo.name}`);
            link.setAttribute("target", "_blank");
            link.setAttribute("rel", "noreferrer noopener");
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
        }
    };

    const messages = makeMessages();
    return (
        <>
            <TableCell style={{ border: "none", maxWidth: "30%" }}>
                <FileInfo file={file} freeze={freeze} />
            </TableCell>
            {editor ? (
                <TableCell align="right" style={{ border: "none", maxWidth: "30%" }}>
                    {!fileHasError(file) && file.status !== UploadFileStatus.Uploading && (
                        <Switch
                            defaultChecked={file.isPublic}
                            onChange={(_, checked) => file.id && onSwitch?.(file.id, checked)}
                            sx={{ margin: "auto" }}
                            disabled={!file.url}
                        />
                    )}
                </TableCell>
            ) : (
                <TableCell align="right" style={{ border: "none", maxWidth: "30%" }} />
            )}
            <TableCell align="right" style={{ border: "none", maxWidth: "100%" }}>
                <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
                    <Box>
                        {!fileHasError(file) && file.status !== UploadFileStatus.Uploading && file.url && (
                            <>
                                {file.url && (
                                    <IconButton
                                        data-testid="file-download-button"
                                        disabled={freeze}
                                        size="small"
                                        onClick={() => downloadFile(file)}
                                    >
                                        <FileArrowDown />
                                    </IconButton>
                                )}
                            </>
                        )}
                    </Box>
                    <Box>
                        {!disabled && file.url && editor && (
                            <IconButton data-testid="file-delete-button" size="small" onClick={() => deleteFile(file)}>
                                <Trash />
                            </IconButton>
                        )}
                    </Box>
                    <Box>
                        {!file.isPublic && !file.url && !editor && (
                            <Tooltip title={messages.restrictedFileTooltip}>
                                <IconButton>
                                    <FileBlock data-testid="restricted" />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Box>
                </Box>
            </TableCell>
        </>
    );
};
