import { defineMessages } from "react-intl";
import { fm } from "src/contexts/IntlContext";
import { ManageAccessMessages } from "./manageAccessPopup";

const messages = defineMessages({
    title: {
        id: "manageAccessPopup.title",
        description: "Manage access popup header text",
        defaultMessage: "Manage access for selected contracts",
    },
    placeholder: {
        id: "manageAccessPopup.placeholder",
        description: "Manage access popup placeholder text",
        defaultMessage: "Add individual permissions",
    },
    add: {
        id: "manageAccessPopup.add",
        description: "Add button caption",
        defaultMessage: "Add",
    },
    contactListTitle: {
        id: "manageAccessPopup.contactListTitle",
        description: "Contact list title",
        defaultMessage: "Individual permissions",
    },
    remove: {
        id: "manageAccessPopup.remove",
        description: "Remove button caption",
        defaultMessage: "Remove",
    },
    cancel: {
        id: "manageAccessPopup.cancel",
        description: "Cancel button caption",
        defaultMessage: "Cancel",
    },
    save: {
        id: "manageAccessPopup.save",
        description: "Save button caption",
        defaultMessage: "Save",
    },
    readAccess: {
        id: "manageAccessPopup.readAccess",
        description: "Read access label",
        defaultMessage: "Can View",
    },
    mixedAccess: {
        id: "manageAccessPopup.mixedAccess",
        description: "Mixed access label",
        defaultMessage: "Mixed",
    },
    public: {
        id: "manageAccessPopup.public",
        description: "Manage access popup `public` option text",
        defaultMessage: "Public",
    },
    publicDesc: {
        id: "manageAccessPopup.publicDesc",
        description: "Manage access popup `public` option description text",
        defaultMessage: "Contracts marked as public are visible to all users with view access.",
    },
    restricted: {
        id: "manageAccessPopup.restricted",
        description: "Manage access popup `restricted` option text",
        defaultMessage: "Restricted",
    },
    restrictedDesc: {
        id: "manageAccessPopup.restrictedDesc",
        description: "Manage access popup `restricted` option description text",
        defaultMessage: "Restricted contracts can also be viewed by users with admin role.",
    },
});

export const makeManageAccessPopupMessages = (): ManageAccessMessages => ({
    title: fm(messages.title).toString(),
    placeholder: fm(messages.placeholder).toString(),
    add: fm(messages.add).toString(),
    contactListTitle: fm(messages.contactListTitle).toString(),
    remove: fm(messages.remove).toString(),
    cancel: fm(messages.cancel).toString(),
    save: fm(messages.save).toString(),
    readAccess: fm(messages.readAccess).toString(),
    mixedAccess: fm(messages.mixedAccess).toString(),
    public: fm(messages.public).toString(),
    publicDesc: fm(messages.publicDesc).toString(),
    restricted: fm(messages.restricted).toString(),
    restrictedDesc: fm(messages.restrictedDesc).toString(),
});
