import React from "react";
import { Typography, Button, Stack } from "@mui/material";
import { Link } from "@tanstack/react-router";
import { track } from "@ignite-analytics/track";

const defaultMessages = {
    startRfx: "Start RFx",
    noRfxLink: "No RFx linked to this contract yet",
    rfxUrl: "Go to RFx",
};

const trackEvent = () => {
    track("RFx process initiated from a contract");
};

export type Messages = typeof defaultMessages;

type Props = {
    rfxCreationUrl: string;
    rfxEventUrl?: string;
    messages?: Messages;
};
const Rfx = ({ rfxCreationUrl, rfxEventUrl, messages: messagesProp }: Props) => {
    const messages = messagesProp ?? defaultMessages;
    return (
        <>
            <Stack direction="row" justifyContent="space-between">
                <Typography>RFx</Typography>
                <Link to={rfxCreationUrl} target="_blank" rel="noreferrer" style={{ textDecoration: "none" }}>
                    <Button onClick={trackEvent}>{messages.startRfx}</Button>
                </Link>
            </Stack>
            {rfxEventUrl ? (
                <Link to={rfxEventUrl} target="_blank" rel="noreferrer">
                    <Typography>{messages.rfxUrl}</Typography>
                </Link>
            ) : (
                <Typography>{messages.noRfxLink}</Typography>
            )}
        </>
    );
};

export default Rfx;
