import { yupResolver } from "@hookform/resolvers/yup";
import { Pen, Trash } from "@ignite-analytics/icons";
import { Avatar, Button, Card, CardContent, IconButton, Stack, TextField, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useIntl } from "react-intl";
import { Note } from "../models";
import { NotesMessages } from "../messages";

interface NoteForm {
    description: string;
}

interface Props {
    initialFocus?: boolean;
    referenceId: string;
    note?: Note;
    userId?: string;
    editor: boolean;
    messages: NotesMessages;
    onSave: (note?: { id?: string; referenceId: string; text: string }) => Promise<void>;
    onDelete: (noteId: string) => Promise<void>;
}

type NewNoteProps = {
    note?: Note;
    referenceId: string;
    isEditing?: boolean;
    messages: NotesMessages;
    setEditMode: (edit: boolean) => void;
    onSave: (note?: { id?: string; referenceId: string; text: string }) => Promise<void>;
};
const EditNote = ({ note, referenceId, isEditing, messages, onSave, setEditMode }: NewNoteProps) => {
    const { formatMessage } = useIntl();
    const noteSchema = yup.object().shape({
        description: yup.string().required(messages.noteRequired),
    });
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<NoteForm>({
        resolver: yupResolver(noteSchema),
        defaultValues: {
            description: note ? note.text : "",
        },
    });
    const saveNote = (data: NoteForm) => {
        onSave({ id: note?.id, referenceId, text: data.description }).then(() => {
            if (isEditing) {
                setEditMode(false);
            } else {
                reset();
            }
        });
    };

    return (
        <form onSubmit={handleSubmit(saveNote)}>
            <TextField
                placeholder={formatMessage({ defaultMessage: "Add a new note here" })}
                multiline
                rows={4}
                fullWidth
                error={!!errors?.description}
                helperText={errors?.description?.message}
                {...register("description")}
            />

            <Stack direction="row" justifyContent="flex-end" mt={1}>
                <Button type="submit" size="small">
                    {messages.saveButton}
                </Button>
            </Stack>
        </form>
    );
};
export const SingleNote = ({ initialFocus, referenceId, note, userId, editor, messages, onSave, onDelete }: Props) => {
    const [isEditing, setIsEditing] = useState(initialFocus ?? false);

    const userIsOwner = useMemo(() => userId === note?.creator.id, [userId, note]);

    const fullName = useMemo(() => (note ? `${note.creator.firstName} ${note.creator.lastName}` : ""), [note]);

    const noteText = useMemo(() => (note ? note.text : ""), [note]);

    const content = (
        <Stack>
            {note && (
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Stack direction="row" alignItems="center">
                        <Avatar
                            sx={{
                                width: 24,
                                height: 24,
                                fontSize: "0.875rem",
                            }}
                        >
                            {note.creator.firstName[0] + note.creator.lastName[0]}
                        </Avatar>
                        <Stack pl={1}>
                            <Typography variant="subtitle1" fontWeight={600} lineHeight={1} m={0}>
                                {fullName}
                            </Typography>
                            <Typography variant="body2" fontSize={12} m={0}>
                                {messages.createdAt}
                            </Typography>
                        </Stack>
                    </Stack>
                    {userIsOwner && (
                        <Stack direction="row">
                            <IconButton onClick={() => setIsEditing((s) => !s)} aria-label="edit" size="small">
                                <Pen fontSize="inherit" />
                            </IconButton>
                            <IconButton onClick={() => onDelete(note.id)} aria-label="delete" size="small">
                                <Trash fontSize="inherit" />
                            </IconButton>
                        </Stack>
                    )}
                </Stack>
            )}
            <Stack mt={2}>
                {!isEditing && note ? (
                    <Typography variant="body2">{noteText}</Typography>
                ) : (
                    editor && (
                        <EditNote
                            note={note}
                            referenceId={referenceId}
                            setEditMode={setIsEditing}
                            onSave={onSave}
                            messages={messages}
                        />
                    )
                )}
            </Stack>
        </Stack>
    );

    return note ? (
        <Card elevation={1}>
            <CardContent>{content}</CardContent>
        </Card>
    ) : (
        content
    );
};
