import { Plus } from "@ignite-analytics/icons";
import { AutocompleteRenderGetTagProps } from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { useCallback, useState } from "react";
import DropDown from "src/components/ui/DropDown";
import { DropDownOptionItem } from "src/components/ui/DropDown/models/DropDownOptionItem";
import { fm } from "src/contexts/IntlContext";
import messages from "./messages";
import { useCreateSupplier } from "../../../hooks/useCreateSupplier";
import { useSearchSupplier } from "../../../hooks/useSearchSupplier";

export interface SupplierOptionItem extends DropDownOptionItem {
    vatId: string;
    country: string;
}

const AddSupplierTemplateComponent = ({ label }: { label: string }) => (
    <Stack flexDirection="row" display="flex" alignItems="center" data-testid="add-supplier-option">
        <Plus />
        <Typography variant="body1" sx={{ pl: 0.5, lineHeight: 2 }}>
            {fm(messages.newSupplierLabel)}:{label}
        </Typography>
    </Stack>
);

const SupplierOption: React.FC<SupplierOptionItem> = ({ label, vatId, country }) => (
    <Stack>
        <Typography variant="body1">{label}</Typography>
        <Stack>
            <Typography>
                {country} {vatId}
            </Typography>
        </Stack>
    </Stack>
);

interface DropDownProps {
    value?: SupplierOptionItem[];
    fieldLabel: string;
    debounceMs?: number;
    readonly?: boolean;
    marginTop?: number;
    onSelect: (selectedValue: SupplierOptionItem[]) => void;
}

const SupplierDropDown = ({ value, fieldLabel, debounceMs, readonly, marginTop, onSelect }: DropDownProps) => {
    const [searchSuppliers] = useSearchSupplier();
    const [loading, setLoading] = useState<boolean>(false);
    const { create: createSupplier } = useCreateSupplier();
    const [suppliersOptions, setSuppliersOptions] = useState<SupplierOptionItem[]>([]);

    const handleOnSearch = useCallback(
        (phrase: string) => {
            setLoading(true);
            if (phrase?.length === 0) {
                setLoading(false);
                return;
            }

            searchSuppliers(phrase)
                .then((result) => {
                    const mappedSuppliers = (result || []).map((r: any) => ({
                        id: r.id,
                        country: r.country || "",
                        label: r.name,
                        vatId: r.orgNumber || "",
                    }));

                    setSuppliersOptions(mappedSuppliers);
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [searchSuppliers]
    );

    const navigateToSupplier = (supplierId: string) => {
        const newPath = `${window.location.pathname}/supplier-page/${supplierId}/overview`;
        window.postMessage(
            {
                type: "route-change",
                path: newPath,
                from: window.location.pathname,
            },
            window.location.origin
        );
    };

    const handleOnAddDropdown = (newSupplierName: string) => {
        createSupplier(newSupplierName)
            .then((newSupplierId) => {
                const newSupplierOption: SupplierOptionItem = {
                    id: newSupplierId || "",
                    label: newSupplierName,
                    country: "",
                    vatId: "",
                };
                onSelect([newSupplierOption]);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <>
            <DropDown<SupplierOptionItem>
                label={fieldLabel}
                value={value}
                options={suppliersOptions}
                loading={loading}
                optionTemplateComponent={SupplierOption as React.FC<DropDownOptionItem>}
                onSearch={handleOnSearch}
                onSelect={onSelect}
                debounceMs={debounceMs ?? 800}
                readOnly={readonly}
                renderTags={
                    readonly
                        ? (values: SupplierOptionItem[], getTagProps: AutocompleteRenderGetTagProps) =>
                              values.map((option, index) => (
                                  <Chip
                                      {...getTagProps({ index })}
                                      key={option.id}
                                      label={option.label}
                                      data-testid="user-chip"
                                      onClick={() => navigateToSupplier(option.id)}
                                  />
                              ))
                        : undefined
                }
                addItemTemplateCompoment={AddSupplierTemplateComponent}
                onAdd={(newSupplierName) => handleOnAddDropdown(newSupplierName)}
                closeOnSelect
                marginTop={marginTop}
            />
        </>
    );
};

export default SupplierDropDown;
