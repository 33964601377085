import {
    Button,
    DialogContentText,
    FormControlLabel,
    Radio,
    RadioGroup,
    Select,
    Stack,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import type { Contact } from "src/entities/users";
import { track } from "@ignite-analytics/track";
import { ContactsList } from "../../ui/ContactList/contactList";
import { ContactsDropdown } from "../../ui/ContactDropdown/contactsEdit";
import { makeManageAccessPopupMessages } from "./messages";
import { Popup } from "../../ui/Popup/popup";
import { ContractPermissions } from "../manageAccessPopup";

export type EntitledUser = {
    subjectId: string;
    permission: string;
};

export type Access = "restricted" | "public" | undefined;

type Props = {
    isOpen: boolean;
    defaultAccess: Access;
    contacts: Contact[];
    contractsPermission: ContractPermissions[];
    onClose: () => void;
    onSave: (permissions: ContractPermissions[], access: Access) => void;
    contractsIds: string[];
};

export type ManageAccessMessages = {
    title: string;
    placeholder: string;
    add: string;
    contactListTitle: string;
    remove: string;
    cancel: string;
    save: string;
    readAccess: string;
    mixedAccess: string;
    public: string;
    publicDesc: string;
    restricted: string;
    restrictedDesc: string;
};

const getContractPermissionMap = (contractId: string, permissions: ContractPermissions[]) => {
    const contractPermissions = permissions.find((p) => p.objectId === contractId);
    if (!contractPermissions) {
        return new Map<string, string>();
    }

    const map: Map<string, string> = new Map(
        contractPermissions.entitleds.map((e) => [e.subjectId, e.permission]) ?? []
    );
    return map;
};

const parseRespose = (response: ContractPermissions[], contractsIds: string[]) => {
    const map: Map<string, Map<string, string>> = new Map(
        contractsIds.map((p: string) => [p, getContractPermissionMap(p, response)])
    );
    return map;
};

export const ManageAccessPopup = ({
    isOpen,
    contacts,
    defaultAccess,
    contractsPermission,
    onClose,
    onSave,
    contractsIds,
}: Props) => {
    const [entitledContactsList, setEntitledContactsList] = useState<Contact[]>([]);
    const [selectedInDropdown, setSelectedInDropdown] = useState<Contact[]>([]);
    const [subjectIdToPermissions, setSubjectIdToPermissions] = useState<Map<string, string>>(new Map());
    const messages = makeManageAccessPopupMessages();
    const defaultPermission = "read";
    const [access, setAccess] = useState<Access>(defaultAccess);
    const [contractsToPermissions, setContractsToPermissions] = useState<Map<string, Map<string, string>>>(new Map());

    useEffect(() => {
        const contactsList: Contact[] = [];
        const entitledUsersIds = contractsPermission.flatMap((entitled) =>
            entitled.entitleds.map((user) => user.subjectId)
        );

        contacts.forEach((contact) => {
            if (entitledUsersIds.includes(contact.id)) contactsList.push(contact);
        });
        setEntitledContactsList(contactsList);
        const subjectIdToOccurance = contractsPermission
            .flatMap((perm) => perm.entitleds)
            .reduce<Map<string, number>>((acc, current) => {
                if (acc.has(current.subjectId)) {
                    acc.set(current.subjectId, acc.get(current.subjectId)! + 1);
                } else {
                    acc.set(current.subjectId, 1);
                }
                return acc;
            }, new Map<string, number>());

        const subjectIdToPermission = new Map(
            [...subjectIdToOccurance].map(([subId, occurances]) => [
                subId,
                occurances === contractsIds.length ? "read" : "mixed",
            ])
        );
        setContractsToPermissions(parseRespose(contractsPermission, contractsIds));

        setSubjectIdToPermissions(subjectIdToPermission);
    }, [contacts, contractsPermission, contractsIds.length, contractsIds]);

    const addPermission = (contactsToAdd: Contact[]) => {
        const newContacts = contactsToAdd.filter((contact) => !entitledContactsList.includes(contact));
        setEntitledContactsList([...entitledContactsList, ...newContacts]);
        contractsToPermissions.forEach((permissionsMap) => {
            contactsToAdd.forEach((contact) => {
                permissionsMap.set(contact.id, defaultPermission);
            });
        });

        track("ManageAccessPopup user add", { users: contactsToAdd.length });

        setContractsToPermissions(contractsToPermissions);
        setSelectedInDropdown([]);
    };

    const removePermission = (contactIdToRemove: string) => {
        contractsToPermissions.forEach((permissionsMap) => {
            permissionsMap.delete(contactIdToRemove);
        });
        setContractsToPermissions(contractsToPermissions);

        const filteredContacts = entitledContactsList.filter((contact) => contact.id !== contactIdToRemove);
        setEntitledContactsList(filteredContacts);
    };

    const changePermission = (subjectId: string, newPermission: string) => {
        if (newPermission === "none") {
            removePermission(subjectId);
            return;
        }
        contractsToPermissions.forEach((permissionsMap) => {
            permissionsMap.set(subjectId, newPermission);
        });
        setContractsToPermissions(contractsToPermissions);
    };

    const handleCancel = () => {
        onClose();
    };

    const handleOnSave = () => {
        const newPermissions: ContractPermissions[] = [];
        contractsToPermissions.forEach((permissionsMap, contractId) => {
            const entitleds: EntitledUser[] = [];
            permissionsMap.forEach((permission, subjectId) => {
                entitleds.push({ subjectId, permission });
            });
            newPermissions.push({ objectId: contractId, entitleds });
        });
        onSave(newPermissions, access);
        onClose();
    };

    const handleAccessChange = (event: React.ChangeEvent<HTMLInputElement>) =>
        setAccess((event.target as HTMLInputElement).value as Access);

    const renderForm = (contactId: string, label: string | undefined) => (
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <Select
                defaultValue={label ?? defaultPermission}
                key={`permission-${contactId}`}
                onChange={(event) => changePermission(contactId, event.target.value)}
            >
                <MenuItem value="read">{messages.readAccess}</MenuItem>
                {label === "mixed" && (
                    <MenuItem value="mixed" disabled>
                        {messages.mixedAccess}
                    </MenuItem>
                )}
                {/* Text color hardcoded because of color tokens are not supported here https://mui.com/material-ui/customization/palette/ */}
                <MenuItem value="none" sx={{ color: "#F44336" }}>
                    {messages.remove}
                </MenuItem>
            </Select>
        </FormControl>
    );

    const restrictedLabel = (
        <Stack direction="column">
            <Typography variant="subtitle1">{messages.restricted}</Typography>
            <Typography variant="body2">{messages.restrictedDesc}</Typography>
        </Stack>
    );
    const publicLabel = (
        <Stack direction="column">
            <Typography variant="subtitle1">{messages.public}</Typography>
            <Typography variant="body2">{messages.publicDesc}</Typography>
        </Stack>
    );

    return (
        <Popup
            cancelLabel={messages.cancel}
            isOpen={isOpen}
            onCancel={handleCancel}
            onSubmit={async () => handleOnSave()}
            titleLabel={messages.title}
            submitLabel={messages.save ?? "Save"}
            disableSubmitButton={access === undefined}
        >
            <FormControl>
                <RadioGroup name="access-radio-group" value={access} onChange={handleAccessChange}>
                    <FormControlLabel sx={{ m: 1 }} value="public" control={<Radio />} label={publicLabel} />
                    <FormControlLabel sx={{ m: 1 }} value="restricted" control={<Radio />} label={restrictedLabel} />
                </RadioGroup>
            </FormControl>
            {access === "restricted" && (
                <>
                    <DialogContentText paddingTop={4}>{messages.contactListTitle}</DialogContentText>
                    <Stack overflow="auto">
                        <ContactsList
                            contacts={entitledContactsList}
                            secondaryAction={(contactWithPermission: Contact) =>
                                renderForm(
                                    contactWithPermission.id,
                                    subjectIdToPermissions.get(contactWithPermission.id)
                                )
                            }
                        />
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" spacing={1} paddingTop={2}>
                        <ContactsDropdown
                            key={`manageAccessPopup-${selectedInDropdown.length}`}
                            placeholder={messages.placeholder}
                            multiple
                            defaultValue={selectedInDropdown}
                            options={contacts}
                            onChange={setSelectedInDropdown}
                        />
                        <Button type="button" variant="text" onClick={() => addPermission(selectedInDropdown)}>
                            {messages.add}
                        </Button>
                    </Stack>
                </>
            )}
        </Popup>
    );
};
