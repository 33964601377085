import LoadingButton from "@mui/lab/LoadingButton";
import { TrashSolid, PenSolid } from "@ignite-analytics/icons";
import { Button, Grid, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import { Layout } from "src/types/Layout";
import { CustomField } from "src/models/customFields";
import { FormattedMessage } from "react-intl";
import { CustomFieldsSection } from "./customFieldsSection";
import { ContractGeneralInformation, GeneralInformationSection } from "./generalInformationSection";
import DeletePrompt from "../../../components/DeletePrompt";

const ID_PREFIX = "contractEditPage-";

interface ContractDetailsProps {
    generalInformation: ContractGeneralInformation;
    layout: Layout | undefined;
    onSave: (generalInformation: ContractGeneralInformation, customFields: CustomField[]) => Promise<void>;
    onCancel: () => void;
    onBack: () => void;
    customFields: CustomField[];
    onDelete: () => void;
    canEdit: boolean;
    hasRenewalDate: boolean;
}

export const ContractDetails: React.FC<ContractDetailsProps> = ({
    generalInformation,
    onSave,
    onCancel,
    onBack,
    layout,
    customFields,
    onDelete,
    canEdit,
    hasRenewalDate,
}) => {
    const [pendingCustomFields, setPendingCustomFields] = useState<CustomField[]>([]);
    const [pendingGeneralInformation, setPendingGeneralInformation] = useState<ContractGeneralInformation | undefined>(
        undefined
    );

    const updatePendingCustomField = (customField: CustomField) => {
        const allPendingsButTheOneUpdated = pendingCustomFields.filter((x) => x.id !== customField.id);
        setPendingCustomFields([...allPendingsButTheOneUpdated, customField]);
    };
    const [loading, setLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [deleteContractModalOpen, setDeleteContractModalOpen] = useState(false);

    const save = () => {
        setLoading(true);
        onSave(pendingGeneralInformation ?? generalInformation, pendingCustomFields).then(() => {
            setLoading(false);
            setEditMode(false);
        });
    };

    const cancel = () => {
        onCancel();
        setEditMode(false);
    };

    return (
        <>
            <Grid>
                {editMode ? (
                    <Grid item>
                        <Button type="button" variant="text" onClick={cancel}>
                            <FormattedMessage defaultMessage="Cancel" />
                        </Button>
                        <LoadingButton
                            data-testid={`${ID_PREFIX}submitButton`}
                            loading={loading}
                            variant="contained"
                            type="submit"
                            onClick={() => save()}
                        >
                            <FormattedMessage defaultMessage="Save" />
                        </LoadingButton>
                    </Grid>
                ) : (
                    <Grid container direction="row" alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <Typography key="general_information_label" variant="h6">
                                <FormattedMessage defaultMessage="Contract Details" />
                            </Typography>
                        </Grid>
                        <Grid>
                            {canEdit && (
                                <>
                                    <Tooltip title={<FormattedMessage defaultMessage="Edit" />}>
                                        <IconButton
                                            size="small"
                                            aria-label="edit contract"
                                            onClick={() => setEditMode(true)}
                                        >
                                            <PenSolid fontSize="inherit" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={<FormattedMessage defaultMessage="Delete" />}>
                                        <IconButton
                                            size="small"
                                            aria-label="delete contract"
                                            onClick={() => setDeleteContractModalOpen(true)}
                                        >
                                            <TrashSolid fontSize="inherit" />
                                        </IconButton>
                                    </Tooltip>
                                    <DeletePrompt
                                        open={deleteContractModalOpen}
                                        onDelete={async () => onDelete()}
                                        onCancel={() => setDeleteContractModalOpen(false)}
                                    />
                                </>
                            )}
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <Stack key="custom_fields_stack_container">
                <GeneralInformationSection
                    editMode={editMode}
                    onChange={setPendingGeneralInformation}
                    generalInformation={generalInformation}
                    hasRenewalDate={hasRenewalDate}
                />
                <CustomFieldsSection
                    layout={layout}
                    editMode={editMode}
                    customFields={customFields}
                    onChange={updatePendingCustomField}
                />

                <Stack direction="row" justifyContent="flex-end" pt={2} key="custom_fields_buttons">
                    {!editMode && (
                        <Button type="button" variant="text" onClick={onBack}>
                            <FormattedMessage defaultMessage="Back" />
                        </Button>
                    )}
                </Stack>
            </Stack>
        </>
    );
};
