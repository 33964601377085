import Autocomplete, { AutocompleteRenderGetTagProps, createFilterOptions } from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import { TextField, Typography } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Plus } from "@ignite-analytics/icons";

import React, { useState } from "react";
import { fm } from "src/contexts/IntlContext";
import messages from "./messages";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type DropDownProps<T> = {
    defaultValue?: T[];
    multiple?: boolean;
    readOnly?: boolean;
    label?: string;
    limitTags?: number;
    options: T[];
    loading?: boolean;
    placeholder?: string;
    getOptionLabel: (option: T) => string;
    isOptionEqualToValue?: (option: T, value: T) => boolean;
    optionTemplateComponent?: React.FC<T>;
    renderTags?: (values: T[], getTagProps: AutocompleteRenderGetTagProps, ownerState?: unknown) => React.ReactNode;
    onChange?: (values: T[]) => void;
    onBlur?: () => void;
    onCreate?: (input: string) => void;
};
// eslint-disable-next-line @typescript-eslint/ban-types
const DropDown = <T extends {} | string>({
    defaultValue,
    multiple = false,
    readOnly,
    label,
    limitTags,
    options,
    loading,
    optionTemplateComponent,
    placeholder,
    isOptionEqualToValue,
    getOptionLabel,
    renderTags,
    onBlur,
    onChange,
    onCreate,
}: DropDownProps<T>) => {
    const [value, setValue] = useState(defaultValue);
    const filter = createFilterOptions<T>();
    return (
        <Autocomplete
            multiple
            defaultValue={defaultValue}
            options={options}
            filterOptions={(allOptions, params) => {
                const filtered = filter(allOptions, params);
                if (onCreate) {
                    const { inputValue } = params;
                    inputValue !== "" && filtered.push(inputValue as T);
                }
                return filtered;
            }}
            selectOnFocus
            value={value}
            onBlur={onBlur}
            disableClearable
            isOptionEqualToValue={isOptionEqualToValue}
            disableCloseOnSelect
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    {multiple && typeof option !== "string" && (
                        <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                    )}
                    {optionTemplateComponent
                        ? React.createElement(optionTemplateComponent, option)
                        : getOptionLabel(option)}
                    {typeof option === "string" && (
                        <>
                            <Plus fontSize="inherit" sx={{ mr: 0.5 }} />
                            <Typography>{`Add "${option}"`}</Typography>
                        </>
                    )}
                </li>
            )}
            getOptionLabel={(option) => getOptionLabel(option)}
            loading={loading}
            loadingText={fm(messages.loadingText).toString()}
            noOptionsText={
                !defaultValue || defaultValue.length === 0
                    ? fm(messages.emptyInputText).toString()
                    : fm(messages.noOptionsText).toString()
            }
            renderInput={(params) => (
                <TextField
                    autoFocus
                    {...params}
                    placeholder={!readOnly ? placeholder ?? fm(messages.emptyInputText).toString() : ""}
                    label={label}
                    variant={readOnly ? "filled" : "outlined"}
                />
            )}
            forcePopupIcon={false}
            onChange={(_, values) => {
                const newOption = values[values.length - 1];
                if (onCreate && typeof newOption === "string") {
                    onCreate(newOption);
                    return;
                }
                const hasSingleChanged = values.length && multiple !== true;
                setValue(hasSingleChanged ? [newOption] : values);
                onChange?.(values);
            }}
            readOnly={readOnly}
            limitTags={limitTags}
            renderTags={renderTags}
            sx={{ width: "100%" }}
        />
    );
};

export default DropDown;
