import React from "react";
import Rfx from "./dumb/rfx";
import makeMessages from "./messages";
import { env } from "@/env";

const MAX_URL_LENGTH = 1500;
const TEST_TENANTS = ["ostoyapl", "deepstreamignitedemo", "ignitedeepstreamdemo"];

type Props = {
    deepStreamId: string;
    tenant: string;
    creator: string;
    title?: string;
    supplierIds?: string[];
    rfxEventUrl?: string;
    description?: string;
};

const trimUrl = (urlToTrim: string, maxLen: number): string => {
    const trimmedUrl = urlToTrim.substring(0, maxLen);
    const lastPercentageIndex = trimmedUrl.lastIndexOf("%");
    if (lastPercentageIndex < trimmedUrl.length - 2) {
        return trimmedUrl;
    }
    return urlToTrim.substring(0, maxLen - (maxLen - lastPercentageIndex));
};

const computeBaseLink = (deepStreamId: string, tenant: string): string => {
    const trimmedId = deepStreamId.trim();
    let baseUrl = env.REACT_APP_DEEP_STREAM_URL;
    if (TEST_TENANTS.includes(tenant)) {
        baseUrl = env.REACT_APP_PILOT_DEEP_STREAM_URL;
    }
    return `${baseUrl}/${trimmedId}/requests?tab=templates&`;
};

const RfxCard = ({ rfxEventUrl, supplierIds, description, deepStreamId, creator, title, tenant }: Props) => {
    const params = new URLSearchParams({ systemId: tenant, creator });
    title && params.append("requestName", title);
    supplierIds?.forEach((id) => {
        params.append("supplierIds", id);
    });
    description && params.append("requestOverview", description);
    const rfxCreationUrl = trimUrl(`${computeBaseLink(deepStreamId, tenant)}${params.toString()}`, MAX_URL_LENGTH);
    return <Rfx rfxCreationUrl={rfxCreationUrl} rfxEventUrl={rfxEventUrl} messages={makeMessages()} />;
};
export default RfxCard;
