import React from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Email, Phone } from "@ignite-analytics/icons";
import { DialogContent, InputAdornment, Stack, TextField } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { ObjectSchema } from "yup";
import { Contact } from "../ui/ContactList/contactList";
import { Popup } from "../ui/Popup/popup";
import { ContactsMessages, makeContactsMessages } from "./messages";

const searchTermToNewContact = (term: string): NewContact => {
    const terms = term.trim().split(/\s+/);
    return {
        firstName: terms[0] ?? "",
        lastName: terms[1] ?? "",
        email: terms[2] ?? "",
        phone: terms[3] ?? "",
    };
};

export type NewContact = Omit<Contact, "id">;

type Props = {
    isOpen: boolean;
    customMessages?: ContactsMessages;
    triggeringTerm?: string;
    onClose: () => void;
    onSubmit: (contact: NewContact) => Promise<{ error?: boolean }>;
};

export const AddContact = ({ isOpen, triggeringTerm = "", onClose, onSubmit }: Props) => {
    const messages = makeContactsMessages();
    const initialNewContact = searchTermToNewContact(triggeringTerm);

    const contactSchema = yup.object({
        email: yup
            .string()
            .email(messages.email.errors.invalid)
            .required(messages.email.errors.required)
            .test("is-valid-email", messages.email.errors.invalid, (value) => {
                if (!value) {
                    return false;
                }
                const domain = value.split("@")[1];
                return !!(domain && domain.includes(".") && domain.split(".").length > 1);
            }),
        firstName: yup.string(),
        lastName: yup.string(),
        phone: yup.string(),
    });

    const {
        register,
        formState: { errors },
        reset,
        handleSubmit,
    } = useForm<NewContact>({
        resolver: yupResolver<NewContact>(contactSchema as ObjectSchema<NewContact, Record<string, unknown>, any, "">),
    });

    const onSubmitHandler: SubmitHandler<NewContact> = async (contact) => {
        const { error } = await onSubmit(contact);
        if (error) {
            return;
        }
        reset();
    };

    const onCanceled = () => {
        reset();
        onClose();
    };

    return (
        <Popup
            isOpen={isOpen}
            onCancel={onCanceled}
            onSubmit={handleSubmit(onSubmitHandler)}
            titleLabel={messages.header}
            submitLabel={messages.submit}
            cancelLabel={messages.cancel}
        >
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <DialogContent sx={{ overflow: "auto" }}>
                    <Stack gap={1}>
                        <TextField
                            label={messages.firstName}
                            helperText={errors.firstName?.message}
                            error={!!errors.firstName}
                            defaultValue={initialNewContact.firstName}
                            {...register("firstName")}
                        />
                        <TextField
                            label={messages.lastName}
                            helperText={errors.lastName?.message}
                            error={!!errors.lastName}
                            defaultValue={initialNewContact.lastName}
                            {...register("lastName")}
                        />
                        <TextField
                            label={`${messages.email.label} *`}
                            helperText={errors.email?.message}
                            defaultValue={initialNewContact.email}
                            error={!!errors.email}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Email />
                                    </InputAdornment>
                                ),
                            }}
                            {...register("email")}
                        />
                        <TextField
                            label={messages.phone}
                            helperText={errors.phone?.message}
                            defaultValue={initialNewContact.phone}
                            error={!!errors.phone}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Phone />
                                    </InputAdornment>
                                ),
                            }}
                            {...register("phone")}
                        />
                    </Stack>
                </DialogContent>
            </form>
        </Popup>
    );
};
