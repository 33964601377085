import { Container } from "@mui/material";
import { Outlet } from "@tanstack/react-router";

export const Root = () => {
    return (
        <Container
            maxWidth={false}
            sx={{ position: "relative", height: "100vh", maxHeight: "calc(100vh - 64px)", overflow: "auto" }}
        >
            <Outlet />
        </Container>
    );
};
