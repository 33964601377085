export enum UploadFileStatus {
    /**
     * Indicates that the file upload process has failed.
     */
    UploadFailed = "UploadFailed",
    /**
     * The file is in the process of uploading.
     */
    Uploading = "Uploading",
    /**
     * The file is successfully uploaded.
     */
    Uploaded = "Uploaded",
    /**
     * The remove process has failed.
     */
    RemoveFailed = "RemoveFailed",

    /** Default file status */
    Selected = "Selected",

    /**
     * The file validation failed
     */
    ValidationFailed = "ValidationFailed",
}
