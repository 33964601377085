import { defineMessages } from "react-intl";

const messages = defineMessages({
    preferencesButton: {
        id: "contractList.preferencesButton",
        defaultMessage: "Preferences",
        description: "preferences button",
    },
    layoutsButton: {
        id: "contractList.layoutsButton",
        defaultMessage: "Layouts",
        description: "layouts button",
    },
    hideShowColumnsButton: {
        id: "contractList.hideShowColumnsButton",
        defaultMessage: "Hide/Show Columns",
        description: "hide/show columns button",
    },
    setDensity: {
        id: "contractList.setDensity",
        defaultMessage: "Set table density",
        description: "set table density",
    },
    compactDensity: {
        id: "contractList.compactDensity",
        defaultMessage: "Compact",
        description: "compact density",
    },
    standardDensity: {
        id: "contractList.standardDensity",
        defaultMessage: "Standard",
        description: "standard density",
    },
    comfortableDensity: {
        id: "contractList.comfortableDensity",
        defaultMessage: "Comfortable",
        description: "comfortable density",
    },
});

export default messages;
