import { track } from "@ignite-analytics/track";
import { Stack, CircularProgress } from "@mui/material";
import React from "react";
import { useContractsApiFetch } from "src/hooks/useContractsApiFetch";
import { useContractsApiPost } from "src/hooks/useContractsApiPost";
import { useContractsApiDelete } from "src/hooks/useContractsApiDelete";
import { useContractsApiPut } from "src/hooks/useContractsApiPut";
import { SingleNote } from "./SingleNote";
import { Note } from "./models";
import { NotesList } from "./notesList";
import { createMessages } from "./messages";
import { useFetchOrySessionDirectly } from "@/entities/users";

interface Props {
    referenceId: string;
    maxHeight?: string;
    canEdit: boolean;
}

export type NewNote = {
    creatorFirstName: string;
    creatorLastName: string;
    text: string;
};

export const Notes = ({ referenceId, maxHeight, canEdit }: Props) => {
    const { userId, firstName, lastName } = useFetchOrySessionDirectly();
    const { data: notes, isLoading: loading, refetch } = useContractsApiFetch<Note[]>(`contracts/${referenceId}/notes`);
    const createNote = useContractsApiPost();
    const updateNote = useContractsApiPut();
    const deleteNoteRequest = useContractsApiDelete();
    const onNoteAdded = (noteText: string) => {
        const newNote: NewNote = {
            creatorFirstName: firstName ?? "",
            creatorLastName: lastName ?? "",
            text: noteText,
        };
        createNote(`contracts/${referenceId}/notes`, newNote).then(() => refetch());
    };

    const onDelete = (noteId: string) => {
        deleteNoteRequest(`contracts/${referenceId}/notes/${noteId}`).then(() => refetch());
    };

    const onNoteUpdated = (noteId: string, noteText: string) => {
        updateNote(`contracts/${referenceId}/notes/${noteId}`, { text: noteText }).then(() => refetch());
    };
    const save = async (note?: { id?: string; referenceId: string; text: string }) => {
        if (note?.id) {
            onNoteUpdated(note.id, note.text);
            track(`Contract Details: Note updated`, { noteId: note.id, noteText: note.text });
        } else if (note) {
            onNoteAdded(note.text);
            track(`Contract Details: Note created`, { noteId: note.id, noteText: note.text });
        }
    };

    const deleteNote = async (noteId: string) => {
        onDelete(noteId);
        track(`Contract Details: Note deleted`, { noteId });
    };
    const messages = createMessages();

    return (
        <>
            <Stack maxHeight={maxHeight ?? "auto"} overflow="auto">
                <Stack spacing={1} mt={1}>
                    {(loading || !userId) && <CircularProgress />}
                    {!loading && userId && notes && (
                        <NotesList
                            notes={notes}
                            referenceId={referenceId}
                            onSave={save}
                            onDelete={deleteNote}
                            userId={userId}
                            editor={canEdit}
                            createMessages={createMessages}
                        />
                    )}
                </Stack>
            </Stack>
            {!loading && userId && (
                <SingleNote
                    referenceId={referenceId}
                    userId={userId ?? undefined}
                    onSave={save}
                    onDelete={deleteNote}
                    editor={canEdit}
                    messages={messages}
                />
            )}
        </>
    );
};
