import { gql } from "@apollo/client";
import { useCallback, useState } from "react";
import { graphQlGatewayClient as client } from "src/gqlClient";
import { Company } from "../components/CompanyDropdown/company";

const matchCompanyMasterId = `
    mutation bulkMatchCompanies($input: BulkMatchCompaniesInput!) {
        bulkMatchCompanies(input: $input) {
            bulkMatches {
                companyMasterId
                companyRequestKey
            }
        }
    }
`;
// hack to avoid codegen for this particular mutation
function gqlMatchCompanyMasterId() {
    return gql`${matchCompanyMasterId}`;
}

export function useMatchCompanyMaster() {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<string[] | undefined>(undefined);

    const getCompanyMasterId = useCallback(async (company: Company) => {
        if (company) {
            const companyMasterId = await client
                .mutate({
                    mutation: gqlMatchCompanyMasterId(),
                    variables: {
                        input: {
                            bulkCompanies: [
                                {
                                    companyRequestKey: "newCompanies",
                                    country: company.country,
                                    name: company.name,
                                    orgNumber: company.orgNumber,
                                },
                            ],
                        },
                    },
                })
                .then((result) => {
                    if (result.errors) {
                        const queryErrors = result.errors
                            .map((e) => e.message)
                            .concat(result.errors?.map((e) => e.message));
                        setErrors(queryErrors);
                    }
                    return result.data.bulkMatchCompanies.bulkMatches[0].companyMasterId;
                })
                .finally(() => setLoading(false));
            return companyMasterId;
        }
    }, []);

    return {
        getCompanyMasterId,
        errors,
        loading,
    };
}
