import { defineMessages } from "react-intl";

const messages = defineMessages({
    searcField: {
        id: "contractsListPage.searchField",
        defaultMessage: "Search",
        description: "Label text: Search field",
    },
});

export default messages;
