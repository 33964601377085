import { Play } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import { Box, Button, Card, CardContent, Chip, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage, MessageDescriptor } from "react-intl";

import { useNavigate } from "@tanstack/react-router";
import { useImage } from "@/hooks/useImage";

const baseUrl = "https://imagedelivery.net/15ALoYjGmzQG831cZcmjQQ/";
const variantName = "public";

type Props = {
    imageId: string;
    trackId: string;
    name: MessageDescriptor;
    header: React.ReactNode;
    description: MessageDescriptor;
};
export const ExploreCard: React.FC<Props> = ({ imageId, trackId, name, header, description }) => {
    const navigate = useNavigate();

    const image = useImage(`${baseUrl}${imageId}/${variantName}`);

    const handleGetStartedClick = () => {
        track(`Explore Modules: Clicked on ${trackId}`);
        navigate({ search: { show: "demo" } });
    };

    return (
        <Card>
            <CardContent sx={{ background: "#F7F7F8" }}>
                <Stack direction="row" gap={2}>
                    <Stack gap={2} justifyContent="space-between">
                        <Stack gap={1}>
                            <Box>
                                <Chip
                                    label={
                                        <Typography variant="textMd" fontWeight={500}>
                                            <FormattedMessage {...name} />
                                        </Typography>
                                    }
                                    color="success"
                                />
                            </Box>
                            {header}
                            <Typography variant="textMd" color="text.text-secondary">
                                <FormattedMessage {...description} />
                            </Typography>
                        </Stack>

                        <Stack direction="row" gap={1} justifyContent="start">
                            <Button startIcon={<Play />} color="secondary" onClick={handleGetStartedClick}>
                                <FormattedMessage defaultMessage="Watch demo" />
                            </Button>
                            <Button
                                color="primary"
                                onClick={() => {
                                    navigate({ search: { show: "learn" } });
                                }}
                            >
                                <FormattedMessage defaultMessage="Learn more" />
                            </Button>
                        </Stack>
                    </Stack>
                    {image.data ? (
                        <Box sx={{ minWidth: 450, position: "relative", left: 50, maxHeight: 300 }}>
                            <Grid container alignItems="flex-end" justifyContent="flex-end">
                                <Grid item>
                                    <img
                                        src={image.data}
                                        alt={`${trackId} module banner`}
                                        style={{ height: "400px" }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    ) : null}
                </Stack>
            </CardContent>
        </Card>
    );
};
