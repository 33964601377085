import { track } from "@ignite-analytics/track";
import { MenuItem, Select } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { contractListRoute } from "@/Router";
import { Status } from "@/types";
import { statusToSearchParams } from "@/helpers/statusToSearchParams";

const trackTab = (status: Status) => track("Contracts: Filter by status", { status });

const ContractStatusFilters = ({ hasRenewalDate }: { hasRenewalDate: boolean }) => {
    const months = 6;

    const navigate = contractListRoute.useNavigate();
    const { status } = contractListRoute.useSearch();

    const onClick = (newStatus: Status) => {
        trackTab(newStatus);
        navigate({ replace: true, search: (prev) => ({ ...prev, ...statusToSearchParams(newStatus) }) });
    };

    return (
        <Select value={status} size="small" sx={{ minWidth: "140px", margin: 0 }}>
            <MenuItem value="all" onClick={() => onClick("all")}>
                <FormattedMessage defaultMessage="All contracts" />
            </MenuItem>
            <MenuItem value="active" onClick={() => onClick("active")}>
                <FormattedMessage defaultMessage="Active contracts" />
            </MenuItem>
            {hasRenewalDate && (
                <MenuItem value="renewing-soon" onClick={() => onClick("renewing-soon")}>
                    <FormattedMessage defaultMessage="Renewing within {months} months" values={{ months }} />
                </MenuItem>
            )}
            <MenuItem value="expiring-soon" onClick={() => onClick("expiring-soon")}>
                <FormattedMessage defaultMessage="Expiring within {months} months" values={{ months }} />
            </MenuItem>
            <MenuItem value="expired" onClick={() => onClick("expired")}>
                <FormattedMessage defaultMessage="Expired contracts" />
            </MenuItem>
            <MenuItem value="upcoming" onClick={() => onClick("upcoming")}>
                <FormattedMessage defaultMessage="Upcoming contracts" />
            </MenuItem>
        </Select>
    );
};

export default ContractStatusFilters;
