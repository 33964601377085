import { Button, Dialog, DialogTitle, Stack, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { defineMessages, FormattedMessage } from "react-intl";

import { useNavigate } from "@tanstack/react-router";
import { X } from "@ignite-analytics/icons";
import { ExploreCard } from "./ExploreCard";
import WatchDemo from "./WatchDemo";
import { contractOverviewRoute } from "@/Router";
import LearnMoreContent from "./LearnMore";

const messages = defineMessages({
    contractsTitleChip: {
        id: "exploreModules.contracts.titleChip",
        defaultMessage: "Contract management",
    },
    contractsDescription: {
        id: "exploreModules.contracts.description.v2",
        defaultMessage:
            "Get an overview of all your contracts, track their status, and get notified before they expire. Share your contracts with the rest of your organization to ensure contract compliance and reduce costs.",
    },
    takeControlOfYour: {
        id: "exploreModules.contracts.takeControlOfYour.v2",
        defaultMessage: "Stay in control of your contracts",
    },
});

const contractsProps = {
    imageId: "8c93ca6e-4c5e-43a1-5e8d-537e16be1000",
    trackId: "contracts",
    name: messages.contractsTitleChip,
    description: messages.contractsDescription,
};

export const ExploreContracts = () => {
    const { show } = contractOverviewRoute.useSearch();
    const navigate = useNavigate();

    const smHeader: React.ReactNode = useMemo(
        () => (
            <Typography variant="displayMd" fontWeight={600}>
                <FormattedMessage {...messages.takeControlOfYour} />
            </Typography>
        ),
        []
    );
    return (
        <>
            <Dialog
                fullWidth
                maxWidth={show === "learn" ? "xs" : "lg"}
                open={show !== undefined}
                onClose={() => {
                    navigate({ search: { show: undefined } });
                }}
            >
                <DialogTitle>
                    <Stack justifyContent="flex-end" alignItems="center" direction="row">
                        <Button
                            onClick={() => {
                                navigate({ search: { show: undefined } });
                            }}
                            size="2xsmall"
                            color="ghostGray"
                            endIcon={<X fontSize="inherit" />}
                        >
                            <FormattedMessage defaultMessage="Close" />
                        </Button>
                    </Stack>
                </DialogTitle>
                {show === "demo" && <WatchDemo />}
                {show === "learn" && <LearnMoreContent />}
            </Dialog>
            <ExploreCard {...contractsProps} header={smHeader} />
        </>
    );
};
