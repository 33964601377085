import React from "react";
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { FileListItem } from "../FileListItem";
import { UploadFileInfo } from "../FileListItem/UploadFileInfo.model";
import { makeFileUploadMessages } from "./messages";

interface FilesListProps {
    files: UploadFileInfo[];
    onFileRemove?: (removedFile: UploadFileInfo) => void;
    disabled?: boolean;
    freeze?: boolean;
    onSwitch?: (id: string, checked: boolean) => void;
    hasEditPermission: boolean;
}

const FilesList: React.FC<FilesListProps> = ({
    files,
    onFileRemove,
    disabled,
    onSwitch,
    hasEditPermission,
    freeze,
}) => {
    const handleDeleteFile = (fileForDelete: UploadFileInfo) => {
        if (files && onFileRemove && !disabled) {
            onFileRemove(fileForDelete);
        }
    };

    const groupAndSortFiles = (unsortedFiles: UploadFileInfo[]) => {
        const sortedFiles = unsortedFiles.sort((a, b) => (a.name > b.name ? 1 : -1));
        const publicFiles = sortedFiles.filter((file) => file.isPublic);
        const restrictedFiles = sortedFiles.filter((file) => !file.isPublic);
        return [...publicFiles, ...restrictedFiles];
    };
    const filesData = groupAndSortFiles(files);
    const messages = makeFileUploadMessages();
    return (
        <Table style={{ border: "none", maxWidth: "100%", tableLayout: "fixed" }}>
            <TableHead style={{ border: "none" }}>
                <TableRow>
                    <TableCell style={{ border: "none" }}>
                        <Typography variant="h6">{messages.filesTableHeader}</Typography>
                    </TableCell>
                    {hasEditPermission && (
                        <TableCell align="right" style={{ border: "none", width: "10%" }}>
                            <Typography variant="subtitle1">{messages.isFilePublicHeader}</Typography>
                        </TableCell>
                    )}
                    <TableCell style={{ border: "none", width: "15%" }} />
                </TableRow>
            </TableHead>
            <TableBody>
                {filesData?.map((file: UploadFileInfo, index: number) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <TableRow key={file.name + index.toString()}>
                        <FileListItem
                            file={file}
                            onDeleteFile={handleDeleteFile}
                            disabled={disabled}
                            onSwitch={onSwitch}
                            editor={hasEditPermission}
                            freeze={freeze}
                        />
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default FilesList;
