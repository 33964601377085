import React from "react";
import { User } from "@/entities/users";
import { AvatarGroup } from "./AvatarGroup";
import { UserRow } from "./Row";

type Props = {
    users: User[];
    max?: number;
};

export const ContractResponsibleDisplay = ({ users, max = 5 }: Props) => {
    if (users.length === 1) {
        return <UserRow userId={users[0].id} />;
    }
    return <AvatarGroup displayTooltip users={users} max={max} />;
};
