import { defineMessages } from "react-intl";

const messages = defineMessages({
    emptyGroupMessage: {
        id: "dragAndDropBoard.emptyGroupMessage",
        description: "Drag and drop board empty group message",
        defaultMessage: "No items added yet, to add items drag and drop them to this section",
    },
    defaultNewGroupName: {
        id: "dragAndDropBoard.defaultNewGroupName",
        description: "Newly added group name",
        defaultMessage: "New section",
    },
    defaultOtherGroupName: {
        id: "dragAndDropBoard.defaultOtherGroupName",
        description: "Group name for remaining fields",
        defaultMessage: "Others",
    },
    addGroupButton: {
        id: "dragAndDropBoard.addGroupButton",
        description: "Add group button label",
        defaultMessage: "Add new section",
    },
    missingDataColumnName: {
        id: "dragAndDropBoard.dataColumn",
        description: "Column name when undefned",
        defaultMessage: "Missing name",
    },
});

export default messages;
