import { AutocompleteRenderGetTagProps } from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import React, { useMemo, useState } from "react";
import { fm } from "src/contexts/IntlContext";
import { useTableRelationSearch } from "src/hooks/useTableRelationSearch";
import DropDown from "src/components/ui/DropDown";
import { DropDownOptionItem } from "src/components/ui/DropDown/models/DropDownOptionItem";
import { TableRelationDataPopup } from "src/components/TableRelationDataPopup";
import { DataTable } from "@/generated/client";
import { TableRelationData } from "../tableRelationData";
import messages from "./messages";

interface TableRelationDropdownProps {
    value?: TableRelationData;
    dataTable: DataTable;
    fieldName: string;
    readOnly?: boolean;
    onSelect: (selectedId?: string) => void;
}

export const TableRelationDropdown: React.FC<TableRelationDropdownProps> = ({
    value,
    dataTable,
    readOnly,
    fieldName,
    onSelect,
}) => {
    const { search } = useTableRelationSearch(dataTable);
    const [options, setOptions] = useState<DropDownOptionItem[]>([]);
    const [popupOpen, setPopupOpen] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);

    const dropdownValue = useMemo(
        () =>
            value
                ? [
                      {
                          id: value.id,
                          label: value.title,
                      },
                  ]
                : [],
        [value]
    );

    const handleOnSelect = (selectedItems: DropDownOptionItem[]) => {
        if (selectedItems.length) {
            onSelect(selectedItems[0].id);
        } else {
            onSelect();
        }
    };

    const handleOnSearch = async (phrase: string) => {
        if (!phrase) {
            setOptions([]);
        } else {
            try {
                setLoading(true);
                const optionsList = await search(phrase);
                setOptions(optionsList);
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <>
            <DropDown
                label={fieldName}
                value={dropdownValue}
                options={options}
                readOnly={readOnly}
                loading={loading}
                onSelect={handleOnSelect}
                onSearch={handleOnSearch}
                renderTags={
                    readOnly
                        ? (values: DropDownOptionItem[], getTagProps: AutocompleteRenderGetTagProps) =>
                              values.map((option, index) => (
                                  <Chip
                                      {...getTagProps({ index })}
                                      key={option.id}
                                      label={option.label}
                                      data-testid="rel-chip"
                                      onClick={() => setPopupOpen(true)}
                                  />
                              ))
                        : undefined
                }
            />
            <TableRelationDataPopup
                fields={value?.fields || []}
                open={popupOpen}
                onClose={() => setPopupOpen(false)}
                title={value?.title || ""}
                titleHeaderLabel={fm(messages.titleHeaderLabel).toString()}
                valueHeaderLabel={fm(messages.valueHeaderLabel).toString()}
            />
        </>
    );
};
