import { defineMessages } from "react-intl";

const messages = defineMessages({
    loadingText: {
        id: "dropdown.loadingText",
        description: "Text displayed when data loading in dropdown",
        defaultMessage: "Loading...",
    },
    noOptionsText: {
        id: "dropdown.noOptionsText",
        description: "Text displayed when there is no options",
        defaultMessage: "No options",
    },
    emptyInputText: {
        id: "dropdown.emptyInputText",
        description: "Text displayed when input is empty",
        defaultMessage: "Type to search",
    },
});

export default messages;
