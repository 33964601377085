import { defineMessages } from "react-intl";

const messages = defineMessages({
    "global.admin": {
        defaultMessage: "Administrator",
        description: "Admin role",
    },
    "global.editor": {
        defaultMessage: "Editor",
        description: "Editor role",
    },
    "global.viewer": {
        defaultMessage: "Viewer",
        description: "Viewer role",
    },
    "global.contract-editor": {
        defaultMessage: "Contract Editor",
        description: "Contract Editor role",
    },
    "global.contract-viewer": {
        defaultMessage: "Contract Viewer",
        description: "Contract Viewer role",
    },
    "unknown": {
        defaultMessage: "Unknown role",
        description: "Unknown role",
    },
});

export default messages;

