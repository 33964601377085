import { gql, useQuery } from "@apollo/client";
import { isObject } from "lodash";
import { useMemo } from "react";
import { DataColumn, DataTable, useGetDataTableRowQuery } from "@/generated/client";
import { TableRelationData } from "../components/TableRelation Legacy/tableRelationData";

const getDataTableGQL = gql`
    query getDataTable($input: GetDataTableInput!) {
        getDataTable(input: $input) {
            entity {
                id
                elasticIndex
                nameColumnId
                name
            }
        }
    }
`;

const getDataColumnsGQL = gql`
    query getDataColumns($input: GetDataColumnsInput!) {
        getDataColumns(input: $input) {
            dataColumns {
                id
                name
                dataType
                dataTableId
                globalTypeKey
                referencedId
                isList
            }
        }
    }
`;

export function useTableRelation(tableId: string, rowId?: string) {
    const { data: dataTableResponseData } = useQuery<{
        getDataTable: {
            entity: DataTable;
        };
    }>(getDataTableGQL, {
        variables: {
            input: {
                id: tableId,
            },
        },
    });

    const { data: dataColumnsResponseData } = useQuery<{
        getDataColumns: {
            dataColumns: DataColumn[];
        };
    }>(getDataColumnsGQL, {
        variables: {
            input: {
                dataTableId: tableId,
            },
        },
    });

    const dataTable: DataTable | undefined = useMemo(
        () => dataTableResponseData?.getDataTable?.entity,
        [dataTableResponseData]
    );

    const dataColumns: DataColumn[] | undefined = useMemo(
        () => dataColumnsResponseData?.getDataColumns?.dataColumns,
        [dataColumnsResponseData]
    );

    const { result: getTableRowResult } = useGetDataTableRowQuery(
        {
            input: {
                dataTableId: tableId,
                id: rowId || "",
            },
        },
        {
            skip: !rowId || !dataTable?.nameColumnId,
        }
    );

    const tableRelationValue: TableRelationData | undefined = useMemo(() => {
        if (getTableRowResult.type === "success" && dataColumns) {
            const valueObject = JSON.parse(getTableRowResult.data.dataTableRow.dataJson);
            let title = "";
            if (isObject(valueObject[dataTable?.nameColumnId || ""])) {
                title = valueObject[dataTable?.nameColumnId || ""]?.id || "";
            } else {
                title = valueObject[dataTable?.nameColumnId || ""];
            }

            return {
                id: rowId || "",
                title,
                fields: (dataColumns || [])
                    .filter((dc) => dc.dataType !== "TABLE_RELATION" && dc.dataType !== "GROUP_STRUCTURE")
                    .map((dc) => ({
                        name: dc.name,
                        value: isObject(valueObject[dc.id]) ? "" : valueObject[dc.id],
                    })),
            };
        }
        return undefined;
    }, [getTableRowResult, dataTable, dataColumns, rowId]);

    return {
        tableRelationValue,
        dataTable,
    };
}
