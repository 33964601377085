import { defineMessages } from "react-intl";
import { fm } from "src/contexts/IntlContext";
import { defaultMessages } from "../ui/ContactDropdown/contacts";

const messages = defineMessages({
    title: {
        id: "contractResponsibles.title",
        description: "Contract responsible title",
        defaultMessage: "Contract responsible",
    },
    editLabel: {
        id: "contractResponsibles.editLabel",
        description: "Contract responsible edit label (near title)",
        defaultMessage: "Edit",
    },
});

export const makeMessages = (): typeof defaultMessages => ({
    editLabel: fm(messages.editLabel).toString(),
    title: fm(messages.title).toString(),
});
