import { Dialog, DialogTitle, DialogContent, DialogActions, Stack, Button, IconButton } from "@mui/material";
import React, { PropsWithChildren, useState } from "react";
import { X } from "@ignite-analytics/icons";
import { LoadingButton } from "@mui/lab";

type Props = {
    isOpen: boolean;
    onCancel: () => void;
    onSubmit?: () => Promise<void>;
    titleLabel: string;
    submitLabel?: string;
    cancelLabel?: string;
    disableSubmitButton?: boolean;
    hasCloseIcon?: boolean;
};

export const Popup = ({
    children,
    isOpen,
    onCancel,
    onSubmit,
    titleLabel,
    submitLabel,
    cancelLabel,
    disableSubmitButton,
    hasCloseIcon,
}: PropsWithChildren<Props>) => {
    const [loading, setLoading] = useState(false);

    return (
        <Dialog open={isOpen} sx={{ maxHeight: "90%" }} onClose={onCancel}>
            <DialogTitle>
                {titleLabel}
                {hasCloseIcon && (
                    <IconButton
                        aria-label="close"
                        onClick={onCancel}
                        sx={{
                            position: "absolute",
                            right: "0.5em",
                            top: "0.5em",
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <X />
                    </IconButton>
                )}
            </DialogTitle>
            <DialogContent sx={{ position: "relative", overflow: "hidden", display: "flex", flexDirection: "column" }}>
                {children}
            </DialogContent>
            <Stack>
                <DialogActions>
                    {cancelLabel && (
                        <Button variant="outlined" onClick={onCancel}>
                            {cancelLabel}
                        </Button>
                    )}
                    {onSubmit && (
                        <LoadingButton
                            variant="contained"
                            onClick={async () => {
                                setLoading(true);
                                await onSubmit();
                                setLoading(false);
                            }}
                            loading={loading}
                            disabled={disableSubmitButton}
                        >
                            {submitLabel}
                        </LoadingButton>
                    )}
                </DialogActions>
            </Stack>
        </Dialog>
    );
};
