import { defineMessages } from "react-intl";
import { fm } from "src/contexts/IntlContext";
import { defaultMessages } from "../ui/ContactDropdown/contacts";

const messages = defineMessages({
    title: {
        id: "supplierContact.Title",
        description: "Supplier Contact card title",
        defaultMessage: "Supplier contact",
    },
    editLabel: {
        id: "supplierContact.editLabel",
        description: "Supplier Contact edit label (near title)",
        defaultMessage: "Edit",
    },
});

export const makeMessages = (): typeof defaultMessages => ({
    editLabel: fm(messages.editLabel).toString(),
    title: fm(messages.title).toString(),
});
