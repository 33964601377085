import { ApolloProvider, createGraphqlClient } from "@ignite-analytics/graphql-utilities";
import { getDesignTokens } from "@ignite-analytics/theme";
import { ThemeProvider, createTheme } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LicenseInfo } from "@mui/x-license-pro";
import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { ErrorHandlerProvider } from "@/errorHandling/ErrorHandlerContext";
import { dayJsLocale } from "@/formatDate";
import { useOnUnhandledRejectionReporter } from "@/hooks/useOnUnhandledRejectionReporter";
import { MicroAppMountValues } from "@/types";
import { BasePathProvider } from "./BasePathContext";
import { CompanyCurrencyProvider } from "./CompanyCurrencyContext";
import { FeatureContext } from "./FeatureContext";
import { IntlContext } from "./IntlContext";
import { NotificationHandlerProvider } from "./NotificationHandlerContext";
import { PermissionHandlerProvider } from "./PermissionsContext";
import { SessionContextProvider } from "./SessionContext";
import { env } from "@/env";

export const apolloClient = createGraphqlClient(env.REACT_APP_GRAPHQL_ROUTER_URL);
export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 1,
            enabled: false,
            gcTime: 1000 * 60 * 15,
        },
    },
});

LicenseInfo.setLicenseKey(env.REACT_APP_MUI_TOKEN);

type Props = {
    children: React.ReactNode;
    sessionContext: MicroAppMountValues["sessionContext"];
    path: MicroAppMountValues["path"];
    theme: MicroAppMountValues["theme"] & string;
    locale: MicroAppMountValues["locale"] & string;
};

export const ContextProviders: React.FC<Props> = ({ locale, theme, path, sessionContext, children }) => {
    useOnUnhandledRejectionReporter();
    const { lang } = dayJsLocale();

    return (
        <NotificationHandlerProvider>
            <ErrorHandlerProvider>
                <ApolloProvider client={apolloClient}>
                    <QueryClientProvider client={queryClient}>
                        <BasePathProvider path={path ?? "/"}>
                            <ThemeProvider theme={createTheme(getDesignTokens(theme, "light"))}>
                                <IntlContext locale={locale}>
                                    <SessionContextProvider sessionContext={sessionContext}>
                                        <FeatureContext>
                                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={lang}>
                                                <CompanyCurrencyProvider>
                                                    <PermissionHandlerProvider enabled>
                                                        {children}
                                                    </PermissionHandlerProvider>
                                                </CompanyCurrencyProvider>
                                            </LocalizationProvider>
                                        </FeatureContext>
                                    </SessionContextProvider>
                                </IntlContext>
                            </ThemeProvider>
                        </BasePathProvider>
                    </QueryClientProvider>
                </ApolloProvider>
            </ErrorHandlerProvider>
        </NotificationHandlerProvider>
    );
};
