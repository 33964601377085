import { Alert, Snackbar } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";

export type NotificationContextType = {
    handleMessage: (message: string) => void;
};
export const NotificationHandlerContext = React.createContext<NotificationContextType | null>(null);

const NotificationHandlerProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState<string>();

    const handleMessage = useCallback(
        (messageText: string) => {
            setOpen(true);
            setMessage(messageText);
        },
        [setOpen, setMessage]
    );

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    const notificationContextTypeValue = useMemo(() => ({ handleMessage }), [handleMessage]);

    return (
        <NotificationHandlerContext.Provider value={notificationContextTypeValue}>
            {children}
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
                    {message}
                </Alert>
            </Snackbar>
        </NotificationHandlerContext.Provider>
    );
};
function useNotification(): NotificationContextType {
    const ctx = React.useContext(NotificationHandlerContext) as NotificationContextType;
    if (!ctx) {
        throw new Error("useNotificationHandler hook must be used within a NotificationHandlerProvider");
    }
    return ctx;
}
export { NotificationHandlerProvider, useNotification };
