import { useGetManyDataTablesQuery } from "@/generated/client";

export const useV2DataTableWithType = (typeKey: string) => {
    const { result: getManyDataTablesResult } = useGetManyDataTablesQuery({});
    if (getManyDataTablesResult.type === "success") {
        const v2Table = getManyDataTablesResult.data.entities.find((table) => table.globalTypeKey === typeKey);
        if (v2Table) {
            return { table: v2Table, loading: false };
        }
    }
    if (getManyDataTablesResult.type === "loading") {
        return { table: undefined, loading: true };
    }
    return { table: undefined, loading: false };
};
