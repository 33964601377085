import React from "react";
import { Button, ListItemIcon, MenuItem, Menu as MuiMenu, SxProps, Typography } from "@mui/material";
import { ChevronDown } from "@ignite-analytics/icons";
import { ConditionalTooltip } from "../ConditionalTooltip";

type Entry = {
    icon?: {
        component: React.ReactNode;
        pos: "start" | "end";
    };
    divider?: boolean;
    label: string;
    disabled?: boolean;
    disabledTooltipText?: string;
    onClick?: () => void;
    danger?: boolean;
};

type RiddleOnlyMenuProps = {
    anchorEl: HTMLElement | null;
    onClose: () => void;
    entries: Entry[];
    sx?: SxProps;
};

export const RiddleMenu = ({ onClose, anchorEl, entries, sx }: RiddleOnlyMenuProps) => {
    return (
        <MuiMenu
            slotProps={{
                paper: {
                    sx: {
                        maxHeight: "350px",
                    },
                },
            }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={anchorEl !== null}
            anchorEl={anchorEl}
            onClose={() => onClose()}
        >
            {entries.map((item) => (
                <ConditionalTooltip
                    placement="left"
                    title={item.disabledTooltipText ?? ""}
                    condition={item.disabled}
                    key={item.label}
                >
                    <MenuItem
                        disabled={item.disabled}
                        color={item.danger ? "error" : undefined}
                        sx={{
                            "& .MuiSvgIcon-root": { width: "16px", height: "16px" },
                            ...sx,
                        }}
                        divider={item.divider}
                        onClick={() => {
                            if (item.onClick) {
                                item.onClick();
                                onClose();
                            }
                        }}
                    >
                        {item.icon?.pos === "start" && <ListItemIcon>{item.icon.component}</ListItemIcon>}
                        <Typography variant="textSm">{item.label}</Typography>
                        {item.icon?.pos === "end" && <ListItemIcon>{item.icon.component}</ListItemIcon>}
                    </MenuItem>
                </ConditionalTooltip>
            ))}
        </MuiMenu>
    );
};

type RiddleMenuWithButtonProps = {
    buttonLabel: string;
    entries: Entry[];
} & React.ComponentProps<typeof Button>;

export const RiddleMenuWithButton = ({ entries, buttonLabel, ...props }: RiddleMenuWithButtonProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    return (
        <>
            <Button
                {...props}
                onClick={(e) => {
                    setAnchorEl(e.currentTarget);
                }}
                endIcon={<ChevronDown />}
            >
                {buttonLabel}
            </Button>
            <RiddleMenu entries={entries} anchorEl={anchorEl} onClose={() => setAnchorEl(null)} />
        </>
    );
};
