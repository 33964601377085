import { defineMessages } from "react-intl";

const messages = defineMessages({
    measureUnitPercentShort: {
        id: "lib.convert.measureUnitPercentShort",
        defaultMessage: "%",
    },
    measureUnitThousandShort: {
        id: "lib.convert.measureUnitThousandShort",
        defaultMessage: "K",
    },
    measureUnitMillionShort: {
        id: "lib.convert.measureUnitMillionShort",
        defaultMessage: "M",
    },
    measureUnitBillionShort: {
        id: "lib.convert.measureUnitBillionShort",
        defaultMessage: "B",
    },
    measureUnitPercentLong: {
        id: "lib.convert.measureUnitPercentLong",
        defaultMessage: "percent",
    },
    measureUnitThousandLong: {
        id: "lib.convert.measureUnitThousandLong",
        defaultMessage: "thousand",
    },
    measureUnitMillionLong: {
        id: "lib.convert.measureUnitMillionLong",
        defaultMessage: "million",
    },
    measureUnitBillionLong: {
        id: "lib.convert.measureUnitBillionLong",
        defaultMessage: "billion",
    },
});

export default messages;
