import dayjs, { Dayjs } from "dayjs";

const addLeadingZeros = (value: number) => {
    const zero = 2 - value.toString().length + 1;
    return Array(+(zero > 0 && zero)).join("0") + value.toString();
};

const parseDateToDateString = (dateObject: Date): string =>
    new Date(
        `${dateObject.getFullYear()}-${addLeadingZeros(dateObject.getMonth() + 1)}-${addLeadingZeros(
            dateObject.getDate()
        )}`
    ).toJSON();

const parseDateTimeStringToDateString = (dateTimeString: string): string => {
    const parsedDate = dayjs(dateTimeString);
    if (!parsedDate.isValid()) {
        throw new Error("Invalid date");
    }
    return parseDateToDateString(parsedDate.toDate());
};

export const parseDateTimeAnyToDateString = (dateTimeObject: Dayjs | Date | string | undefined): string | undefined => {
    if (!dateTimeObject) {
        return undefined;
    }
    if (dateTimeObject instanceof Date) {
        return parseDateToDateString(dateTimeObject);
    }
    if (dayjs.isDayjs(dateTimeObject)) {
        return parseDateToDateString(dateTimeObject.toDate());
    }
    return parseDateTimeStringToDateString(dateTimeObject);
};
