import { Plus } from "@ignite-analytics/icons";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { useMemo } from "react";
import { fm } from "src/contexts/IntlContext";
import { usePermissionHandler } from "src/contexts/PermissionsContext";
import messages from "./messages";
import { useSearchTags } from "../../hooks/useSearchTags";
import DropDown from "../ui/DropDown";
import { DropDownOptionItem } from "../ui/DropDown/models/DropDownOptionItem";

export interface TagsDropdownProps {
    value: string[] | null;
    dataColumnId: string;
    fieldLabel: string;
    multi?: boolean;
    debounceMs?: number;
    readonly?: boolean;
    onSelect: (selectedTags: string[]) => void;
}

const AddTagTemplateComponent = ({ label }: { label: string }) => (
    <Stack flexDirection="row" display="flex" alignItems="center">
        <Plus />
        <Typography variant="body1" sx={{ pl: 0.5, lineHeight: 2 }}>
            {fm(messages.newTagLabel)}:{label}
        </Typography>
    </Stack>
);

const TagsDropdown: React.FC<TagsDropdownProps> = ({
    value,
    dataColumnId,
    fieldLabel,
    multi,
    debounceMs,
    readonly,
    onSelect,
}) => {
    const { tags, loading, addTag, search } = useSearchTags(dataColumnId);

    const tagsOptions: DropDownOptionItem[] = useMemo(
        () => tags.map((tagString) => ({ id: tagString, label: tagString })),
        [tags]
    );

    const tagValues: DropDownOptionItem[] = useMemo(
        () => value?.map((tag) => ({ id: tag, label: tag })) ?? [],
        [value]
    );

    const handleOnTagAdd = (newTagText: string) => {
        addTag(newTagText);
        onSelect([...(value ?? []), newTagText]);
    };

    const handleOnSelect = (selectedItems: DropDownOptionItem[]) => {
        onSelect(selectedItems.map((i) => i.label));
    };

    const { checkPermissions } = usePermissionHandler();

    const isAddDisabled = checkPermissions("contracts-tags", "write") === false;

    return (
        <DropDown
            value={tagValues}
            label={fieldLabel}
            onSearch={search}
            options={tagsOptions}
            loading={loading}
            debounceMs={debounceMs}
            multi={multi}
            readOnly={readonly}
            onSelect={handleOnSelect}
            addItemTemplateCompoment={AddTagTemplateComponent}
            onAdd={handleOnTagAdd}
            isAddDisabled={isAddDisabled}
        />
    );
};

export default TagsDropdown;
