import { gql, useLazyQuery } from "@apollo/client";
import { useState } from "react";

const getSuppliersQuery = gql`
    query getSuppliers($input: GetSuppliersInput!) {
        getSuppliers(input: $input) {
            suppliers {
                id
                name
                country
                orgNumber
            }
            total
        }
    }
`;

export function useSearchSupplier(): [(phrase: string) => Promise<any>, boolean, string[] | undefined] {
    const [getSuppliersLazyQuery] = useLazyQuery<{ getSuppliers: { suppliers: any } }>(getSuppliersQuery);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<string[] | undefined>(undefined);

    const searchSuppliers = (phrase: string) => {
        setLoading(true);
        return getSuppliersLazyQuery({
            variables: {
                input: {
                    fallbackToOldDMS: false,
                    nameSearch: phrase,
                    pageIndex: 0,
                    pageRange: 100,
                },
            },
        })
            .then((result) => {
                if (result.error) {
                    const queryErrors = result.error?.graphQLErrors
                        .map((e) => e.message)
                        .concat(result.error?.clientErrors.map((e) => e.message));
                    setErrors(queryErrors);
                }
                return result.data?.getSuppliers.suppliers;
            })
            .finally(() => setLoading(false));
    };

    return [searchSuppliers, loading, errors];
}
