import "dayjs/locale/en-gb";
import "dayjs/locale/nb";
import "dayjs/locale/de";
import "dayjs/locale/en";
import { getRegion, getLocale } from "@ignite-analytics/locale";

type Lang = "en" | "en-gb" | "de" | "nb";
type Locale = {
    lang: Lang;
    dateFormat: string;
};

const isoTodaysJsLang: Map<string, Lang> = new Map([
    ["de-DE", "de"],
    ["nb-NO", "nb"],
    ["en-US", "en"],
]);

const isoToDateFormat = new Map([
    ["de-DE", "DD.MM.YYYY"],
    ["nb-NO", "DD.MM.YYYY"],
    ["en-US", "MM/DD/YYYY"],
]);

export const dayJsLocale = (): Locale => {
    const lang = getLocale() ?? "en-US";
    const region = getRegion() ?? "en-US";
    return {
        lang: isoTodaysJsLang.get(lang) ?? "en",
        dateFormat: isoToDateFormat.get(region) ?? "DD/MM/YYYY",
    };
};
