import { gql, useMutation } from "@apollo/client";

const addSupplierQuery = gql`
    mutation addSupplier($input: CreateSupplierInput!) {
        createSupplier(input: $input) {
            supplier {
                id
            }
        }
    }
`;

interface CreateSupplierResponse {
    supplier: {
        id: string;
    };
}

export function useCreateSupplier() {
    const [createMutation] = useMutation<{ createSupplier: CreateSupplierResponse }>(addSupplierQuery);

    const create = async (name: string) => {
        const response = await createMutation({
            variables: { input: { name, customFields: [] } },
        });
        return response.data?.createSupplier.supplier.id;
    };

    return { create };
}
