import { Button, Link, Stack, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";

const WarningExclamationMark = () => (
    <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="12" y="12" width="64" height="64" rx="32" fill="#FEF9EA" />
        <rect x="1.5" y="1.5" width="85" height="85" rx="42.5" stroke="#FCB823" strokeOpacity="0.16" strokeWidth="3" />
        <rect x="7.5" y="7.5" width="73" height="73" rx="35.5" stroke="#FCB823" strokeOpacity="0.32" strokeWidth="3" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M47.5362 32.4173C46.0286 29.6051 41.9966 29.6036 40.4869 32.4148L30.2692 51.4413C28.8381 54.1061 30.7684 57.3338 33.7932 57.3338H54.211C57.2344 57.3338 59.1649 54.1086 57.7363 51.4439L47.5362 32.4173ZM45.3332 40C45.3332 39.2636 44.7362 38.6667 43.9998 38.6667C43.2635 38.6667 42.6665 39.2636 42.6665 40V45.3333C42.6665 46.0697 43.2635 46.6667 43.9998 46.6667C44.7362 46.6667 45.3332 46.0697 45.3332 45.3333V40ZM43.9998 49.3333C43.2635 49.3333 42.6665 49.9303 42.6665 50.6667C42.6665 51.403 43.2635 52 43.9998 52H44.0132C44.7496 52 45.3465 51.403 45.3465 50.6667C45.3465 49.9303 44.7496 49.3333 44.0132 49.3333H43.9998Z"
            fill="#F6970A"
        />
    </svg>
);

export const ContractsNotConfiguredErrorComponent = () => {
    const configurationUrl = "/data/tables/";
    const helpCenterArticleUrl =
        "https://help-eu.igniteprocurement.com/en/articles/71272-contract-data-setup-and-upload";

    function redirectToConfiguration() {
        window.location.href = configurationUrl;
    }

    return (
        <Stack gap={3} alignItems="center" justifyContent="center">
            {/* Shape icon */}
            <WarningExclamationMark />

            {/* Content */}
            <Stack gap={4} alignItems="center" justifyContent="center">
                {/* Title with support text */}
                <Typography variant="textXl">
                    <FormattedMessage defaultMessage="You haven’t set up the data fields to be used for contracts" />
                </Typography>
                <Typography variant="textMd">
                    <FormattedMessage defaultMessage="In order for us to show you these insights in a proper way, tell us which source and fields you want us to use." />
                </Typography>

                {/* Button group */}
                <Button color="secondary" size="medium" onClick={() => redirectToConfiguration()}>
                    <FormattedMessage defaultMessage="Configure data fields" />
                </Button>
            </Stack>

            {/* Footer */}
            <Typography fontWeight={400} variant="textMd">
                <FormattedMessage defaultMessage="Not sure where to start?" />
                <Link sx={{ pl: 0.5 }} href={helpCenterArticleUrl} variant="body1" target="_blank">
                    <FormattedMessage defaultMessage="Help Center" />
                </Link>
            </Typography>
        </Stack>
    );
};
