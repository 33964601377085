import { FeatureToggleContextProvider } from "@ignite-analytics/feature-toggle";
import { ReactNode } from "react";
import { useSessionContext } from "./SessionContext";

interface Props {
    children: ReactNode;
}

export const FeatureContext = ({ children }: Props) => {
    const { email, tenant } = useSessionContext();
    return (
        <FeatureToggleContextProvider tenant={tenant} userEmail={email}>
            {children}
        </FeatureToggleContextProvider>
    );
};
