import { AxiosError } from "axios";
import React, { ReactElement } from "react";
import { Link } from "@mui/material";
import { fm } from "src/contexts/IntlContext";
import * as Sentry from "@sentry/react";
import type { ScopeContext } from "@sentry/types";
import messages from "./messages";

type ErrorCodes = "invalid-data-table-configuration";

export type Explanation = string | ReactElement;

const findErrorMessage = (error: any): string | undefined => {
    if (error.response?.data?.message) {
        return error.response?.data?.message;
    }
    if (error.message) {
        return error.message;
    }
    return undefined;
};

// just a wrapper around Sentry.captureException, with contracts-app tag set as default
export function captureMessage(message: string, ctx?: Partial<ScopeContext>) {
    if (ctx) {
        ctx.tags = { ...ctx.tags, app: "contracts-app" };
    } else {
        // eslint-disable-next-line no-param-reassign
        ctx = { tags: { app: "contracts-app" } };
    }
    Sentry.captureMessage(message, ctx);
}

export const httpErrorToExplanation = (error: AxiosError): Explanation => {
    const code: ErrorCodes | undefined = error.response?.data?.errorCode;
    const explanation = (() => {
        if (code === "invalid-data-table-configuration")
            return (
                (
                    <p>
                        {findErrorMessage(error)}
                        <br />
                        <Link
                            sx={{ textDecoration: "underline" }}
                            href="https://help.igniteprocurement.com/en/articles/7156073-contract-data-setup-and-upload"
                            target="_blank"
                        >
                            Read help center article
                        </Link>
                    </p>
                ) ?? fm(messages.invalidDataTableConfig).toString()
            );

        return findErrorMessage(error) ?? "Unknown error";
    })();
    return explanation;
};
