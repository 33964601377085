import { defineMessages } from "react-intl";
import { fm } from "src/contexts/IntlContext";

export const messages = defineMessages({
    filesTableHeader: {
        id: "fileUploadComponent.filesTableHeader",
        description: "Files table header text",
        defaultMessage: "Files",
    },
    isFilePublicHeader: {
        id: "fileUploadComponent.isFilePublicHeader",
        description: "Is file public header text",
        defaultMessage: "Public",
    },
    noFilesAttached: {
        id: "fileUploadComponent.noFilesAttached",
        description: "Label displayed when no files are attached",
        defaultMessage: "No files attached",
    },
});

export const makeFileUploadMessages = () => ({
    filesTableHeader: fm(messages.filesTableHeader).toString(),
    isFilePublicHeader: fm(messages.isFilePublicHeader).toString(),
});
