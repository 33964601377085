import { TextField } from "@mui/material";
import React from "react";
import { ComputeInputProps } from "src/components/readOnlyStypeComputer";

interface NumberInputRendererProps {
    id: string;
    label: string;
    onChange: (value: number | undefined) => void;
    value?: number;
    readOnly: boolean;
}

export const NumberInputRenderer: React.FC<NumberInputRendererProps> = ({ id, label, value, onChange, readOnly }) => {
    const ParseToNumberOrUndefined = (val: string): number | undefined => {
        const parsedValue = Number.parseFloat(val);
        if (Number.isNaN(parsedValue)) {
            return undefined;
        }
        return parsedValue;
    };

    return (
        <TextField
            id={`number_input_renderer_${id}`}
            label={label}
            type="number"
            variant={readOnly ? "standard" : "outlined"}
            fullWidth
            value={value ?? ""}
            onChange={(newValue) => {
                onChange(ParseToNumberOrUndefined(newValue.target.value));
            }}
            InputProps={ComputeInputProps(readOnly)}
        />
    );
};
