import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { useMemo } from "react";
import { useUserSearch } from "src/entities/users";
import { AutocompleteRenderGetTagProps } from "@mui/material/Autocomplete";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import DropDown from "../ui/DropDown";
import { DropDownOptionItem } from "../ui/DropDown/models/DropDownOptionItem";
import { DEFAULT_DEBOUNCE_MS } from "../ui/DropDown/models/DropdownSettings";

export interface UserOptionItem extends DropDownOptionItem {
    email: string;
}

const UserOption: React.FC<UserOptionItem> = ({ label, email }) => (
    <Stack>
        <Typography variant="body1">{label}</Typography>
        <Stack>
            <Typography>{email}</Typography>
        </Stack>
    </Stack>
);

export interface UserDropDownProps {
    value?: UserOptionItem[];
    fieldLabel: string;
    debounceMs?: number;
    multi?: boolean;
    readonly?: boolean;
    onSelect: (selectedUsersIds: UserOptionItem[]) => void;
}

const UserDropDown: React.FC<UserDropDownProps> = ({
    value,
    fieldLabel,
    debounceMs,
    multi = false,
    readonly,
    onSelect,
}) => {
    const { users, setSearchTerm, loading } = useUserSearch();

    const usersOptions: UserOptionItem[] = useMemo(
        () => users.map((u) => ({ id: u.id, label: `${u.firstName} ${u.lastName}`, email: u.email })),
        [users]
    );

    const handleOnSearch = (searchPhrase: string) => {
        setSearchTerm(searchPhrase);
    };

    const handleOnSelect = (options: DropDownOptionItem[]) => {
        onSelect(options as UserOptionItem[]);
    };

    return (
        <DropDown<UserOptionItem>
            multi={multi}
            label={fieldLabel}
            value={value}
            options={usersOptions}
            loading={loading}
            onSearch={handleOnSearch}
            onSelect={handleOnSelect}
            debounceMs={debounceMs || DEFAULT_DEBOUNCE_MS}
            optionTemplateComponent={UserOption}
            readOnly={readonly}
            limitTags={1}
            renderTags={(values: UserOptionItem[], getTagProps: AutocompleteRenderGetTagProps) =>
                values.map((option, index) => (
                    <Tooltip title={option.email} key={option.id}>
                        <Chip {...getTagProps({ index })} label={option.label} data-testid="user-chip" />
                    </Tooltip>
                ))
            }
        />
    );
};

export default UserDropDown;
