import React from "react";

const Context = React.createContext<string | null>(null);

type Props = {
    path: string;
    children?: React.ReactNode;
};
const BasePathProvider: React.FC<Props> = ({ path, children }) => (
    <Context.Provider value={path}>{children}</Context.Provider>
);

/*
 * Saved for later in case we need it
function useBasePath(): string {
    const context = useContext(Context);
    if (!context) {
        throw new Error("useBasePath hook must be used within a BasePathProvider");
    }
    return context;
}
*/

export { BasePathProvider };
