import { Stack, Checkbox, TextField, Typography, Autocomplete, AutocompleteRenderGetTagProps } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import debounce from "lodash/debounce";
import isString from "lodash/isString";
import React, { useCallback, useMemo, useState } from "react";
import { fm } from "src/contexts/IntlContext";
import { ComputeTextFieldProps } from "src/components/readOnlyStypeComputer";
import messages from "./messages";
import { DropDownOptionItem } from "./models/DropDownOptionItem";
import { MAX_DEBOUNCE_WAIT } from "./models/DropdownSettings";

interface DropDownOptionItemWithNewLabel extends DropDownOptionItem {
    isNew?: boolean;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export interface DropDownProps<T extends DropDownOptionItem> {
    value?: T[];
    multi?: boolean;
    readOnly?: boolean;
    limitTags?: number;
    label: string;
    options: T[];
    loading?: boolean;
    debounceMs?: number;
    optionTemplateComponent?: React.FC<T>;
    addItemTemplateCompoment?: React.FC<T>;
    renderTags?: (values: T[], getTagProps: AutocompleteRenderGetTagProps, ownerState?: unknown) => React.ReactNode;
    onSearch: (phrase: string) => void;
    onOpen?: VoidFunction;
    onClose?: VoidFunction;
    onAdd?: (newLabel: string) => void;
    onSelect: (selected: T[]) => void;
    isAddDisabled?: boolean;
    closeOnSelect?: boolean;
    marginTop?: number;
}

const DefaultOptionTemplate = ({ label: optionLabel }: { label: string }) => (
    <Stack>
        <Typography variant="body1">{optionLabel}</Typography>
    </Stack>
);

const DropDown = <T extends DropDownOptionItem>({
    value,
    label,
    options,
    loading,
    debounceMs = 100,
    optionTemplateComponent,
    addItemTemplateCompoment,
    multi = false,
    readOnly,
    limitTags,
    renderTags,
    onSelect,
    onOpen,
    onClose,
    onSearch,
    onAdd,
    isAddDisabled = false,
    closeOnSelect = false,
    marginTop,
}: DropDownProps<T>) => {
    const [searchPhrase, setSearchPhrase] = useState<string | undefined>();

    const isNewOptionEnabled = useMemo(() => {
        if (isAddDisabled) return false;
        const isNew = !!addItemTemplateCompoment && !!searchPhrase && !loading && !!onAdd;
        return isNew;
    }, [addItemTemplateCompoment, searchPhrase, loading, onAdd, isAddDisabled]);

    const optionsToDisplay = useMemo(() => {
        const optionsToReturn =
            isNewOptionEnabled && options.filter((o) => o.label === searchPhrase).length === 0
                ? [
                      ...options,
                      {
                          label: searchPhrase || "",
                          id: "0",
                          isNew: true,
                      } as DropDownOptionItemWithNewLabel,
                  ]
                : options;
        return optionsToReturn;
    }, [options, isNewOptionEnabled, searchPhrase]);

    const handleOnInputChange = React.useMemo(
        () =>
            debounce(
                (val) => {
                    onSearch(val);
                },
                debounceMs,
                { maxWait: MAX_DEBOUNCE_WAIT }
            ),
        [onSearch, debounceMs]
    );

    const handleOnSelect = useCallback(
        (selected: T[]) => {
            const onlyObjectsSelected = selected.filter((option) => !isString(option));

            if (!multi) {
                onSelect(onlyObjectsSelected.slice(-1));
            } else {
                onSelect(onlyObjectsSelected);
            }
        },
        [onSelect, multi]
    );

    const handleOnOpen = () => {
        if (!multi && value?.length === 1 && options.length === 0) {
            onSearch(value[0].label);
        }
        onOpen?.();
    };

    const handleOnSelectChange = useCallback(
        (_: unknown, selectedValues: (T | string | DropDownOptionItemWithNewLabel)[]) => {
            const newValue = selectedValues.find(
                (o) => (isString(o) && o === searchPhrase) || (o as DropDownOptionItemWithNewLabel)?.isNew
            );

            if (newValue && !!onAdd && isNewOptionEnabled) {
                if (
                    (isString(newValue) && !options.find((o) => o.label === newValue)) ||
                    (newValue as DropDownOptionItemWithNewLabel)?.isNew
                ) {
                    onAdd(searchPhrase || "");
                }
            } else {
                handleOnSelect(selectedValues as T[]);
            }
        },
        [isNewOptionEnabled, onAdd, handleOnSelect, options, searchPhrase]
    );
    const optionLabel = (option: DropDownOptionItem | string) => (isString(option) ? option : option?.label);
    return (
        <Autocomplete
            freeSolo
            multiple
            value={value}
            options={optionsToDisplay}
            disableCloseOnSelect={!closeOnSelect}
            onOpen={handleOnOpen}
            onClose={onClose}
            onInputChange={(_, phrase) => {
                setSearchPhrase(phrase);
                handleOnInputChange(phrase);
            }}
            onChange={handleOnSelectChange}
            loading={loading}
            loadingText={fm(messages.loadingText).toString()}
            noOptionsText={
                !value || value.length === 0
                    ? fm(messages.emptyInputText).toString()
                    : fm(messages.noOptionsText).toString()
            }
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    {isNewOptionEnabled &&
                    addItemTemplateCompoment &&
                    (option as DropDownOptionItemWithNewLabel).isNew ? (
                        React.createElement(addItemTemplateCompoment, option as T)
                    ) : (
                        <>
                            {multi && (
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                            )}
                            {React.createElement(optionTemplateComponent || DefaultOptionTemplate, option as T)}
                        </>
                    )}
                </li>
            )}
            renderInput={(params) => (
                <TextField
                    {...ComputeTextFieldProps(readOnly ?? false, params)}
                    placeholder={!readOnly ? fm(messages.emptyInputText).toString() : ""}
                    label={label}
                    variant={readOnly ? "standard" : "outlined"}
                />
            )}
            getOptionLabel={optionLabel}
            forcePopupIcon={false}
            disableClearable
            isOptionEqualToValue={(option, val) => optionLabel(option) === optionLabel(val)}
            readOnly={readOnly}
            limitTags={limitTags}
            renderTags={renderTags as any}
            sx={{ width: "100%", mt: marginTop }}
        />
    );
};

export default DropDown;
