import axios from "axios";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as Sentry from "@sentry/react";
import { PermissionMode, PermissionType, PermissionsMap } from "./permissionTypes";
import { env } from "@/env";

interface PermissionHandlerProviderProps {
    children?: React.ReactNode;
    enabled: boolean;
}

interface PurePermissionHandlerProviderProps {
    children?: React.ReactNode;
    permissions: PermissionsMap;
}

type PermissionsContextType = {
    checkPermissions: (type: PermissionType, mode: PermissionMode) => boolean;
};

const PermissionHandlerContext = React.createContext<PermissionsContextType | null>(null);

const cartesianProduct = (...arrs: unknown[][]) =>
    arrs.reduce((acc, el) => acc.flatMap((innerEl) => el.map((e) => [innerEl, e].flat())));

const buildPermissionObjectsRequest = (): { object: PermissionType, relation: PermissionMode }[] => {
    const objects: PermissionType[] = ["general", "contracts-tags", "contracts-layouts-config", "attachments", "contracts-notes"];
    const relations: PermissionMode[] = ["read", "write", "create"];
    return cartesianProduct(objects, relations).map((pair: any) => ({ object: pair[0], relation: pair[1] }));
};

const mapToPermissionModes = (read: boolean, write: boolean, create: boolean) => {
    const result: PermissionMode[] = [];
    if (read)
        // eslint-disable-next-line fp/no-mutating-methods
        result.push("read");
    if (write)
        // eslint-disable-next-line fp/no-mutating-methods
        result.push("write");
    if (create)
        // eslint-disable-next-line fp/no-mutating-methods
        result.push("create");
    return result;
};

const PurePermissionHandlerProvider: React.FC<PurePermissionHandlerProviderProps> = ({ children, permissions }) => {
    const checkPermissions = useCallback(
        (type: PermissionType, mode: PermissionMode) => permissions.get(type)?.includes(mode) ?? false,
        [permissions]);

    const notificationContextTypeValue = useMemo(() => ({ checkPermissions }), [checkPermissions]);

    return <PermissionHandlerContext.Provider value={notificationContextTypeValue}>{children}</PermissionHandlerContext.Provider>;
};

const PermissionHandlerProvider: React.FC<PermissionHandlerProviderProps> = ({ children, enabled }) => {
    const [permissions, setPermissions] = useState<PermissionsMap>(new Map<PermissionType, PermissionMode[]>([]));
    const checkPermissions = useCallback(async () => {
        const permissionObjects = buildPermissionObjectsRequest();
        const data = await axios
            .post<boolean[]>(
                `${env.REACT_APP_AUTH_SIDECAR_API_URL}/api/v1/permissions/check/contracts`,
                permissionObjects,
                { withCredentials: true }
            )
            .then((response) => response.data)
            .catch((e) => {
                Sentry.captureException(e, {
                    tags: { app: "contracts-app" },
                });
                return Array(permissionObjects.length).fill(false);
            });
        return new Map<PermissionType, PermissionMode[]>([
            ["general", mapToPermissionModes(data[0], data[1], data[2])],
            ["contracts-tags", mapToPermissionModes(data[3], data[4], data[5])],
            ["contracts-layouts-config", mapToPermissionModes(data[6], data[7], data[8])],
            ["attachments", mapToPermissionModes(data[9], data[10], data[11])],
            ["contracts-notes", mapToPermissionModes(data[12], data[13], data[14])],
        ]);
    }, []);

    const handlePermissions = useCallback(async () => {
        enabled
            ? await checkPermissions().then((res) => setPermissions(res))
            : setPermissions(
                new Map<PermissionType, PermissionMode[]>([
                    ["general", ["read", "write", "create"]],
                    ["contracts-tags", ["read", "write", "create"]],
                    ["contracts-layouts-config", ["read", "write", "create"]],
                    ["attachments", ["read", "write", "create"]],
                    ["contracts-notes", ["read", "write", "create"]],
                ])
            );
    }, [enabled, setPermissions, checkPermissions]);

    useEffect(() => {
        handlePermissions();
    }, [handlePermissions]);

    return <PurePermissionHandlerProvider permissions={permissions}>{children}</PurePermissionHandlerProvider>;
};

function usePermissionHandler(): PermissionsContextType {
    const ctx = React.useContext(PermissionHandlerContext) as PermissionsContextType;
    if (!ctx) throw new Error("usePermissionHandler hook must be used within a PermissionHandlerProvider");
    return ctx;
}

export { PermissionHandlerProvider, PurePermissionHandlerProvider, usePermissionHandler };
