import React from "react";
import { Chip, CircularProgress, Divider, Stack, Typography } from "@mui/material";
import { ChevronRight } from "@ignite-analytics/icons";
import { Leaf } from "../../../hooks/useCategories";
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, Radio } from "./accordion";

interface Props {
    nodes: Leaf[];
    selectedNodes: Leaf[];
    multiselect?: boolean;
    nodeIdBeingFetched: string | undefined;
    onDelete: (id: string) => void;
    onNodeExpanded: (expandedId: string) => void;
    onSelected: (nodes: Leaf) => void;
}

const computeIcon = (node: Leaf, nodeIdBeingFetched?: string) => {
    if (node.id === nodeIdBeingFetched) return <CircularProgress size="1rem" />;
    if (node.hasChildren) return <ChevronRight sx={{ fontSize: "1rem" }} />;
    return undefined;
};

const renderNodes = (
    nodes: Leaf[],
    selectedIds: string[],
    onChange: (id: string) => (_e: React.SyntheticEvent, isExpanded: boolean) => void,
    onSelected: (node: Leaf) => void,
    onDelete: (id: string) => void,
    multiselect: boolean,
    nodeIdBeingFetched?: string
) =>
    nodes.map((node) => (
        <Accordion key={`${node.id}`} {...(node.hasChildren && { onChange: onChange(node.id) })}>
            <AccordionSummary expandIcon={computeIcon(node, nodeIdBeingFetched)}>
                <Typography variant="body2">{`${node.name} ${node.id === nodeIdBeingFetched ? "..." : ""}`}</Typography>
                {multiselect ? (
                    <Checkbox
                        checked={selectedIds.includes(node.id)}
                        onChange={(_e, checked) => (checked ? onSelected(node) : onDelete(node.id))}
                    />
                ) : (
                    <Radio checked={selectedIds.includes(node.id)} onChange={() => onSelected(node)} />
                )}
            </AccordionSummary>
            {node.hasChildren && (
                <AccordionDetails>
                    {node.children &&
                        renderNodes(
                            node.children,
                            selectedIds,
                            onChange,
                            onSelected,
                            onDelete,
                            multiselect,
                            nodeIdBeingFetched
                        )}
                </AccordionDetails>
            )}
        </Accordion>
    ));

export const CategoryTree = ({
    nodes,
    multiselect,
    selectedNodes,
    nodeIdBeingFetched,
    onNodeExpanded,
    onSelected,
    onDelete,
}: Props) => {
    const onExpanded = (id: string) => (_e: React.SyntheticEvent, isExpanded: boolean) =>
        isExpanded && onNodeExpanded(id);

    return (
        <Stack
            sx={{
                maxHeight: "100%",
                position: "relative",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <Stack style={{ overflowY: "scroll", overflowX: "hidden", flex: 1 }}>
                {renderNodes(
                    nodes,
                    selectedNodes.map((n) => n.id),
                    onExpanded,
                    onSelected,
                    onDelete,
                    multiselect ?? false,
                    nodeIdBeingFetched
                )}
            </Stack>
            <Divider sx={{ m: 1 }} />
            <Stack direction="row" flexWrap="wrap" spacing={1} rowGap={1}>
                {selectedNodes?.map((node) => (
                    <Chip label={node.name} key={node.id} size="small" onDelete={() => onDelete(node.id)} />
                ))}
            </Stack>
        </Stack>
    );
};
