import { gql, useMutation, useQuery } from "@apollo/client";
import { useCallback, useMemo, useState } from "react";

const getTagsGQL = gql`
    query getTagValuesByColumnId($input: GetTagValuesByColumnIdInput!) {
        getTagValuesByColumnId(input: $input) {
            tagValues {
                value
            }
        }
    }
`;

const addTagGQL = gql`
    mutation addTagValueToColumn($input: AddTagValueToColumnInput!) {
        addTagValueToColumn(input: $input) {
            tag {
                id
            }
        }
    }
`;

interface SearchTagHook {
    loading: boolean;
    tags: string[];
    error: unknown;
    search: (phrase: string) => void;
    addTag: (newTagName: string) => void;
}

const  uniqueFilter = (value: string, index: number, self: string[]) => self.indexOf(value) === index;

export const useSearchTags = (dataColumnId: string): SearchTagHook => {
    const [searchPhrase, setSearchPhrase] = useState<string>("");
    const [addedTags, setAddedTags] = useState<string[]>([]);

    const { data, loading, error, refetch } = useQuery<{
        getTagValuesByColumnId: {
            tagValues: {
                value: string;
            }[];
        };
    }>(getTagsGQL, {
        variables: {
            input: {
                dataColumnId,
            },
        },
    });

    const [addTag] = useMutation<{
        addTagValueToColumn: {
            tag: {
                id: string;
            };
        };
    }>(addTagGQL);

    const handleTagAdd = useCallback(
        async (newTagName: string) => {
            await addTag({
                variables: {
                    input: {
                        dataColumnId,
                        tagValue: newTagName,
                    },
                },
            });
            setAddedTags([...addedTags, newTagName]);
            await refetch();
        },
        [addTag, setAddedTags, dataColumnId, addedTags, refetch]
    );

    const originTags = useMemo(() =>{
        const existingTags = data ? data.getTagValuesByColumnId.tagValues.map((t) => t.value) : [];
        const existingAndAddedTags = addedTags.concat(existingTags);
        return existingAndAddedTags.filter(uniqueFilter);
    }, [data, addedTags]);

    const filteredTags = useMemo(() => {
        const searchPhraseLowercased = searchPhrase.toLowerCase();
        const res = [...originTags].filter((t: string) =>
            searchPhrase ? t.toLowerCase().includes(searchPhraseLowercased) : true
        );
        return res;
    }, [originTags, searchPhrase]);

    return { tags: filteredTags, loading, error, search: setSearchPhrase, addTag: handleTagAdd };
};
