import { UploadFileInfo } from "../FileListItem/UploadFileInfo.model";
import { UploadFileStatus } from "../FileListItem/UploadFileStatus.enum";

type OnFileSelect = (files: UploadFileInfo[]) => void;

type UploadFile = (
    selectedFiles: FileList | null,
    acceptedTypes: string[] | undefined,
    onFileSelect: OnFileSelect | undefined
) => void;

export const uploadFile: UploadFile = (selectedFiles, acceptedTypes, onFilesSelect) => {
    if (selectedFiles) {
        const selectedFilesInfo = Array.from(selectedFiles).map((selectedFile) => {
            const fileName = selectedFile.name;
            // eslint-disable-next-line fp/no-mutating-methods
            const fileType = fileName.split(".").pop();

            const hasValidType = acceptedTypes?.length ? acceptedTypes.includes(`.${fileType}`) : true;

            return {
                id: fileName + new Date().valueOf().toString(),
                name: fileName,
                file: selectedFile,
                status: hasValidType ? UploadFileStatus.Selected : UploadFileStatus.ValidationFailed,
                message: hasValidType ? undefined : "Invalid file type",
            };
        });

        onFilesSelect?.(selectedFilesInfo);
    }
};
