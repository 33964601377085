import { defineMessages } from "react-intl";
import { fm } from "src/contexts/IntlContext";

const messages = defineMessages({
    restrictedFileTooltip: {
        id: "components.fileUpload.fileListItem.restrictedFileTooltip",
        defaultMessage: "Restricted file",
        description: "Tooltip for restricted file",
    },
});
export const makeMessages = () => ({
    restrictedFileTooltip: fm(messages.restrictedFileTooltip).toString(),
});
