import { gql, useQuery } from "@apollo/client";
import { useCallback, useMemo } from "react";
import { Company } from "src/components/CompanyDropdown/company";

const getCompanyById = gql`
    query getCompany($input: GetCompanyInput!) {
        getCompany(input: $input) {
            company {
                id
                country
                name
                orgNumber
            }
        }
    }
`;

export function useGetCompany(companyId?: string) {
    const { data, loading, refetch, error } = useQuery<{ getCompany: { company: Company } }>(getCompanyById, {
        skip: !companyId,
        variables: {
            input: {
                id: companyId,
                enrichmentPrecedence: ["ENIN_ENTITY_INSIGHTS"],
            },
        },
    });

    const company = useMemo(() => {
        if (data?.getCompany?.company) {
            return data.getCompany.company;
        }
        return undefined;
    }, [data]);

    const refetchCompany = useCallback(
        (id: string) => {
            refetch({
                input: {
                    id,
                },
            });
        },
        [refetch]
    );

    return { company, loading, refetchCompany, error };
}
