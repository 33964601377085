import { Skeleton, Typography, Stack } from "@mui/material";
import { track } from "@ignite-analytics/track";
import { useContractsApiFetch } from "src/hooks/useContractsApiFetch";
import { useContractsApiPost } from "src/hooks/useContractsApiPost";
import { Alert, NewAlert } from "./dumb/models";
import { AlertsList } from "./dumb/list";
import { AddFooter } from "./dumb/addFooter";
import { ErrorDetails } from "../ErrorDetailsComponent/errorDetailsComponent";
import { useDeleteAlert } from "../../hooks/useAlerts";

type Props = {
    refId: string;
    maxHeight?: string;
    canEdit: boolean;
    skipHeader?: boolean;
};
export const Alerts = ({ refId, maxHeight, canEdit, skipHeader }: Props) => {
    const {
        data: alerts,
        setData: setAlerts,
        error,
        isLoading,
        refetch,
    } = useContractsApiFetch<Alert[]>(`contracts/${refId}/reminders`);
    const addAlert = useContractsApiPost();
    const deleteAlert = useDeleteAlert();

    const onAlertAdded = (newAlert: NewAlert) => {
        track("Contract Details: Alert added");
        addAlert(`contracts/${refId}/reminders`, { ...newAlert, refId }).then(() => {
            refetch();
        });
    };
    const onAlertDelete = (alertId: string) =>
        deleteAlert(refId, alertId).then(() => setAlerts(alerts?.filter((alert) => alert.id !== alertId)));
    return (
        <>
            {!skipHeader && (
                <Stack direction="row" justifyContent="space-between">
                    <Typography>Alerts</Typography>
                    <Typography textAlign="right" variant="body1">
                        Total: {alerts?.length}
                    </Typography>
                </Stack>
            )}
            {alerts && (
                <>
                    <Stack maxHeight={maxHeight ?? "auto"} overflow="auto">
                        <AlertsList alerts={alerts} onDelete={onAlertDelete} canEdit={canEdit} />
                    </Stack>
                    {canEdit && <AddFooter onAdd={onAlertAdded} />}
                </>
            )}
            {error && <ErrorDetails explanation={error.message} />}
            {isLoading && (
                <>
                    <Skeleton height="80px" animation="wave" />
                    <Skeleton height="80px" animation="wave" />
                </>
            )}
        </>
    );
};
