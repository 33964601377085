import { useErrorHandler } from "src/errorHandling/ErrorHandlerContext";
import { makeContractsApiHttp as http } from "src/http";

export const useContractsApiPut = () => {
    const { handleError } = useErrorHandler();
    return <T>(url: string, inputData: any) =>
        http()
            .put<T>(url, inputData)
            .then((res) => res.data)
            .catch((err) => {
                handleError(err);
                throw err;
            });
};
