import { useQuery } from "@tanstack/react-query";
import { useSessionContext } from "@/contexts/SessionContext";
import { makeHeadersWithTenant } from "@/http";
import { env } from "@/env";

type ModuleAccessResponse = {
    contracts: {
        included: boolean;
    };
};

const isModuleAccess = (data: unknown): data is ModuleAccessResponse => {
    if (typeof data !== "object" || data === null) {
        return false;
    }
    if (!("contracts" in data) || typeof data.contracts !== "object" || data.contracts === null) {
        return false;
    }
    if (!("included" in data.contracts) || typeof data.contracts.included !== "boolean") {
        return false;
    }
    return true;
};

export const useModuleAccess = () => {
    const { tenant } = useSessionContext();

    const { data } = useQuery({
        enabled: true,
        queryKey: [tenant, "module-access"],
        queryFn: async () => {
            const res = await fetch(`${env.REACT_APP_CUSTOMERS_URL}/modules`, {
                credentials: "include",
                headers: makeHeadersWithTenant(),
            });
            const data = await res.json();
            if (!isModuleAccess(data)) {
                throw new Error("Invalid data");
            }
            return data;
        },
    });

    return data;
};
