import { defineMessages } from "react-intl";
import { fm } from "src/contexts/IntlContext";

const defaultMessages = {
    header: "There are currently no contracts in your organisation.",
    defaultHeader: "No contracts found",
    createContract: "+ Create new contract",
    or: "or",
    uploadContracts: "Upload your contracts",
    uploadInstruction: "To upload your contracts as a CSV, download the template",
    here: "here",
    defaultSubheaderpart1: "If you need help, please visit our Help Center ",
    defaultSubheaderpart2: " for a quick guide on creating your first contract.",
    linkText: "here",
};
export type Messages = typeof defaultMessages;

const messages = defineMessages({
    header: {
        id: "noContractsInOrg.header",
        defaultMessage: "There are currently no contracts in your organisation.",
        description: "Label text: No contracts",
    },
    defaultHeader: {
        id: "noContracts.defaultHeader",
        defaultMessage: "No contracts found",
        description: "Label text: No contracts",
    },
    defaultSubheaderpart1: {
        id: "noContractsInOrg.defaultSubheaderpart1",
        defaultMessage: "If you need help, please visit our Help Center ",
        description: "Label text: No contracts",
    },
    defaultSubheaderpart2: {
        id: "noContractsInOrg.defaultSubheaderpart2",
        defaultMessage: " for a quick guide on creating your first contract.",
        description: "Label text: No contracts",
    },
    linkText: {
        id: "noContractsInOrg.linkText",
        defaultMessage: "here",
        description: "Part of the text which redirects to help center on click",
    },
    createContract: {
        id: "noContractsInOrg.createContract",
        defaultMessage: "+ Create new contract",
        description: "Button: Create new contract",
    },
    or: {
        id: "noContractsInOrg.or",
        defaultMessage: "or",
        description: "Label text: or",
    },
    uploadContracts: {
        id: "noContractsInOrg.uploadContracts",
        defaultMessage: "Upload your contracts",
        description: "Button: Upload your contracts",
    },
    uploadInstruction: {
        id: "noContractsInOrg.uploadInstruction",
        defaultMessage: "To upload your contracts as a CSV, download the template",
        description: "Label text: To upload your contracts as a CSV, download the template",
    },
    here: {
        id: "noContractsInOrg.here",
        defaultMessage: "here",
        description: "Label text: here",
    },
});

export const makeMessages = (): Messages => ({
    header: fm(messages.header).toString(),
    defaultHeader: fm(messages.defaultHeader).toString(),
    defaultSubheaderpart1: fm(messages.defaultSubheaderpart1).toString(),
    defaultSubheaderpart2: fm(messages.defaultSubheaderpart2).toString(),
    linkText: fm(messages.linkText).toString(),
    createContract: fm(messages.createContract).toString(),
    or: fm(messages.or).toString(),
    uploadContracts: fm(messages.uploadContracts).toString(),
    uploadInstruction: fm(messages.uploadInstruction).toString(),
    here: fm(messages.here).toString(),
});
