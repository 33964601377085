import { defineMessages } from "react-intl";
import { fm } from "src/contexts/IntlContext";
import { Messages } from "./dumb/rfx";

const messages = defineMessages({
    startRfx: {
        id: "rfx.button",
        description: "Button caption - create rfx",
        defaultMessage: "Start RFx",
    },
    noRfxLink: {
        id: "rfx.noRfxLink",
        description: "Text displayed when no sourcing event linked",
        defaultMessage: "No RFx linked to this contract yet",
    },
    rfxUrl: {
        id: "rfx.rfxLink",
        description: "Rfx url link caption",
        defaultMessage: "Go to RFx",
    },
});

const makeMessages = (): Messages => ({
    startRfx: fm(messages.startRfx).toString(),
    noRfxLink: fm(messages.noRfxLink).toString(),
    rfxUrl: fm(messages.rfxUrl).toString(),
});

export default makeMessages;
