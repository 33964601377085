import { Chip, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Variant } from "./interfaces";

export const VariantBadge: React.FC<{ variant: Variant }> = ({ variant }) => {
    const months = 6;

    if (variant === "active") {
        return (
            <Chip
                size="large"
                color="primary"
                label={
                    <Typography variant="textSm">
                        <FormattedMessage defaultMessage="Active contracts" />
                    </Typography>
                }
            />
        );
    }
    if (variant === "expiring") {
        return (
            <Chip
                size="large"
                color="error"
                label={
                    <Typography variant="textSm">
                        <FormattedMessage defaultMessage="Expiring within {months} months" values={{ months }} />
                    </Typography>
                }
            />
        );
    }
    if (variant === "renewing") {
        return (
            <Chip
                size="large"
                color="warning"
                label={
                    <Typography variant="textSm">
                        <FormattedMessage defaultMessage="Renewing within {months} months" values={{ months }} />
                    </Typography>
                }
            />
        );
    }
};
