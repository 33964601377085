import { defineMessages } from "react-intl";

const messages = defineMessages({
    newTagLabel: {
        id: "tagsDropdown.newTagLabel",
        description: "Label info for creating new tag",
        defaultMessage: "Create new tag",
    },
});

export default messages;
