import { IncludeFilter } from "@ignite-analytics/filters";
import { CONTRACT_RESPONSIBLE } from "@ignite-analytics/global-types";
import { ArrowRight, Contracts, ExclamationTriangle } from "@ignite-analytics/icons";
import { ShapeIcon } from "@ignite-analytics/components";
import { track } from "@ignite-analytics/track";
import { Button, Card, Skeleton, Stack, Typography } from "@mui/material";
import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";

import { contractListRoute, contractOverviewRoute } from "@/Router";
import { statusToSearchParams } from "@/helpers/statusToSearchParams";
import { useCompanyCurrency } from "@/contexts/CompanyCurrencyContext";
import { useSessionContext } from "@/contexts/SessionContext";
import { useUsers } from "@/entities/users";
import { useContractsElasticFields } from "@/hooks/useContractTableElasticFields";
import { Status } from "@/types";
import { VariantBadge } from "./VariantBadge";
import { Variant } from "./interfaces";

interface Props {
    variant: Variant;
    statistics?: {
        totalSpend: number;
        count: number;
    };
    contractSelection: "all" | "currentUser";
    disabled?: boolean;
}

const variantToStatus = (variant: Variant): Status => {
    switch (variant) {
        case "active":
            return "active";
        case "expiring":
            return "expiring-soon";
        case "renewing":
            return "renewing-soon";
    }
};

const variantToSearchParams = (variant: Variant) => statusToSearchParams(variantToStatus(variant));

export const MissingRenewalDateCard: React.FC = () => {
    return (
        <Card component={Stack} alignItems="center" justifyContent="center" height="100%" padding={3} gap={2}>
            <ShapeIcon color="warning" size="medium">
                <ExclamationTriangle fontSize="inherit"/>
            </ShapeIcon>
            <Typography variant="textSm" fontWeight={500} textAlign="center">
                <FormattedMessage defaultMessage="We need to know your data preferences for contract renewals. Reach out to a Customer Success Manager to get started." />
            </Typography>
        </Card>
    );
};

export const StatisticsCard: React.FC<Props> = ({ variant, statistics, contractSelection, disabled }) => {
    const navigate = contractListRoute.useNavigate();

    const { group } = contractOverviewRoute.useSearch();
    const { id: userId } = useSessionContext();
    const { users } = useUsers();
    const user = users.find((user) => user.id === userId);

    const elasticFields = useContractsElasticFields();
    const contractResponsiblesField = elasticFields?.find((field) => field.globalTypeKey === CONTRACT_RESPONSIBLE);

    const currency = useCompanyCurrency();

    const goToList = useCallback(() => {
        track("Contract Overview: Click on statistics card", { card: variant, contractSelection });
        const contractResponsibleFilters: IncludeFilter[] | undefined =
            group === "currentUser" && user && contractResponsiblesField
                ? [
                      {
                          field: contractResponsiblesField.field,
                          type: "keyword",
                          include: [`${user.firstName} ${user.lastName}`],
                          filterType: "includefilter",
                          fieldId: contractResponsiblesField.fieldId,
                      },
                  ]
                : undefined;

        navigate({
            to: contractListRoute.fullPath,
            search: (prev) => ({ ...prev, ...variantToSearchParams(variant), dmsFilter: contractResponsibleFilters }),
        });
    }, [navigate, variant, contractSelection, user, group, contractResponsiblesField]);

    return (
        <Card component={Stack} justifyContent="space-between" padding={3} gap={2}>
            <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
                <Contracts fontSize="small" />
                <VariantBadge variant={variant} />
            </Stack>
            <Stack direction="row" alignItems="flex-end" justifyContent="space-between">
                <Stack alignItems="flex-start" justifyContent="flex-start">
                    <Typography variant="h4" fontWeight={500}>
                        {statistics ? statistics.count.toLocaleString() : <Skeleton variant="text" width={100} />}
                    </Typography>
                    <Typography variant="textSm" fontWeight={500} color="text.text-helper">
                        <FormattedMessage defaultMessage="Contracts" />
                    </Typography>
                </Stack>
                <Typography variant="textXl">{currency.formatCompact(statistics?.totalSpend)}</Typography>
            </Stack>
            <Stack direction="row">
                <Button
                    size="xsmall"
                    variant="outlined"
                    color="secondary"
                    onClick={goToList}
                    endIcon={<ArrowRight fontSize="small" />}
                    disabled={disabled}
                >
                    <FormattedMessage defaultMessage="View contracts" />
                </Button>
            </Stack>
        </Card>
    );
};
