import { defineMessages } from "react-intl";

const messages = defineMessages({
    contractCoverageTitle: {
        id: "contractCoverage.title",
        description: "Contract coverage",
        defaultMessage: "Spend through contracts",
    },
    spendThroughContracts: {
        id: "contractCoverage.spendThroughContracts",
        description: "Spend through contracts",
        defaultMessage: "Spend through contracts",
    },
    spendWoContracts: {
        id: "contractCoverage.spendWoContracts",
        description: "Spend without contracts",
        defaultMessage: "Spend without contracts",
    },
});

export default messages;
