import React from "react";
import { Grid, Stack, Typography, Autocomplete, Skeleton, Checkbox, Chip, TextField } from "@mui/material";
import { CustomField } from "src/models/customFields";
import { useIntl } from "react-intl";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { ChevronRight } from "@ignite-analytics/icons";
import { useQuery } from "@tanstack/react-query";
import { useCompanyCurrency } from "@/contexts/CompanyCurrencyContext";
import { useSearchTagsV2 } from "@/hooks/useSearchTagsV2";
import { groupHierarchy, loadGroupsRecursively } from "@/hooks/useGroupsV2";
import { useSessionContext } from "@/contexts/SessionContext";
import { groupStructureQueryKey } from "@/querykeys";
import { normalizeDateString } from "@/helpers/dates";

type ContractFieldProps = {
    label: string;
    xs: number;
    // multiple
    children?: React.ReactNode;
    blank?: boolean;
    edit?: boolean;
    loading?: boolean;
};

const GroupHierarchy = ({ names }: { names: string[] }) => {
    return (
        <>
            {names.map((name, i) => (
                <>
                    <Typography color={i < names.length - 1 ? "GrayText" : undefined} variant="textMd">
                        {name}
                    </Typography>
                    {i < names.length - 1 && <ChevronRight sx={{ height: "16px" }} />}
                </>
            ))}
        </>
    );
};

export const ContractField = ({ blank, label, xs, children, edit, loading }: ContractFieldProps) => {
    // if 2 children are passed -> first is rendered in display mode, second in edit mode
    // otherwise, the same child is rendered in both modes
    const childNodes = React.Children.toArray(children);
    if (![0, 1, 2].includes(childNodes.length)) {
        // throw an error here ot make life easier for the developer
        throw new Error(
            `ContractField expects 0, 1 or 2 children, but got ${childNodes.length} children. (label=${label})`
        );
    }
    let displayNode = childNodes[0];
    let editNode = childNodes.length === 2 ? childNodes[1] : displayNode;

    if (typeof displayNode === "string") {
        displayNode = <Typography variant="textMd">{displayNode}</Typography>;
    }
    if (typeof editNode === "string") {
        editNode = <Typography variant="textMd">{editNode}</Typography>;
    }

    return (
        <Grid item xs={xs}>
            <Stack gap={1} alignItems="stretch" height="100%">
                <Typography variant="textMd" fontWeight={500}>
                    {label}
                </Typography>
                <Stack height="100%" alignItems="center" direction="row">
                    {loading ? (
                        <Skeleton width={250} variant="text" height={40} />
                    ) : edit ? (
                        editNode
                    ) : blank ? (
                        <Typography>-</Typography>
                    ) : (
                        displayNode
                    )}
                </Stack>
            </Stack>
        </Grid>
    );
};

type RenderCustomFieldProps = {
    customField: CustomField;
};
const RenderCustomField = ({ customField }: RenderCustomFieldProps) => {
    const currency = useCompanyCurrency();
    let groupStructureId = "";
    if (customField.dataType === "GROUP_STRUCTURE" || customField.dataType === "GROUP_STRUCTURE_LIST") {
        groupStructureId = customField.data.tableId;
    }
    const session = useSessionContext();
    const groups = useQuery({
        enabled: groupStructureId !== "",
        queryKey: groupStructureQueryKey(session.tenant, groupStructureId),
        queryFn: () => loadGroupsRecursively(groupStructureId),
        staleTime: 1000 * 60,
    });

    switch (customField.dataType) {
        case "TEXT":
            return customField.data;
        case "TEXT_LIST":
            return customField.data?.join(", ");
        case "NUMBER":
        case "NUMBER_AGGREGATION":
            return (
                <Typography variant="textMd">
                    {customField.data?.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                </Typography>
            );
        case "MONETARY_AMOUNT":
            return <Typography variant="textMd">{currency.format(customField.data?.amount)}</Typography>;
        case "DATE":
        case "DATE_AGGREGATION":
            return normalizeDateString(customField.data)?.format("DD MMM YYYY");
        case "BOOLEAN":
            return (
                <Checkbox
                    size="large"
                    checked={customField.data ?? false}
                    inputProps={{ readOnly: true }}
                    disableRipple
                />
            );
        case "TAG":
            return (
                <Stack direction="row" alignItems="center" justifyItems="start">
                    <Chip label={customField.data} />
                </Stack>
            );
        case "TAG_LIST":
            return (
                <Stack direction="row" alignItems="center" justifyItems="start" gap={2}>
                    {customField.data?.map((tag) => <Chip key={tag} label={tag} />)}
                </Stack>
            );
        case "GROUP_STRUCTURE":
            if (customField.data.id === null || customField.data.id === undefined) {
                return <Typography>-</Typography>;
            }
            if (groups.isLoading) {
                return <Skeleton width={250} variant="text" height={40} />;
            }
            if (groups.data) {
                return <GroupHierarchy names={groupHierarchy(groups.data, customField.data.id).map((g) => g.name)} />;
            }
            return <Typography>-</Typography>;

        case "GROUP_STRUCTURE_LIST": {
            const { ids } = customField.data;
            if (ids === null || ids.length === 0) {
                return <Typography>-</Typography>;
            }
            if (groups.isLoading) {
                return <Skeleton width={250} variant="text" height={40} />;
            }
            if (groups.data) {
                return (
                    <Stack direction="row" alignItems="start" justifyItems="start" gap={2}>
                        {ids.map((id) => (
                            <Chip
                                key={id}
                                label={
                                    <Stack direction="row" alignItems="center">
                                        <GroupHierarchy names={groupHierarchy(groups.data, id).map((g) => g.name)} />
                                    </Stack>
                                }
                            />
                        ))}
                    </Stack>
                );
            }
            return <Typography>-</Typography>;
        }

        case "TABLE_RELATION":
        case "COMPANY":
        case "USER":
        case "USER_LIST":
        case "CONTACT":
        case "CONTACT_LIST":
            return undefined;
    }
};

type RenderCustomFieldEditProps = {
    customField: CustomField;
    onChange: (value: CustomField["data"]) => void;
};
const RenderCustomFieldEdit = ({ customField, onChange }: RenderCustomFieldEditProps) => {
    const { formatMessage } = useIntl();

    // these hooks should lazily load data, so it's ok to define them here with potentially
    // invalid parameters (eg the empty string as id if the custom field is not a group structure).
    // We can't define the hooks inside the switch statement due to the rules of hooks.
    const tags = useSearchTagsV2(customField.id);
    let groupStructureId = "";
    if (customField.dataType === "GROUP_STRUCTURE" || customField.dataType === "GROUP_STRUCTURE_LIST") {
        groupStructureId = customField.data.tableId;
    }
    const session = useSessionContext();
    const groups = useQuery({
        enabled: groupStructureId !== "",
        queryKey: groupStructureQueryKey(session.tenant, groupStructureId),
        queryFn: () => loadGroupsRecursively(groupStructureId),
        staleTime: 1000 * 60,
    });

    switch (customField.dataType) {
        case "TEXT":
            return (
                <TextField
                    fullWidth
                    value={customField.data ?? ""}
                    onChange={(event) => onChange(event.target.value)}
                />
            );
        case "TEXT_LIST":
            return (
                <TextField
                    placeholder={formatMessage({ defaultMessage: "Separated by comma" })}
                    fullWidth
                    value={customField.data?.join(", ")}
                    onChange={(event) => {
                        const value = event.target.value;
                        const parts = value.split(",").map((part) => part.trim());
                        onChange(parts);
                    }}
                />
            );
        case "NUMBER": {
            const ParseToNumberOrUndefined = (val: string): number | undefined => {
                const parsedValue = Number.parseFloat(val);
                if (Number.isNaN(parsedValue)) {
                    return undefined;
                }
                return parsedValue;
            };

            return (
                <TextField
                    size="small"
                    type="number"
                    variant="outlined"
                    fullWidth
                    value={customField.data ?? ""}
                    onChange={(event) => {
                        onChange(ParseToNumberOrUndefined(event.target.value));
                    }}
                />
            );
        }
        case "NUMBER_AGGREGATION":
        case "MONETARY_AMOUNT":
            return undefined;
        case "DATE":
            return (
                <DesktopDatePicker
                    value={normalizeDateString(customField.data)}
                    timezone="UTC"
                    onChange={(value) => onChange(value?.format())}
                />
            );
        case "DATE_AGGREGATION":
            return undefined;
        case "BOOLEAN":
            return (
                <Checkbox
                    checked={customField.data ?? false}
                    onChange={(event) => {
                        onChange(event.target.checked);
                    }}
                />
            );
        case "TAG":
            return (
                <Autocomplete
                    onOpen={tags.load}
                    onMouseOver={() => {
                        if (!tags.loading) {
                            tags.load();
                        }
                    }}
                    loading={tags.loading}
                    renderInput={(params) => {
                        return (
                            <TextField
                                {...params}
                                placeholder={formatMessage({ defaultMessage: "Select one" })}
                                InputProps={{ ...params.InputProps, size: "small" }}
                            />
                        );
                    }}
                    options={tags.options}
                    fullWidth
                    value={customField.data}
                    onChange={(_, value) => onChange(value ?? undefined)}
                />
            );
        case "TAG_LIST":
            return (
                <Autocomplete
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip size="xsmall" label={option} {...getTagProps({ index })} key={option} />
                        ))
                    }
                    onOpen={tags.load}
                    onMouseOver={() => {
                        if (!tags.loading) {
                            tags.load();
                        }
                    }}
                    loading={tags.loading}
                    multiple
                    renderInput={(params) => {
                        return (
                            <TextField
                                {...params}
                                placeholder={
                                    React.Children.count(params.InputProps.startAdornment) > 0
                                        ? undefined
                                        : formatMessage({ defaultMessage: "Select multiple" })
                                }
                                InputProps={{ ...params.InputProps, size: "small" }}
                            />
                        );
                    }}
                    options={tags.options}
                    fullWidth
                    value={customField.data}
                    onChange={(_, value) => onChange(value ?? [])}
                />
            );
        case "GROUP_STRUCTURE":
            return (
                <Autocomplete
                    loading={groups.isLoading}
                    onOpen={() => groups.refetch()}
                    renderInput={(params) => {
                        return (
                            <TextField
                                {...params}
                                placeholder={formatMessage({ defaultMessage: "Select one" })}
                                InputProps={{ ...params.InputProps, size: "small" }}
                            />
                        );
                    }}
                    options={groups.data ?? []}
                    renderOption={(props, option) => {
                        // name -> parent name -> grandparent name ...
                        const path = groupHierarchy(groups.data ?? [], option.id).map((g) => g.name);
                        return (
                            <Stack direction="row" alignItems="center" component="li" {...props}>
                                {path.map((p, i) => (
                                    <>
                                        <Typography
                                            component="span"
                                            variant="textSm"
                                            color={i < path.length - 1 ? "GrayText" : undefined}
                                            fontWeight={500}
                                            key={p}
                                        >
                                            {p}
                                        </Typography>
                                        {i < path.length - 1 && <ChevronRight sx={{ height: "16px" }} />}
                                    </>
                                ))}
                            </Stack>
                        );
                    }}
                    getOptionLabel={(option) => option.name}
                    fullWidth
                    value={groups.data?.find((g) => g.id === customField.data.id) ?? null}
                    onChange={(_, value) => {
                        onChange({ tableId: customField.data.tableId, id: value === null ? undefined : value.id });
                    }}
                />
            );
        case "GROUP_STRUCTURE_LIST":
            return (
                <Autocomplete
                    multiple
                    onOpen={() => groups.refetch()}
                    loading={groups.isLoading}
                    renderTags={(value, getTagProps) => (
                        <>
                            {value.map((option, index) => (
                                <Chip
                                    label={
                                        <Stack direction="row" alignItems="center">
                                            <GroupHierarchy
                                                names={groupHierarchy(groups.data ?? [], option.id).map((g) => g.name)}
                                            />
                                        </Stack>
                                    }
                                    {...getTagProps({ index })}
                                    key={option.id}
                                />
                            ))}
                        </>
                    )}
                    disableCloseOnSelect
                    renderInput={(params) => {
                        return (
                            <TextField
                                {...params}
                                placeholder={
                                    React.Children.count(params.InputProps.startAdornment) > 0
                                        ? undefined
                                        : formatMessage({ defaultMessage: "Select multiple" })
                                }
                                InputProps={{ ...params.InputProps, size: "small" }}
                            />
                        );
                    }}
                    options={groups.data ?? []}
                    renderOption={(props, option) => {
                        // name -> parent name -> grandparent name ...
                        const path = groupHierarchy(groups.data ?? [], option.id).map((g) => g.name);

                        return (
                            <Stack direction="row" alignItems="center" component="li" {...props}>
                                {path.map((p, i) => (
                                    <>
                                        <Typography
                                            component="span"
                                            variant="textSm"
                                            color={i < path.length - 1 ? "GrayText" : undefined}
                                            fontWeight={500}
                                            key={p}
                                        >
                                            {p}
                                        </Typography>
                                        {i < path.length - 1 && <ChevronRight sx={{ height: "16px" }} />}
                                    </>
                                ))}
                            </Stack>
                        );
                    }}
                    getOptionLabel={(option) => option.name}
                    fullWidth
                    value={groups.data?.filter((g) => customField.data.ids?.includes(g.id))}
                    onChange={(_, value) => {
                        onChange({ tableId: customField.data.tableId, ids: value.map((v) => v.id) });
                    }}
                />
            );
        case "TABLE_RELATION":
        case "COMPANY":
        case "USER":
        case "USER_LIST":
        case "CONTACT":
        case "CONTACT_LIST":
            return undefined;
    }
};

type ContractCustomFieldProps = {
    edit: boolean;
    customField: CustomField;
    onChange: (value: CustomField) => void;
};
export const ContractCustomField: React.FC<ContractCustomFieldProps> = ({ edit, customField, onChange }) => {
    const updatedCustomField = { ...customField }; // makes a copy I suppose

    let blank = false;
    if (customField.data === null) {
        blank = true;
    } else if (customField.dataType === "TAG_LIST" && customField.data.length === 0) {
        blank = true;
    }

    return (
        <ContractField blank={blank} edit={edit} xs={4} label={customField.name}>
            <RenderCustomField customField={updatedCustomField} />
            <RenderCustomFieldEdit
                customField={updatedCustomField}
                onChange={(value) => onChange({ ...customField, data: value } as CustomField)}
            />
        </ContractField>
    );
};
