import { formatDateAsUTC } from "@ignite-analytics/locale";
import { Chip, Link as MuiLink, Stack, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Skeleton from "@mui/material/Skeleton";
import { ExclamationTriangle } from "@ignite-analytics/icons";
import React, { ReactElement } from "react";
import { Link } from "@tanstack/react-router";
import { makeGraphqlRouter } from "src/http";
import { FormattedMessage } from "react-intl";
import { useUsers } from "../../entities/users";
import { CustomField } from "./contractListItem";
import { contractListRoute, contractDetailRoute } from "@/Router";
import { useCompanyCurrency } from "@/contexts/CompanyCurrencyContext";
import { usePermissionCheck } from "@/hooks/usePermissionCheck";
import { env } from "@/env";

const fixDmsContacts = env.REACT_APP_ENV !== "local";

const getSupplierQuery = `
    query getSupplier($input: GetSupplierInput!) {
        getSupplier(input: $input) {
            supplier {
                id
                name
            }
        }
    }
`;

const formatDate = (date: string | Date | number) => {
    return formatDateAsUTC(date, {month: "short"});
}

const UserCell = ({ userIds }: { userIds: string[] }) => {
    const { loading, users } = useUsers(userIds);

    if (loading) return <Skeleton height="40px" width="300px" animation="wave" />;
    return (
        <Stack display="block">
            {users.map((user) => (
                <Chip sx={{ m: 0.5 }} label={`${user.firstName} ${user.lastName}`} key={user.id} size="small" />
            ))}
        </Stack>
    );
};

const fetchSupplierById = (id: string): Promise<string> =>
    makeGraphqlRouter()
        .post("", {
            query: getSupplierQuery,
            variables: {
                input: {
                    id,
                },
            },
        })
        .then((response) => response.data.data.getSupplier.supplier.id);

const onSupplierClick = async (supplierId: string) => {
    const winnerId = await fetchSupplierById(supplierId);
    const newPath = `${window.location.pathname}/supplier-page/${winnerId}/overview`;
    window.postMessage(
        {
            type: "route-change",
            path: newPath,
            from: window.location.pathname,
        },
        window.location.origin
    );
};

// export const RenderLink = ({ label, to }: { label: string; to: string }) => {
//     return (
//         <MuiLink component={Link} to={to} noWrap>
//             {label}
//         </MuiLink>
//     );
// };
type RenderSupplierChipProps = {
    id?: string;
    name?: string;
};
export const RenderSupplierChip = ({ id, name }: RenderSupplierChipProps) => {
    const canOpenSupplierCard = usePermissionCheck("suppliers", "general", "read").data ?? false;
    if (!id) {
        return <Typography>-</Typography>;
    }
    return (
        <Chip
            disabled={!canOpenSupplierCard}
            onClick={() => onSupplierClick(id)}
            label={name ?? "Missing name"}
            size="small"
        />
    );
};

type RenderUsersChipProps = {
    users: { firstName: string; lastName: string }[];
};
export const RenderUsersChip = ({ users }: RenderUsersChipProps) => {
    return (
        <>
            {users.map((user) => (
                <Chip sx={{ mx: 0.5 }} label={`${user.firstName} ${user.lastName}`} key={user.firstName} size="small" />
            ))}
        </>
    );
};

type RenderDateCellProps = {
    date?: string;
};
export const RenderDateCell = ({ date }: RenderDateCellProps) => {
    if (!date) {
        return <Typography>-</Typography>;
    }
    return formatDate(date);
};
// export const RenderTextCell = ({ text }: { text: string }) => {
//     return (
//         <Typography variant="body2" noWrap>
//             {text}
//         </Typography>
//     );
// };
// export const RenderNumberCell = ({ number }: { number: number }) => {
//     return <Typography sx={{ textAlign: "right" }}>{Number.isInteger(number) ? number : number.toFixed(2)}</Typography>;
// };

type RenderMonetaryAmountCellProps = {
    amount?: number;
};
export const RenderMonetaryAmountCell = ({ amount }: RenderMonetaryAmountCellProps) => {
    const currency = useCompanyCurrency();
    if (amount === undefined) {
        return <Typography sx={{ textAlign: "right" }}>-</Typography>;
    }
    return <Typography sx={{ textAlign: "right" }}>{currency.formatCompact(amount)}</Typography>;
};
// export const RenderBooleanCell = ({ checked }: { checked: boolean }) => {
//     return (
//         <Typography sx={{ textAlign: "center" }}>
//             <Checkbox checked={checked} />
//         </Typography>
//     );
// };

type CustomFieldProps = {
    field: CustomField;
};
export const RenderCustomField = ({ field }: CustomFieldProps): ReactElement => {
    const canOpenSupplierCard = usePermissionCheck("suppliers", "general", "read").data ?? false;
    const currency = useCompanyCurrency();
    switch (field.dataType) {
        case "TITLE":
            return (
                <Stack alignItems="left" flexWrap="nowrap" width="100%">
                    <MuiLink
                        component={Link}
                        from={contractListRoute.fullPath}
                        to={contractDetailRoute.fullPath}
                        params={{ id: field.data.value }}
                        noWrap
                        style={{
                            // copied from suprema
                            color: "black",
                            textDecoration: "none",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                        }}
                    >
                        {field.data.label !== "" ? (
                            <Typography variant="textSm" noWrap>
                                {field.data.label}
                            </Typography>
                        ) : (
                            <Stack direction="row" alignItems="center" gap={1}>
                                <ExclamationTriangle />
                                <Typography variant="textSm" noWrap>
                                    <FormattedMessage defaultMessage="Missing title" />
                                </Typography>
                            </Stack>
                        )}
                    </MuiLink>
                </Stack>
            );
        case "SUPPLIER":
            return (
                <>
                    {field.data?.value && (
                        <Chip
                            disabled={!canOpenSupplierCard}
                            label={field.data.label}
                            size="small"
                            onClick={() => field.data?.value && onSupplierClick(field.data.value)}
                        />
                    )}
                </>
            );
        case "END_DATE":
            return <>{field.data ? formatDate(field.data) : ""}</>;
        case "TEXT":
            return (
                <Typography variant="textSm" noWrap>
                    {field.data}
                </Typography>
            );
        case "TEXT_LIST":
        case "TAG":
            return <>{field.data}</>;
        case "NUMBER":
        case "NUMBER_AGGREGATION":
            return (
                <Typography variant="textSm" sx={{ textAlign: "right" }}>
                    {Number.isInteger(Number(field.data)) ? field.data : Number(field.data).toFixed(2)}
                </Typography>
            );
        case "MONETARY_AMOUNT":
            return (
                <Typography variant="textSm" sx={{ textAlign: "right" }} key={field.id}>
                    {field.data?.currency} {field.data?.amount}
                </Typography>
            );
        case "SPEND": {
            // the currency code is written in the header, so we don't need to display it here
            let amount = currency.formatInteger(field.data);
            if (currency.code) {
                amount = amount.replace(currency.code, "");
            }
            return (
                <Typography variant="textSm" sx={{ textAlign: "right" }} key={field.id}>
                    {amount}
                </Typography>
            );
        }
        case "BOOLEAN":
            return (
                <Typography key={field.id} sx={{ textAlign: "center" }}>
                    <Checkbox checked={field.data ?? false} />
                </Typography>
            );
        case "DATE_AGGREGATION":
        case "DATE":
            return (
                <Typography variant="textSm" key={field.id}>
                    {field.data ? formatDate(field.data) : ""}
                </Typography>
            );
        case "CONTACT":
        case "COMPANY":
            return <>{field.data?.label || ""}</>;
        case "USER":
            return <UserCell userIds={field.data?.value ? [field.data?.value] : []} />;
        case "GROUP_STRUCTURE":
            return <>{field.data?.id?.label || ""}</>;
        case "TAG_LIST":
            return (
                <Stack display="block">
                    {field.data?.map((item) => (
                        <Chip sx={{ m: 0.5 }} label={item} key={field.name + item} size="small" />
                    ))}
                </Stack>
            );
        case "CONTACT_LIST":
            return (
                <Stack display="block">
                    {field.data?.map((item) => (
                        <Chip sx={{ m: 0.5 }} label={item.label} key={item.value} size="small" />
                    ))}
                </Stack>
            );
        case "USER_LIST":
            return (
                <>
                    {fixDmsContacts && <UserCell userIds={field.data.map((d) => d.value as string)} />}
                    {!fixDmsContacts && (
                        <Stack display="block">
                            {field.data?.map((item) => (
                                <Chip sx={{ m: 0.5 }} label={item.label} key={item.value} size="small" />
                            ))}
                        </Stack>
                    )}
                </>
            );
        case "GROUP_STRUCTURE_LIST":
            return (
                <Stack display="block">
                    {field.data &&
                        field.data?.ids.map((item) => (
                            <Chip sx={{ m: 0.5 }} label={item.label} key={item.value} size="small" />
                        ))}
                </Stack>
            );
        default:
            return <Typography variant="textSm" key={field.id} />;
    }
};
