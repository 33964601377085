import { Card, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { TooltipProps } from "recharts";
import { ValueType, NameType } from "recharts/types/component/DefaultTooltipContent";

import { formatBigNumber } from "@/helpers/formatNumbers";
import messages from "../messages";
import { fm } from "@/contexts/IntlContext";

const spendThroughContractsKey = fm(messages.spendThroughContracts).toString();
const spendWithoutContractsKey = fm(messages.spendWoContracts).toString();

export const CustomTooltip: React.FC<TooltipProps<ValueType, NameType>> = ({ payload, label }) => {
    if (!(payload && payload?.length > 0)) {
        return null;
    }
    const { payload: innerPayload } = payload[0];
    const spendThroughContracts = Number(innerPayload[spendThroughContractsKey]);
    const spendWithoutContracts = Number(innerPayload[spendWithoutContractsKey]);
    const percentage =
        spendThroughContracts + spendWithoutContracts === 0
            ? 0
            : Math.round((spendThroughContracts / (spendThroughContracts + spendWithoutContracts)) * 100);
    return (
        <Card sx={{ padding: 1 }}>
            <Typography variant="textMd" fontWeight={500}>
                {`${label}:`}
            </Typography>
            <Stack direction="column" gap={1}>
                <Typography variant="textSm">
                    <FormattedMessage
                        defaultMessage="Spend through contracts: {spend}"
                        values={{ spend: formatBigNumber(spendThroughContracts, "auto") }}
                    />
                </Typography>
                <Typography variant="textSm">
                    <FormattedMessage
                        defaultMessage="Spend without contracts: {spend}"
                        values={{ spend: formatBigNumber(spendWithoutContracts, "auto") }}
                    />
                </Typography>
                <Typography variant="textSm">
                    <FormattedMessage defaultMessage="Contract coverage: {percentage}%" values={{ percentage }} />
                </Typography>
            </Stack>
        </Card>
    );
};
